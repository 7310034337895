import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SiderLayout } from 'modules/core/layouts'
import { Col, Row, Spin } from 'antd'
import { PeriodNavigator, ProjectionSteps } from 'modules/core/components'
import { useParams } from 'react-router-dom'
import { generateIndexPhase, isUserAllowed } from 'modules/core/utils'
import { CONCEPTS, PROJECTION, ROLES } from 'modules/core/constants'
import { BaseForecast } from './components'
import { isStepClosing } from 'modules/core/components/ProjectionComponents/utils'
import { usePagination } from 'modules/core/customHooks'
import planning from 'modules/planning'
import forecast from 'modules/forecast'
import _ from 'lodash'

import './ForecastDetail.scss'

const {
  FORECAST__SALES__BASE__ABM,
  FORECAST__SALES__BASE__VIEW,
  FORECAST__SALES__TOP_DOWN__ABM,
  FORECAST__SALES__TOP_DOWN__VIEW,
  FORECAST__SALES__BOTTOM_UP__ABM,
  FORECAST__SALES__BOTTOM_UP__VIEW,
  FORECAST__SALES__CLOSING__ABM,
  FORECAST__SALES__CLOSING__VIEW,
  FORECAST__EXPENSES__BASE__ABM,
  FORECAST__EXPENSES__BASE__VIEW,
  FORECAST__EXPENSES__TOP_DOWN__ABM,
  FORECAST__EXPENSES__TOP_DOWN__VIEW,
  FORECAST__EXPENSES__BOTTOM_UP__ABM,
  FORECAST__EXPENSES__BOTTOM_UP__VIEW,
  FORECAST__EXPENSES__CLOSING__ABM,
  FORECAST__EXPENSES__CLOSING__VIEW,
  FORECAST__COSTS__BASE__ABM,
  FORECAST__COSTS__BASE__VIEW,
  FORECAST__COSTS__TOP_DOWN__ABM,
  FORECAST__COSTS__TOP_DOWN__VIEW,
  FORECAST__COSTS__BOTTOM_UP__ABM,
  FORECAST__COSTS__BOTTOM_UP__VIEW,
  FORECAST__COSTS__CLOSING__ABM,
  FORECAST__COSTS__CLOSING__VIEW,
  FORECAST__HUMAN_RESOURCES__BASE__ABM,
  FORECAST__HUMAN_RESOURCES__BASE__VIEW,
  FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
  FORECAST__HUMAN_RESOURCES__TOP_DOWN__VIEW,
  FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
  FORECAST__HUMAN_RESOURCES__BOTTOM_UP__VIEW,
  FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
  FORECAST__HUMAN_RESOURCES__CLOSING__VIEW,
  FORECAST__OTHER_5__BASE__ABM,
  FORECAST__OTHER_5__BASE__VIEW,
  FORECAST__OTHER_5__TOP_DOWN__ABM,
  FORECAST__OTHER_5__TOP_DOWN__VIEW,
  FORECAST__OTHER_5__BOTTOM_UP__ABM,
  FORECAST__OTHER_5__BOTTOM_UP__VIEW,
  FORECAST__OTHER_5__CLOSING__ABM,
  FORECAST__OTHER_5__CLOSING__VIEW,
  FORECAST__OTHER_6__BASE__ABM,
  FORECAST__OTHER_6__BASE__VIEW,
  FORECAST__OTHER_6__TOP_DOWN__ABM,
  FORECAST__OTHER_6__TOP_DOWN__VIEW,
  FORECAST__OTHER_6__BOTTOM_UP__ABM,
  FORECAST__OTHER_6__BOTTOM_UP__VIEW,
  FORECAST__OTHER_6__CLOSING__ABM,
  FORECAST__OTHER_6__CLOSING__VIEW,
} = ROLES

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const FORECAST__CONCEPT = {
  VIEW: {
    [CONCEPTS.IDS.SALES_ID]: {
      [PROJECTION.BASE]: [FORECAST__SALES__BASE__ABM, FORECAST__SALES__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [FORECAST__SALES__TOP_DOWN__ABM, FORECAST__SALES__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [FORECAST__SALES__BOTTOM_UP__ABM, FORECAST__SALES__BOTTOM_UP__VIEW],
      [PROJECTION.CLOSING]: [FORECAST__SALES__CLOSING__ABM, FORECAST__SALES__CLOSING__VIEW],
    },
    [CONCEPTS.IDS.EXPENSES_ID]: {
      [PROJECTION.BASE]: [FORECAST__EXPENSES__BASE__ABM, FORECAST__EXPENSES__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [FORECAST__EXPENSES__TOP_DOWN__ABM, FORECAST__EXPENSES__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [
        FORECAST__EXPENSES__BOTTOM_UP__ABM,
        FORECAST__EXPENSES__BOTTOM_UP__VIEW,
      ],
      [PROJECTION.CLOSING]: [FORECAST__EXPENSES__CLOSING__ABM, FORECAST__EXPENSES__CLOSING__VIEW],
    },
    [CONCEPTS.IDS.COSTS_ID]: {
      [PROJECTION.BASE]: [FORECAST__COSTS__BASE__ABM, FORECAST__COSTS__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [FORECAST__COSTS__TOP_DOWN__ABM, FORECAST__COSTS__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [FORECAST__COSTS__BOTTOM_UP__ABM, FORECAST__COSTS__BOTTOM_UP__VIEW],
      [PROJECTION.CLOSING]: [FORECAST__COSTS__CLOSING__ABM, FORECAST__COSTS__CLOSING__VIEW],
    },
    [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: {
      [PROJECTION.BASE]: [
        FORECAST__HUMAN_RESOURCES__BASE__ABM,
        FORECAST__HUMAN_RESOURCES__BASE__VIEW,
      ],
      [PROJECTION.TOPDOWN]: [
        FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
        FORECAST__HUMAN_RESOURCES__TOP_DOWN__VIEW,
      ],
      [PROJECTION.BOTTOMUP]: [
        FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
        FORECAST__HUMAN_RESOURCES__BOTTOM_UP__VIEW,
      ],
      [PROJECTION.CLOSING]: [
        FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
        FORECAST__HUMAN_RESOURCES__CLOSING__VIEW,
      ],
    },
    [CONCEPTS.IDS.OTHER_5_ID]: {
      [PROJECTION.BASE]: [FORECAST__OTHER_5__BASE__ABM, FORECAST__OTHER_5__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [FORECAST__OTHER_5__TOP_DOWN__ABM, FORECAST__OTHER_5__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [
        FORECAST__OTHER_5__BOTTOM_UP__ABM,
        FORECAST__OTHER_5__BOTTOM_UP__VIEW,
      ],
      [PROJECTION.CLOSING]: [FORECAST__OTHER_5__CLOSING__ABM, FORECAST__OTHER_5__CLOSING__VIEW],
    },
    [CONCEPTS.IDS.OTHER_6_ID]: {
      [PROJECTION.BASE]: [FORECAST__OTHER_6__BASE__ABM, FORECAST__OTHER_6__BASE__VIEW],
      [PROJECTION.TOPDOWN]: [FORECAST__OTHER_6__TOP_DOWN__ABM, FORECAST__OTHER_6__TOP_DOWN__VIEW],
      [PROJECTION.BOTTOMUP]: [
        FORECAST__OTHER_6__BOTTOM_UP__ABM,
        FORECAST__OTHER_6__BOTTOM_UP__VIEW,
      ],
      [PROJECTION.CLOSING]: [FORECAST__OTHER_6__CLOSING__ABM, FORECAST__OTHER_6__CLOSING__VIEW],
    },
  },
}

const ForecastDetail = ({
  dataPhaseBudget,
  forecastInstanceDetail,
  fetchForecastInstanceDetail,
}) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const strictFilter = useFeatureValue('feature-strict-filter', {})
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [current, setCurrent] = useState(0)
  const [tablePagination, setTablePagination] = usePagination()
  let { periodId, forecastId, projectionId } = useParams()
  const { t } = useTranslation()

  const verifyPermission = (concept_id, el) => {
    const permission = [
      PROJECTION.GLOBAL_FORECAST.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(concept_id.toString())) {
      return isUserAllowed(FORECAST__CONCEPT.VIEW[concept_id][el.key.toUpperCase()])
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const filterDataPhaseBudgetByRole = dataPhaseBudget.filter((el) =>
    !_.isEmpty(forecastInstanceDetail)
      ? verifyPermission(forecastInstanceDetail?.concept_id, el)
      : true,
  )

  const index = generateIndexPhase(forecastInstanceDetail, filterDataPhaseBudgetByRole)

  useEffect(() => {
    setIsScreenLoading(true)
    fetchForecastInstanceDetail(projectionId).then(() => setIsScreenLoading(false))
  }, [fetchForecastInstanceDetail, projectionId])

  useEffect(() => {
    setCurrent(index)
  }, [index])

  const disablePhases = dataPhaseBudget.slice(index + 1, dataPhaseBudget.length)
  const status_budget = isStepClosing(dataPhaseBudget[current].key)
    ? 'finish'
    : dataPhaseBudget[current + 1].key

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_FORECAST'),
          url: `/forecast/${periodId}`,
        },
        {
          name: `${t('LABEL_DETAIL')}: ${t('LABEL_FORECAST')}`,
          url: `/forecast/${periodId}/${forecastId}?tab=${forecastInstanceDetail?.concept_name}`,
        },
        { name: forecastInstanceDetail?.name || '' },
      ]}
    />
  )

  return (
    <SiderLayout extraKey={`-${dataPhaseBudget[current].key}`}>
      <Spin spinning={isScreenLoading}>
        {!isScreenLoading && renderHeader()}
        {!isScreenLoading && (
          <Row gutter={[4, 12]}>
            <Col span={24}>
              <ProjectionSteps
                current={current}
                setCurrent={setCurrent}
                dataProjectionPhase={filterDataPhaseBudgetByRole}
                disablePhases={disablePhases}
              />
            </Col>
            <Col span={24}>
              <BaseForecast
                forecastInstanceDetail={forecastInstanceDetail}
                dataPhase={dataPhaseBudget[current]}
                onRefetch={() => fetchForecastInstanceDetail(projectionId)}
                status_budget={status_budget}
                setTablePagination={setTablePagination}
                tablePagination={tablePagination}
                strictFilter={strictFilter}
              />
            </Col>
          </Row>
        )}
      </Spin>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  forecastInstanceDetail: forecast.selectors.getForecastInstanceDetail(state),
  dataPhaseBudget: planning.selectors.getDataPhaseBudget(state),
})

const mapDispatchToProps = {
  fetchForecastInstanceDetail: forecast.actions.fetchForecastInstanceDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForecastDetail)
