import { useState } from 'react'
import { Avatar, Mentions, Space /*Upload*/ } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import Button from 'modules/core/components/Button/Button'
import Typography from 'modules/core/components/Typography/Typography'
// import { useState } from 'react'
// import { PictureOutlined } from '@ant-design/icons'

import './CardEmpty.scss'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import login from 'modules/login'

const CardEmpty = ({ onCommentCreate, usersList, loggedUser }) => {
  const { t } = useTranslation()
  const [usersIds, setUsersIds] = useState([])
  const [textValue, setTextValue] = useState('')
  // const [value, setValue] = useState(null)
  // const [fileList, setFileList] = useState([])

  return (
    <div className="card-empty-comment">
      <div>
        <Space direction="horizontal">
          <Avatar style={{ backgroundColor: '#0047ba' }} size="small">
            {`${loggedUser?.firstName[0]}`.toUpperCase()}
          </Avatar>
          <Mentions
            placeholder={t('WRITE_COMMENT_PLACEHOLDER')}
            rows={2}
            // style={{ width: 205 }}
            onSelect={(d) => setUsersIds((prevState) => [...prevState, parseInt(d.key)])}
            onChange={(value) => setTextValue(value)}
          >
            {usersList?.values.map((user) => (
              <Mentions.Option key={user.id} value={`${user.first_name}${user.last_name}`}>
                {user.first_name}
                {user.last_name}
              </Mentions.Option>
            ))}
          </Mentions>
          <Button.Icon
            icon={<Typography.Icon level={2} icon={SendOutlined} />}
            title="enviar"
            disabled={!textValue}
            onClick={() => onCommentCreate(textValue, usersIds)}
          />
        </Space>
        {/* {value && (
            <Upload
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture"
            fileList={fileList}
            onChange={(file)=>setFileList(file.fileList)}
        >
            <Button.Icon icon={<Typography.Icon level={2} icon={PictureOutlined} />} />
        </Upload>
        )} */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

export default connect(mapStateToProps, null)(CardEmpty)
