import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { uniq } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Col, notification, Row, Select, Space, Spin } from 'antd'
import {
  ButtonActionBar,
  CustomBottomAction,
  DownloadModal,
  DropdownMenu,
  PeriodNavigator,
  Typography,
} from 'modules/core/components'
import { PROJECTION, REPORT, TABLES } from 'modules/core/constants'
import { ReportKPICarousel } from 'modules/reports/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { generateMessageError, generateSelectOptions, isInElaboration } from 'modules/core/utils'
import { ButtonDownloadPPT, ReportChart, TabsReports } from './components'
import { useDefaultCurrency, usePagination } from 'modules/core/customHooks'
import { SolutionOutlined } from '@ant-design/icons'
import forecast from 'modules/forecast'
import planning from 'modules/planning'
import reports from 'modules/reports'
import configuration from 'modules/configuration'
import login from 'modules/login'

import './ReportDetail.scss'

const DEFAULT_SELECT = {
  value: PROJECTION.REAL,
  id: null,
}

const ReportDetail = ({
  periodDetail,
  selectedDates,
  filters,
  commentKey,
  reportDetail: { columns, rows, lineId, name_report },
  fetchDataEvolution,
  fetchDataComparative,
  activeOptionPeriodTable,
  analysisKPIs,
  fetchGlobalBudgetList,
  fetchGlobalForecastFilterList,
  globalBudgetList,
  globalForecastFilterList,
  downloadReportPPTDrive,
  loggedUser: { folder_id },
  fetchReportDetailStructure,
  reportStructure,
  fetchDataGraphAndKpiByReport,
  dataKpisByReport,
  dataGraphByReport,
  fetchDataEvolutionByReport,
  fetchDataComparativeByReport,
  fetchCurrencyList,
  currencyList,
  fetchCommentsList,
  commentsList,
}) => {
  // Feature flag
  const enabledEvolutionTable = useFeatureIsOn('feature-react-pivot-evolution')
  const enabledComparativeTable = useFeatureIsOn('feature-react-pivot-comparative')
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const enableKpis = useFeatureIsOn('feature-visible-kpi')

  const DEFAULT_REFERENCE_COMPARISON = [PROJECTION.REAL_LAST_PERIOD]
  const [isLoadingGraphAndKpi, setIsLoadingGraphAndKpi] = useState(true)
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isEvolutionLoading, setIsEvolutionLoading] = useState(true)
  const [iscomparativeLoading, setIsComparativeLoading] = useState(true)
  const [periodTableSettings, setPeriodTableSettings] = useState(TABLES.DEFAULT_SETTINGS)
  const [selectedProjection, setSelectedProjection] = useState(DEFAULT_SELECT)
  const [referenceBase, setReferenceBase] = useState(DEFAULT_SELECT)
  const [referenceComparison, setReferenceComparison] = useState(DEFAULT_REFERENCE_COMPARISON)
  const [keyComparisonTable, setKeyComparisonTable] = useState(REPORT.REPORTS_KEYS.ACCUMULATED)
  const [tablePagination, setTablePagination] = usePagination()
  const [tabKey, setTabKey] = useState(REPORT.REPORTS_KEYS.EVOLUTION_KEY)
  const [showModal, setShowModal] = useState(false)
  const [lineVertical, setLineVertical] = useState(null)
  const [currencyChange, setCurrencyChange] = useState(null)
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false)
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const defaultCurrencyData = useDefaultCurrency()

  let { periodId, reportId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  const auxCurrencyId = currencyChange ? currencyChange : defaultCurrencyData?.id

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: commentKey }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, commentKey])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    Promise.all([
      fetchGlobalForecastFilterList({ period_id: periodId }),
      fetchGlobalBudgetList({ period_id: periodId }),
    ])
  }, [fetchGlobalBudgetList, fetchGlobalForecastFilterList, periodId])

  useEffect(() => {
    fetchReportDetailStructure(reportId)
  }, [fetchReportDetailStructure, reportId])

  useEffect(() => {
    setIsCurrencyLoading(true)
    fetchCurrencyList().then(() => {
      setIsCurrencyLoading(false)
    })
  }, [fetchCurrencyList])

  useEffect(() => {
    if (tabKey !== REPORT.REPORTS_KEYS.EVOLUTION_KEY) return

    if (enabled) {
      if (!auxCurrencyId) return
    }

    setIsEvolutionLoading(true)
    setIsLoadingGraphAndKpi(true)
    const dataBaseSend = {
      period_id: periodId,
      filter_date: selectedDates,
      filter: filters,
      type_report: selectedProjection,
      ...(auxCurrencyId && { currency_id: auxCurrencyId }),
    }
    if (enabledEvolutionTable) {
      // TODO: NEW FETCH FOR REVAMP TABLE
      fetchDataGraphAndKpiByReport(reportId, dataBaseSend).then(() => {
        setIsLoadingGraphAndKpi(false)
      })

      fetchDataEvolutionByReport(reportId, {
        ...dataBaseSend,
        vertical_analysis: lineVertical,
        page: tablePagination.page,
      })
        .then(() => {
          setIsScreenLoading(false)
          setIsEvolutionLoading(false)
        })
        .catch((e) => {
          setIsScreenLoading(false)
          setIsEvolutionLoading(false)
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(e, 'REPORT_DETAIL_FAIL_FEEDBACK_DESCRIPTION'),
            duration: 0,
          })
        })
    } else {
      // TODO: DELETE FOR REVAMP TABLE
      fetchDataEvolution(reportId, {
        ...dataBaseSend,
        vertical_analysis: lineVertical,
        page: tablePagination.page,
      })
        .then(() => {
          setIsScreenLoading(false)
          setIsEvolutionLoading(false)
          setIsLoadingGraphAndKpi(false)
        })
        .catch((e) => {
          setIsScreenLoading(false)
          setIsEvolutionLoading(false)
          setIsLoadingGraphAndKpi(false)
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(e, 'REPORT_DETAIL_FAIL_FEEDBACK_DESCRIPTION'),
            duration: 0,
          })
        })
    }
    // eslint-disable-next-line
  }, [
    periodId,
    reportId,
    activeOptionPeriodTable,
    selectedDates,
    selectedProjection,
    tabKey,
    filters,
    auxCurrencyId,
  ])

  useEffect(() => {
    if (tabKey !== REPORT.REPORTS_KEYS.COMPARISON_KEY) return
    setIsComparativeLoading(true)
    const dataBaseSend = {
      period_id: periodId,
      filter_date: selectedDates,
      filter: filters,
      reference_base: referenceBase,
      reference_comparative: referenceComparison,
      comparative_projections: buildProjection(referenceBase, referenceComparison),
      report_type: keyComparisonTable,
      ...(auxCurrencyId && { currency_id: auxCurrencyId }),
    }

    if (enabledComparativeTable) {
      // TODO: NEW FETCH FOR REVAMP TABLE
      fetchDataComparativeByReport(reportId, dataBaseSend).then(() => {
        setIsComparativeLoading(false)
      })
    } else {
      // TODO: DELETE FOR REVAMP TABLE
      fetchDataComparative(reportId, dataBaseSend).then(() => {
        setIsComparativeLoading(false)
      })
    }
    // eslint-disable-next-line
  }, [
    reportId,
    periodId,
    selectedDates,
    filters,
    referenceBase,
    referenceComparison,
    keyComparisonTable,
    tabKey,
    auxCurrencyId,
  ])

  useEffect(() => {
    if (globalBudgetList.values.length > 0 || globalForecastFilterList.values.length > 0) {
      let defaultComparisonSelection = []
      const budgetPublish = globalBudgetList?.values
        .filter((global) => !isInElaboration(global.status))
        .find((item) => item.predefined === true)
      if (budgetPublish) {
        defaultComparisonSelection.push(`${PROJECTION.BUDGET}-${budgetPublish.id}`)
      }
      const forecastPublish = globalForecastFilterList?.values
        .filter((global) => !isInElaboration(global.status))
        .find((item) => item.predefined === true)
      if (forecastPublish) {
        defaultComparisonSelection.push(`${PROJECTION.FORECAST}-${forecastPublish.id}`)
      }
      defaultComparisonSelection.push(PROJECTION.REAL_LAST_PERIOD)
      setReferenceComparison(defaultComparisonSelection)
    }
  }, [globalBudgetList.values, globalForecastFilterList.values])

  const groupProjection = {
    BUDGET: globalBudgetList?.values.filter((global) => !isInElaboration(global.status)),
    FORECAST: globalForecastFilterList?.values.filter((global) => !isInElaboration(global.status)),
  }

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_ANALYSIS'),
          url: `/analisis/${periodId}?tab=reportesplika`,
        },
        {
          name: t('REPORT_PLIKA_CARD_TITLE'),
        },
        { name: reportStructure.name || '' },
      ]}
    />
  )

  const onRefetchComparative = () => {
    setIsComparativeLoading(true)
    fetchDataComparativeByReport(reportId, {
      period_id: periodId,
      filter_date: selectedDates,
      filter: filters,
      reference_base: referenceBase,
      reference_comparative: referenceComparison,
      report_type: keyComparisonTable,
    }).then(() => {
      setIsComparativeLoading(false)
    })
  }

  const onRefetchEvolution = () => {
    setIsEvolutionLoading(true)
    fetchDataEvolutionByReport(reportId, {
      period_id: periodId,
      filter_date: selectedDates,
      filter: filters,
      type_report: selectedProjection,
      vertical_analysis: lineVertical,
      page: tablePagination.page,
    }).then(() => {
      setIsEvolutionLoading(false)
    })
  }

  const onRefetch = () => {
    setIsEvolutionLoading(true)
    const dataBaseSend = {
      period_id: periodId,
      filter_date: selectedDates,
      filter: filters,
      type_report: selectedProjection,
      page: tablePagination.page,
    }

    if (enabledEvolutionTable) {
      fetchDataEvolutionByReport(reportId, dataBaseSend).then(() => setIsEvolutionLoading(false))
    } else {
      // TODO: DELETE FOR REVAMP TABLE
      fetchDataEvolution(reportId, dataBaseSend).then(() => setIsEvolutionLoading(false))
    }
  }

  const getDataKpis = () => {
    if (enabledEvolutionTable) {
      return dataKpisByReport
    }
    return analysisKPIs
  }

  const getDataGraph = () => {
    if (enabledEvolutionTable) {
      return dataGraphByReport.rows
    }
    return rows
  }

  const normalizeProjection = (projection, id) => {
    if (projection === PROJECTION.BUDGET) {
      return `overallbudget-${id}`
    } else if (projection === PROJECTION.FORECAST) {
      return `forecastoverallbudget-${id}`
    } else {
      return projection
    }
  }

  const buildProjection = (base, comparison) => {
    const { value: baseValue, id: baseId } = base
    const valueBase = normalizeProjection(baseValue, baseId)

    const valueComparison = comparison.map((item) => {
      const [value, id] = item.split('-')
      return normalizeProjection(value, id)
    })

    return uniq([valueBase, ...valueComparison])
  }

  return (
    <div className="report-detail-container">
      <Spin spinning={isScreenLoading} size="large" />
      {!isScreenLoading && renderHeader()}
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <ButtonActionBar position="space-between">
            <Space direction="horizontal">
              {enabled && (
                <>
                  <Typography.Body>{t('LABEL_CURRENCY')}:</Typography.Body>
                  <Select
                    bordered={false}
                    value={currencyChange ? currencyChange : defaultCurrencyData?.id}
                    loading={isCurrencyLoading}
                    placeholder={t('ACTION_SELECT')}
                    options={generateSelectOptions({ options: currencyList.data })}
                    onChange={(val) => setCurrencyChange(val)}
                  />
                </>
              )}
            </Space>
            <Space direction="horizontal">
              <ButtonDownloadPPT
                reportId={reportId}
                params={{
                  period_id: periodId,
                  filter_date: selectedDates,
                  filter: filters,
                  type_report: selectedProjection,
                }}
                periodDetail={periodDetail}
                name_report={name_report}
                setShowModal={setShowModal}
              />
              <CustomBottomAction
                extraButtons={{ module_name: 'reportes', concept: '1', submodule_name: reportId }}
              />
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={[
                  {
                    title: t('ACTION_ACTIVITIES'),
                    icon: <SolutionOutlined />,
                    onClick: () =>
                      history.push(
                        `/analisis/${periodId}/reportesplika/${reportId}/actividad?type=report&module=analisisDetail`,
                      ),
                  },
                ]}
              />
            </Space>
          </ButtonActionBar>
        </Col>
        {enableKpis && (
          <Col span={24}>
            <ReportKPICarousel
              dataKPI={getDataKpis()}
              isLoading={isLoadingGraphAndKpi}
              setDataComments={setDataComments}
              dataComments={dataComments}
              onRefetchComments={() => fetchCommentsList({ module: commentKey })}
              loadingComments={isLoadingComments}
              commentKey={commentKey}
              currencyData={
                currencyChange
                  ? currencyList.data.find((el) => el.id === currencyChange)
                  : defaultCurrencyData
              }
            />
          </Col>
        )}
        <Col span={24}>
          <TabsReports
            isEvolutionLoading={isEvolutionLoading}
            iscomparativeLoading={iscomparativeLoading}
            filters={filters}
            selectedDates={selectedDates}
            groupProjection={groupProjection}
            periodTableSettings={periodTableSettings}
            setPeriodTableSettings={setPeriodTableSettings}
            selectedProjection={selectedProjection}
            setSelectedProjection={setSelectedProjection}
            referenceBase={referenceBase}
            setReferenceBase={setReferenceBase}
            referenceComparison={referenceComparison}
            setReferenceComparison={setReferenceComparison}
            keyComparisonTable={keyComparisonTable}
            setKeyComparisonTable={setKeyComparisonTable}
            setTablePagination={setTablePagination}
            tablePagination={tablePagination}
            onRefetch={onRefetch}
            tabKey={tabKey}
            setTabKey={setTabKey}
            lineVertical={lineVertical}
            setLineVertical={setLineVertical}
            onRefetchComparative={onRefetchComparative}
            onRefetchEvolution={onRefetchEvolution}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={() => fetchCommentsList({ module: commentKey })}
            loadingComments={isLoadingComments}
            commentKey={commentKey}
            currencyData={
              currencyChange
                ? currencyList.data.find((el) => el.id === currencyChange)
                : defaultCurrencyData
            }
          />
        </Col>
        <Col span={24}>
          <ReportChart
            rows={getDataGraph()}
            lineId={lineId}
            selectedDates={selectedDates}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={() => fetchCommentsList({ module: commentKey })}
            loadingComments={isLoadingComments}
            commentKey={commentKey}
            currencyData={
              currencyChange
                ? currencyList.data.find((el) => el.id === currencyChange)
                : defaultCurrencyData
            }
          />
        </Col>
      </Row>
      <DownloadModal
        title="PPT"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        defaultValue={folder_id}
        dataSpreadsheets={{
          type_load: 'report',
          params: {
            report_id: reportId,
            period_id: periodId,
          },
        }}
        extraAction={() =>
          downloadReportPPTDrive(reportId, {
            period_id: periodId,
            filter_date: selectedDates,
            filter: filters,
            type_report: selectedProjection,
          })
        }
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  reportDetail: reports.selectors.getReportDetail(state),
  activeOptionPeriodTable: planning.selectors.getActiveOptionPeriodTable(state),
  analysisKPIs: reports.selectors.getAnalysisKPIs(state),
  globalBudgetList: planning.selectors.getGlobalBudgetList(state),
  globalForecastFilterList: forecast.selectors.getGlobalForecastFilterList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  dataKpisByReport: reports.selectors.getDataKpisByReport(state),
  dataGraphByReport: reports.selectors.getDataGraphByBaseReport(state),
  currencyList: configuration.selectors.getCurrencyList(state),
  reportStructure: reports.selectors.getReportStructure(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchDataEvolution: reports.actions.fetchReportDetail,
  fetchDataComparative: reports.actions.fetchReportComparativeDetail,
  fetchGlobalBudgetList: planning.actions.fetchGlobalBudgetList,
  fetchGlobalForecastFilterList: forecast.actions.fetchGlobalForecastFilterList,
  downloadReportPPTDrive: reports.actions.downloadReportPPTDrive,
  fetchReportDetailStructure: reports.actions.fetchReportDetailStructure,
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  fetchDataGraphAndKpiByReport: reports.actions.fetchDataGraphAndKpiByReport,
  fetchDataEvolutionByReport: reports.actions.fetchDataEvolutionByReport,
  fetchDataComparativeByReport: reports.actions.fetchDataComparativeByReport,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail)
