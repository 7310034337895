import { Col, Empty, message, Popover, Row, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  HolderOutlined,
  BgColorsOutlined,
  RightCircleOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import { MoreActions } from '../CardExtra/components'
import { BarChart, LineChart } from 'modules/core/components/Charts'
import { generateMessageError, generateSelectOptions } from 'modules/core/utils'
import { ChromePicker } from 'react-color'
import { useState } from 'react'
import { connect } from 'react-redux'
import Button from 'modules/core/components/Button/Button'
import CardTitle from '../CardTitle/CardTitle'
import Comments from 'modules/core/components/Comments/Comments'
import ButtonActionBar from 'modules/core/components/ButtonActionBar/ButtonActionBar'
import Typography from 'modules/core/components/Typography/Typography'
import StatusTag from 'modules/core/components/StatusTag/StatusTag'
import numeral from 'numeral'
import configuration from 'modules/configuration'
import _ from 'lodash'

const CardBodyKpi = ({
  title,
  loading,
  onConfirmPopconfirm,
  onCancelPopconfirm,
  visible,
  onClickEdit,
  disabled,
  onClickDuplicate,
  onClickDelete,
  onOpenActivities,
  type,
  attributes,
  listeners,
  tagStyle,
  onOpen,
  is_closing,
  status,
  kpi,
  dataLineChart,
  dataRankingUp,
  allDimensionsList,
  editName,
  dimension,
  setDimensionId,
  open,
  color,
  setColor,
  onClickCancelSaveColor,
  onClickSaveColor,
  onClickIconColor,
  isEditing,
  handleEdit,
  titleValue,
  conceptName,
  loggedUser,
  hasComment,
  setHasComment,
  viewComment,
  setViewComment,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  cardId,
  createComment,
  removeNewComment,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `${auxKey}?tab=${conceptName}` : auxKey

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-card-${cardId}`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }

  const renderCardTitle = () => (
    <CardTitle
      isEditing={isEditing}
      editName={editName}
      handleEdit={handleEdit}
      titleValue={titleValue}
    />
  )

  return (
    <Row gutter={[12, 4]}>
      <Col span={23}>
        <Space direction="horizontal">
          <h2 className="title-style" onClick={!editName && onOpen}>
            {renderCardTitle()}
          </h2>
          <Typography.Icon style={{ marginBottom: 10 }} level={2} icon={RightCircleOutlined} />
        </Space>
      </Col>
      <Col span={1}>
        <ButtonActionBar>
          <StatusTag is_closing={is_closing} status={status} />
          <Popover
            placement="bottom"
            trigger="click"
            visible={open}
            content={
              <Space direction="vertical">
                <ChromePicker
                  disableAlpha
                  color={color}
                  onChangeComplete={(col) => setColor(col.hex)}
                />
                <ButtonActionBar position="space-between">
                  <Button.Default title="ACTION_CANCEL" onClick={onClickCancelSaveColor} />
                  <Button.Primary title="ACTION_SAVE" onClick={onClickSaveColor} />
                </ButtonActionBar>
              </Space>
            }
          >
            <Button.Icon
              title={t('ACTION_CHANGE_COLOR')}
              icon={<Typography.Icon icon={BgColorsOutlined} />}
              onClick={onClickIconColor}
            />
          </Popover>
          {(viewComment ||
            dataComments.filter((el) => el.key === `${key}-card-${cardId}` && !el.resolved).length >
              0) && (
            <Comments
              hasComment={
                !_.isEmpty(hasComment?.card)
                  ? !_.isEmpty(hasComment?.card[key]) && hasComment?.card[key][cardId]
                  : false
              }
              onClick={(visible) => {
                setHasComment({ type: 'card', data: visible, key, id: cardId })
                !visible && removeNewComment()
                !visible && setViewComment(visible)
              }}
              comments={dataComments.filter(
                (el) => (el.key === `${key}-card-${cardId}` && !el.resolved) || !el.text,
              )}
              setData={setDataComments}
              loading={loadingComments || isSaving}
              onRefetchComments={onRefetchComments}
              onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
                handleCreate({ text, usersIds, idComment, setIsNewThread })
              }
              onCleanHasComment={() => {
                setHasComment({ type: 'card', data: false, key, id: cardId })
                setViewComment(false)
              }}
            >
              <Typography.Icon
                style={{ cursor: 'pointer' }}
                title={t('LABEL_COMMENTS')}
                icon={CommentOutlined}
              />
            </Comments>
          )}
          <MoreActions
            title={title}
            loading={loading}
            onConfirmPopconfirm={onConfirmPopconfirm}
            onCancelPopconfirm={onCancelPopconfirm}
            visible={visible}
            onClickEdit={onClickEdit}
            disabled={disabled}
            onClickDuplicate={onClickDuplicate}
            onClickDelete={onClickDelete}
            onOpenActivities={onOpenActivities}
            type={type}
          />
          <div className="drag-card-icon" {...attributes} {...listeners} style={tagStyle}>
            <Typography.Icon title={t('ACTION_SORT')} icon={HolderOutlined} />
          </div>
        </ButtonActionBar>
      </Col>
      {(kpi === null || kpi === 0) && (
        <Col span={24}>
          <Empty description={t('PLANNING_EMPTY_DATA_CARDS_BUDGET_TYPE_BODY_TEXT', { title })} />
        </Col>
      )}
      {kpi !== null && kpi !== 0 && (
        <>
          <Col
            span={4}
            className="number-container-col"
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            <Space direction="vertical" size="small">
              <div className="text-container">
                <Typography.Headline title={numeral(kpi).format('0,0.00')} level={3}>
                  {numeral(kpi).format('0[.]0a')}
                </Typography.Headline>
              </div>
              <div className="text-container">
                <Typography.Body level={3}>Total {conceptName}</Typography.Body>
              </div>
            </Space>
          </Col>
          <Col span={10} className="chart-cotainer" style={{ paddingLeft: 20, paddingRight: 20 }}>
            <LineChart
              containerHeight={150}
              margin={{ right: 15, left: 15, top: 30, bottom: 30 }}
              axisLeft={false}
              enableGridY={false}
              enablePoints={false}
              extralegends={{
                anchor: 'top-left',
                direction: 'row',
                translateX: 0,
                itemWidth: 85,
                symbolSize: 10,
              }}
              data={dataLineChart}
            />
          </Col>
          <Col span={10} style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Typography.Body level={3}>{t('FIELD_BEST_SELLERS')}</Typography.Body>
            <Select
              defaultValue={dimension}
              bordered={false}
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              showSearch
              onChange={setDimensionId}
              options={generateSelectOptions({
                options: allDimensionsList.map((el) => {
                  return { id: el.id, name: el.name_destination }
                }),
              })}
            />
            <BarChart
              containerHeight={110}
              margin={{ left: 100, top: 0, bottom: 0 }}
              enableLabel={true}
              enableGridY={false}
              enableGridX={false}
              axisBottom={null}
              legends={[]}
              layout="horizontal"
              indexBy="dimension"
              keys={dataRankingUp.map((el) => {
                return _.keys(el).filter((e) => e !== 'dimension')[0]
              })}
              data={dataRankingUp}
              tooltip={(data) => {
                return (
                  <div className="tooltip-chart-container">
                    <Space direction="horizontal">
                      <div style={{ width: 12, height: 12, backgroundColor: data.color }}></div>
                      <div style={{ width: 300 }}>
                        <Typography.Body level={3}>{data.id}: </Typography.Body>
                        <strong>{data.formattedValue}%</strong>
                      </div>
                    </Space>
                  </div>
                )
              }}
              axisLeft={{
                renderTick: (d) => {
                  return (
                    <g transform={`translate(${d.x},${d.y})`}>
                      <text
                        alignmentBaseline={d.textBaseline}
                        textAnchor={d.textAnchor}
                        transform={`translate(${d.textX},${d.textY})`}
                        style={{ fontSize: 10 }}
                      >
                        {d.value.length <= 10
                          ? d.value
                          : `${d.value.slice(0, -d.value.length + 10)}...`}
                      </text>
                    </g>
                  )
                },
              }}
            />
          </Col>
        </>
      )}
    </Row>
  )
}

const mapDispatchToProps = {
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(null, mapDispatchToProps)(CardBodyKpi)
