import React from 'react'
import { connect } from 'react-redux'
import { onRedirect } from './utils'
import { Space } from 'antd'
import loginMicrosoft from 'assets/images/login/ms-symbollockup_signin_light.svg'
import loginGoogle from 'assets/images/login/web_light_sq_SI.svg'
import login from 'modules/login'

const LoginOtherContainer = ({ googleRedirect, azureRedirect, whoAmI }) => {
  const containerButtonStyle = {
    display: 'flex',
    justifyContent: 'center',
  }

  const buttonStyle = {
    backgroundColor: '#D9D9D9',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div style={containerButtonStyle}>
        <button style={buttonStyle} onClick={() => onRedirect(googleRedirect, whoAmI)}>
          <img src={loginGoogle} alt="login Google" />
        </button>
      </div>
      <div style={containerButtonStyle}>
        <button style={buttonStyle} onClick={() => onRedirect(azureRedirect, whoAmI)}>
          <img width={175} height={40} src={loginMicrosoft} alt="login Microsoft" />
        </button>
      </div>
    </Space>
  )
}

const mapDispatchToProps = {
  googleRedirect: login.actions.googleRedirect,
  azureRedirect: login.actions.azureRedirect,
  whoAmI: login.actions.whoAmI,
}

export default connect(null, mapDispatchToProps)(LoginOtherContainer)
