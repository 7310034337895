import React, { useEffect, useState } from 'react'
import { Alert, Col, Row, Spin, notification } from 'antd'
import { CollapseHeader, CollapseRules, UserActions } from './components'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GENERAL, ROLES } from 'modules/core/constants'
import { generateMessageError, isUserAllowed } from 'modules/core/utils'
import { connect } from 'react-redux'
import EmptyScreen from 'modules/core/components/EmptyScreen/EmptyScreen'
import Button from 'modules/core/components/Button/Button'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import moment from 'moment'
import ProjectionUploadModal from 'modules/core/components/ProjectionUploadModal/ProjectionUploadModal'

import './RuleComponent.scss'

const {
  SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM,
  SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM,
} = ROLES

const RuleComponent = ({
  visible,
  globalProjectionId,
  type,
  onRefetch,
  applyRule,
  unApplyRule,
  disabled = false,
  ruleList,
  downloadVariablesTemplateXLS,
  periodList,
  uploadVariablesFileXLS,
  fetchRulesList,
  typeModule = GENERAL.MODULES_KEYS.MODULE_PLANNING,
  moduleId = 0,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  let { periodId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))

  useEffect(() => {
    if (visible) {
      fetchRulesList({ period_id: periodId, type, pk: globalProjectionId }).then(() =>
        setIsScreenLoading(false),
      )
    }
  }, [fetchRulesList, dataPeriod, visible, periodId, type, globalProjectionId])

  const userCanRuleCreate = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM)
  const userCanVariableCreate = isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__VARIABLES__ABM)

  const onSuccessUploadFile = ({ setIsLoading, fetchRulesList }) => {
    // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL);
    notification.success({
      message: t('FEEDBACK_VERY_GOOD'),
      description: t('FEEDBACK_LOAD_DATA_SUCCESS'),
    })
    setIsLoading(true)
    fetchRulesList({ period_id: periodId, type, pk: globalProjectionId }).then(() =>
      setIsLoading(false),
    )
  }

  const onFailUploadFile = (error) => {
    notification.error({
      key: 'error_file',
      message: t('FEEDBACK_DEFAULT_ERROR'),
      description: generateMessageError(error),
      duration: 0,
    })
    // GA.event(GA.ACTIONS.FILE_UPLOAD_REAL_ERROR);
  }

  const renderAlert = () => (
    <Alert
      showIcon
      closable
      type="info"
      description={t('PLANNING_RULE_KEEP_DATA_INFORMATIVE_ALERT_DESCRIPTION')}
    />
  )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_FORMULAS_EMPTY_SCREEN_TITLE')}
      description={t('CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <Button.Primary
          onClick={() => history.push('/configuracion/herramientas/')}
          title="ACTION_GO_TO_CONFIGURE"
        />
      }
    />
  )

  const getData = () => {
    if (searchValue) {
      return ruleList.values.filter((el) =>
        el.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    }
    return ruleList.values
  }

  return (
    <Spin spinning={isScreenLoading}>
      <Row gutter={[24, 24]}>
        {ruleList.count === 0 && <Col span={24}>{renderNoDataScreen()}</Col>}
        {ruleList.count > 0 && (
          <>
            <Col span={24}>
              <UserActions
                disabled={!userCanVariableCreate || disabled}
                onClick={() => setShowUploadModal(true)}
                setSearchValue={setSearchValue}
              />
            </Col>
            <Col span={24}>{renderAlert()}</Col>
            <Col span={24}>
              <CollapseHeader />
              <CollapseRules
                ruleList={getData()}
                canCreate={userCanRuleCreate || userCanVariableCreate}
                onRefetch={onRefetch}
                onRefetchRules={() =>
                  fetchRulesList({ period_id: periodId, type, pk: globalProjectionId })
                }
                applyRule={applyRule}
                unApplyRule={unApplyRule}
                disabled={disabled}
                typeModule={typeModule}
                moduleId={moduleId}
              />
            </Col>
          </>
        )}
      </Row>
      <ProjectionUploadModal
        title={t('CONFIG_VAR_UPLOAD_VALUES_MODAL_TITLE')}
        visible={showUploadModal}
        onCancel={() => setShowUploadModal(false)}
        uploadFile={(fileList) =>
          uploadVariablesFileXLS(periodId, fileList, type, globalProjectionId)
        }
        onSuccessUpload={() =>
          onSuccessUploadFile({
            setIsLoading: setIsScreenLoading,
            fetchRulesList,
          })
        }
        onFailUpload={(error) => onFailUploadFile(error)}
        downloadTemplateFile={() =>
          downloadVariablesTemplateXLS(
            periodId,
            t('PLANNING_TEMPLATE_UPLOAD_VARIABLES_VALUES', {
              periodName: dataPeriod.name,
              date: moment().format('lll'),
            }),
            { type, pk: globalProjectionId },
          )
        }
      />
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
  ruleList: configuration.selectors.getRulesList(state),
})

const mapDispatchToProps = {
  downloadVariablesTemplateXLS: planning.actions.downloadVariablesTemplateXLS,
  uploadVariablesFileXLS: planning.actions.uploadVariablesFileXLS,
  fetchRulesList: configuration.actions.fetchRulesList,
}

export default connect(mapStateToProps, mapDispatchToProps)(RuleComponent)
