import { Select } from 'antd'
import { generateSelectOptions, handleSelectAll } from 'modules/core/utils'

export const dimensionsColumns = ({
  key,
  dimensionsList,
  loading,
  setDimensionsSelection,
  areAllSelected,
  setAreAllSelected,
}) => {
  const renderValue = (record) => {
    if (record && record?.dataValueSelect) {
      return record?.dataValueSelect
    }

    return undefined
  }

  return dimensionsList.map((item) => {
    return {
      title: item.name_destination,
      dataIndex: item.name_destination,
      width: 200,
      render: (record, row) => {
        return record ? (
          <Select
            key={record.id}
            style={{ width: 200 }}
            value={renderValue(record)}
            loading={loading}
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={8}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={record?.name || ''}
            options={generateSelectOptions({
              options: record?.dimensionValues
                ? record?.dimensionValues.map((op) => {
                    return {
                      id: `${item?.name_destination}_${op.id}`,
                      name: op.name_destination,
                      code: op.code_destination,
                    }
                  })
                : [],
              includeOptionAll: true,
            })}
            onChange={(ids) =>
              handleSelectAll({
                values: ids,
                allValues: record?.dimensionValues
                  ? record?.dimensionValues.map((op) => `${item?.name_destination}_${op.id}`)
                  : [],
                areAllSelected,
                onSetValues: (values) =>
                  setDimensionsSelection({
                    key,
                    index: row.id,
                    data: { [item.id]: values.map((id) => parseInt(id.split('_')[1])) },
                    dimensionName: item.name_destination,
                    dataValueSelect: values,
                    dimensionValues: record?.dimensionValues || [],
                  }),
                onSetAreAllSelected: (state) => setAreAllSelected(state),
              })
            }
          />
        ) : null
      },
    }
  })
}
