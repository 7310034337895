import { DatePicker, Form, Input, Modal, Select } from 'antd'
import _ from 'lodash'
import { PROJECTION } from 'modules/core/constants'
import { generateSelectOptions } from 'modules/core/utils'
import forecast from 'modules/forecast'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { onCreate } from './utils'

const CreateForecastInstanceModal = ({
  visible,
  onCancel,
  optionsSelect,
  onRefetch,
  conceptId,
  dataPeriod,
  createForecastInstance,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  let { forecastId } = useParams()

  const onclose = () => {
    form.resetFields()
    setIsLoading(false)
    onCancel()
  }

  const handleCreate = (values) => {
    const { type_budget, dates, projections, name } = values

    const splitterProjections = projections.map((it) => {
      const splitter = it.split('_')
      return { id: parseInt(splitter[0]), type: splitter[1] }
    })

    const data = {
      name,
      type_budget,
      concept: conceptId,
      forecast_id: forecastId,
      overall_budget_ids: splitterProjections
        .filter((it) => it.type === 'budget')
        .map((it) => it.id),
      forecast_overall_budget_ids: splitterProjections
        .filter((it) => it.type === 'forecast')
        .map((it) => it.id),
      ...(dates && {
        date_start: moment(dates[0]).format('YYYY-MM-DD'),
        date_end: moment(dates[1]).format('YYYY-MM-DD'),
      }),
    }

    return createForecastInstance(data)
  }

  const disabledDateZeroValue = (date) => {
    const pickerDates = moment(date._d).utc().format('YYYY-MM')
    return !(pickerDates >= dataPeriod.start_date && pickerDates <= dataPeriod.end_date)
  }

  const hasZeroValue = (value) => {
    return value && value.toString() === 'VALOR_CERO'
  }

  return (
    <Modal
      title={t('FORECAST_CREATE_MODAL_TITLE')}
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      centered
      forceRender
      visible={visible}
      onCancel={onCancel}
      onOk={() =>
        onCreate({
          form,
          setIsLoading,
          createForecastInstance: (values) => handleCreate(values),
          onRefetch,
          onclose,
        })
      }
      destroyOnClose={true}
      okButtonProps={{ loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form form={form} layout="vertical">
        {() => (
          <>
            <Form.Item
              label={t('FIELD_NAME')}
              name="name"
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Input placeholder={t('ENTER_NAME_PLACEHOLDER')} />
            </Form.Item>

            <Form.Item
              name="type_budget"
              label={t('FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL')}
              rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                options={generateSelectOptions({
                  options: [
                    {
                      id: 'VALOR_CERO',
                      name: 'PLANNING_CREATE_NEW_BUDGET_BASE_OPTION_FORM_SELECT',
                      type: 'cero',
                    },
                  ].map((op) => {
                    return { ...op, name: t(op.name) }
                  }),
                  dataTooltipLabel: 'PLANNING_UPLOAD_VALUE_CERO_INFORMATIVE_TOOLTIP_TITLE',
                })}
              />
            </Form.Item>

            {hasZeroValue(form.getFieldsValue().type_budget) && (
              <>
                <Form.Item
                  name="dates"
                  label={t('PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL')}
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <DatePicker.RangePicker
                    format="MMM YYYY"
                    picker="month"
                    style={{ width: '100%' }}
                    disabledDate={(date) => disabledDateZeroValue(date)}
                    defaultPickerValue={
                      !_.isEmpty(dataPeriod)
                        ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
                        : null
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="projections"
                  label={t('FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL')}
                  rules={[
                    {
                      required: true,
                      message: t('REQUIRED_FIELD'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    showArrow
                    allowClear
                    showSearch
                    mode="multiple"
                    maxTagCount={3}
                    maxTagTextLength={1}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={generateSelectOptions({
                      options: optionsSelect.map((el) => {
                        return {
                          id: `${el.id}_${el.type}`,
                          name: `${el.name}(${t(el.type)})-(${t(
                            PROJECTION.STATUS_COLORS[el.status].name,
                          )})`,
                        }
                      }),
                    })}
                  />
                </Form.Item>
              </>
            )}
          </>
        )}
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createForecastInstance: forecast.actions.createForecastInstance,
}

export default connect(null, mapDispatchToProps)(CreateForecastInstanceModal)
