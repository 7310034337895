import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import {
  Button,
  CreateTransactionModal,
  PhaseKpis,
  ToolsDrawer,
  UserActionsPhases,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { isUserAllowed } from 'modules/core/utils'
import { CONCEPTS, PROJECTION, ROLES } from 'modules/core/constants'
import {
  handleDeleteSelection,
  renderButtonSendName,
  renderFunctionSend,
} from 'modules/core/components/ProjectionComponents/utils'
import { Col, message, Row } from 'antd'
import { CreateBudgetModal } from 'modules/planning/components'
import { GENERAL } from '../../../../../core/constants'
import { handleDeleteAll } from './utils'
import { TabsTables } from './components'
import planning from 'modules/planning'
import configuration from 'modules/configuration'
import home from 'modules/home'
import login from 'modules/login'
import _ from 'lodash'

import './BaseBudget.scss'

const {
  PLANNING__SALES__BASE__ABM,
  PLANNING__SALES__TOP_DOWN__ABM,
  PLANNING__SALES__BOTTOM_UP__ABM,
  PLANNING__SALES__CLOSING__ABM,
  PLANNING__EXPENSES__BASE__ABM,
  PLANNING__EXPENSES__TOP_DOWN__ABM,
  PLANNING__EXPENSES__BOTTOM_UP__ABM,
  PLANNING__EXPENSES__CLOSING__ABM,
  PLANNING__COSTS__BASE__ABM,
  PLANNING__COSTS__TOP_DOWN__ABM,
  PLANNING__COSTS__BOTTOM_UP__ABM,
  PLANNING__COSTS__CLOSING__ABM,
  PLANNING__HUMAN_RESOURCES__BASE__ABM,
  PLANNING__HUMAN_RESOURCES__TOP_DOWN__ABM,
  PLANNING__HUMAN_RESOURCES__BOTTOM_UP__ABM,
  PLANNING__HUMAN_RESOURCES__CLOSING__ABM,
  PLANNING__OTHER_5__BASE__ABM,
  PLANNING__OTHER_5__TOP_DOWN__ABM,
  PLANNING__OTHER_5__BOTTOM_UP__ABM,
  PLANNING__OTHER_5__CLOSING__ABM,
  PLANNING__OTHER_6__BASE__ABM,
  PLANNING__OTHER_6__TOP_DOWN__ABM,
  PLANNING__OTHER_6__BOTTOM_UP__ABM,
  PLANNING__OTHER_6__CLOSING__ABM,
} = ROLES

const PLANNING__CONCEPT = {
  AMB: {
    [CONCEPTS.IDS.SALES_ID]: {
      [PROJECTION.BASE]: PLANNING__SALES__BASE__ABM,
      [PROJECTION.TOPDOWN]: PLANNING__SALES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: PLANNING__SALES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: PLANNING__SALES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.EXPENSES_ID]: {
      [PROJECTION.BASE]: PLANNING__EXPENSES__BASE__ABM,
      [PROJECTION.TOPDOWN]: PLANNING__EXPENSES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: PLANNING__EXPENSES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: PLANNING__EXPENSES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.COSTS_ID]: {
      [PROJECTION.BASE]: PLANNING__COSTS__BASE__ABM,
      [PROJECTION.TOPDOWN]: PLANNING__COSTS__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: PLANNING__COSTS__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: PLANNING__COSTS__CLOSING__ABM,
    },
    [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: {
      [PROJECTION.BASE]: PLANNING__HUMAN_RESOURCES__BASE__ABM,
      [PROJECTION.TOPDOWN]: PLANNING__HUMAN_RESOURCES__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: PLANNING__HUMAN_RESOURCES__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: PLANNING__HUMAN_RESOURCES__CLOSING__ABM,
    },
    [CONCEPTS.IDS.OTHER_5_ID]: {
      [PROJECTION.BASE]: PLANNING__OTHER_5__BASE__ABM,
      [PROJECTION.TOPDOWN]: PLANNING__OTHER_5__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: PLANNING__OTHER_5__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: PLANNING__OTHER_5__CLOSING__ABM,
    },
    [CONCEPTS.IDS.OTHER_6_ID]: {
      [PROJECTION.BASE]: PLANNING__OTHER_6__BASE__ABM,
      [PROJECTION.TOPDOWN]: PLANNING__OTHER_6__TOP_DOWN__ABM,
      [PROJECTION.BOTTOMUP]: PLANNING__OTHER_6__BOTTOM_UP__ABM,
      [PROJECTION.CLOSING]: PLANNING__OTHER_6__CLOSING__ABM,
    },
  },
}

const BaseBudget = ({
  budgetInstanceDetail,
  dataPhase,
  onRefetch,
  status_budget,
  tablePagination,
  fetchBudgetKpis,
  budgetKPIS,
  setNextPhase,
  closingBudget,
  fetchBudgetInstanceList,
  updateBudgetInstance,
  budgetInstanceList,
  periodList,
  fetchDataNestedTableByPhase,
  uploadSpreadsheets,
  applyBudgetRule,
  unApplyBudgetRule,
  massiveCreatePlanningTransaction,
  fetchAllDimensions,
  fetchDataRdnPercentageByPlanning,
  fetchRulesList,
  deleteAllTransaction,
  loggedUser,
  fetchCommentsList,
  commentsList,
}) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const enableKpis = useFeatureIsOn('feature-visible-kpi')
  const [showPopConfirmSend, setShowPopConfirmSend] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isKpisLoading, setIsKpisLoading] = useState(false)
  const [isTableNestedLoading, setIsTableNestedLoading] = useState(false)
  const [showDrawerTools, setShowDrawerTools] = useState(false)
  const [showDeleteAll, setShowDeleteAll] = useState(false)
  const [isDeletingAll, setIsDeletingAll] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [value, setValue] = useState(PROJECTION.TYPE_UPLOAD_OPTION_DEFAULT)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [applyRule, setApplyRule] = useState(false)
  const [isDisable, setIsDisable] = useState(false)

  let { budgetId, periodId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  const [tabKey, setTabKey] = useState(PROJECTION.PLANNING_KEYS.TRANSACTIONAL_KEY)
  const [businessRuleId, setBusinessRuleId] = useState(null)

  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLoading(true)
    fetchCommentsList({ module: `${key}-${dataPhase.key}` }).then(() => setIsLoading(false))
  }, [fetchCommentsList, key, dataPhase.key])

  useEffect(() => {
    setData(commentsList)
  }, [commentsList])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions, periodId])

  useEffect(() => {
    if (!_.isEmpty(budgetInstanceDetail)) {
      setIsKpisLoading(true)
      fetchBudgetKpis({ step: dataPhase.key, planning_id: budgetId }).then(() =>
        setIsKpisLoading(false),
      )
    }
    return () => setIsKpisLoading(false)
  }, [fetchBudgetKpis, budgetId, dataPhase.key, budgetInstanceDetail])

  useEffect(() => {
    if (tabKey !== PROJECTION.PLANNING_KEYS.TRANSACTIONAL_KEY) return
    if (!_.isEmpty(budgetInstanceDetail)) {
      setIsTableNestedLoading(true)
      fetchDataNestedTableByPhase({
        step: dataPhase.key,
        period_id: periodId,
        concept_id: budgetInstanceDetail?.concept_id,
        planning_id: budgetId,
        page: tablePagination.page,
      }).then(() => setIsTableNestedLoading(false))
    }
    return () => setIsTableNestedLoading(false)
  }, [
    fetchDataNestedTableByPhase,
    budgetId,
    periodId,
    dataPhase.key,
    budgetInstanceDetail,
    tablePagination.page,
    tabKey,
  ])

  useEffect(() => {
    if (tabKey !== PROJECTION.PLANNING_KEYS.PERCENTAGE_KEY) return
    if (!_.isEmpty(budgetInstanceDetail) && businessRuleId) {
      setIsTableNestedLoading(true)
      fetchDataRdnPercentageByPlanning({
        concept_id: budgetInstanceDetail?.concept_id,
        page: tablePagination.page,
        period_id: periodId,
        planning_id: budgetId,
        step: dataPhase.key,
        business_rule_id: businessRuleId,
      }).then(() => setIsTableNestedLoading(false))
    }
    return () => setIsTableNestedLoading(false)
  }, [
    fetchDataRdnPercentageByPlanning,
    budgetId,
    periodId,
    dataPhase.key,
    budgetInstanceDetail,
    tabKey,
    businessRuleId,
  ])

  useEffect(() => {
    fetchRulesList({ period_id: periodId, type: 'planning', pk: budgetId }).then((resp) => {
      const result = resp.payload.data
      const sortedRules = result.results.sort((a, b) => {
        if (a.isApplied === b.isApplied) {
          return 0
        }
        return a.isApplied ? -1 : 1
      })
      if (!_.isEmpty(sortedRules)) {
        setBusinessRuleId(sortedRules[0].id)
      }
    })
  }, [fetchRulesList, periodId, budgetId])

  const verifyPermission = (instance) => {
    const permission = [
      PROJECTION.GLOBAL_BUDGET.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(instance?.concept_id.toString())) {
      return isUserAllowed(
        PLANNING__CONCEPT.AMB[instance?.concept_id][
          !_.isEmpty(instance) && instance?.step.toUpperCase()
        ],
      )
    } else if (extraConcept) {
      return true
    }

    return false
  }

  useEffect(() => {
    if (!_.isEmpty(budgetInstanceDetail)) {
      fetchBudgetInstanceList(budgetInstanceDetail?.concept_id, periodId)
    }
  }, [fetchBudgetInstanceList, budgetInstanceDetail, periodId])

  const isFinished = budgetInstanceDetail?.step !== dataPhase.key
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const canUserABM = !_.isEmpty(budgetInstanceDetail)
    ? verifyPermission(budgetInstanceDetail)
    : true

  const reloadTableNested = () => {
    setIsTableNestedLoading(true)
    setIsKpisLoading(true)
    Promise.all([
      fetchBudgetKpis({ step: dataPhase.key, planning_id: budgetId }),
      fetchDataNestedTableByPhase({
        step: dataPhase.key,
        period_id: periodId,
        concept_id: budgetInstanceDetail?.concept_id,
        planning_id: budgetId,
        page: tablePagination.page,
      }),
    ]).then(() => {
      setIsTableNestedLoading(false)
      setIsKpisLoading(false)
      message.success(t('UPDATE_INFORMATION_MESSAGE'), 8)
    })
  }

  const reloadTablePercentage = () => {
    fetchDataRdnPercentageByPlanning({
      concept_id: budgetInstanceDetail?.concept_id,
      page: tablePagination.page,
      period_id: periodId,
      planning_id: budgetId,
      step: dataPhase.key,
      business_rule_id: businessRuleId,
    })
  }

  // const generalReload = () => {
  //   reloadTableNested()
  // }

  const onClose = () => {
    setIsDeletingAll(false)
    setShowDeleteAll(false)
  }

  const onChangeTypeOfLoad = (e) => {
    setValue(e.target.value)
  }

  const handleClose = () => {
    setShowDrawerTools(false)
    if (applyRule) {
      console.log('applyRule', applyRule)
      reloadTableNested()
    }
    setApplyRule(false)
  }

  const handleApplyRule = (ruleId) => {
    setApplyRule(true)

    return applyBudgetRule(budgetId, ruleId, {
      concept_id: budgetInstanceDetail?.concept_id,
      period_id: periodId,
      step: budgetInstanceDetail?.step,
    })
  }

  const handleUnApplyRule = (ruleId) => {
    setApplyRule(true)

    return unApplyBudgetRule(budgetId, ruleId, {
      concept_id: budgetInstanceDetail?.concept_id,
      period_id: periodId,
      step: budgetInstanceDetail?.step,
    })
  }

  return (
    <>
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <UserActionsPhases
            onConfirmPopConfirm={() => {
              setShowPopConfirmSend(false)
              renderFunctionSend({
                step: budgetInstanceDetail?.step,
                setILoading: setIsDataLoading,
                setNextPhase: () => setNextPhase({ planning_id: budgetId, status_budget }),
                closing: () => closingBudget({ planning_id: budgetId, is_closing: true }),
                onRefetch,
                onClose: () => setIsDataLoading(false),
              })
            }}
            onCancelPopconfirm={() => setShowPopConfirmSend(false)}
            showPopConfirmSend={showPopConfirmSend}
            onClickSend={() => setShowPopConfirmSend(true)}
            buttonName={renderButtonSendName({
              step: budgetInstanceDetail?.step,
              dataPhasekey: dataPhase.key,
              is_closing: budgetInstanceDetail?.is_closing,
              buttonName: dataPhase.buttonName,
            })}
            disabled={isFinished || budgetInstanceDetail?.is_closing || !canUserABM || isDisable}
            dataPhase={dataPhase}
            loading={isDataLoading}
            is_closing={budgetInstanceDetail?.is_closing}
            buttonUploadData={
              <Button.PrimaryGhost
                onClick={() => setShowUploadModal(true)}
                disabled={
                  isFinished ||
                  budgetInstanceDetail?.is_closing ||
                  !canUserABM ||
                  isDataLoading ||
                  isDisable
                }
                title="ACTION_UPLOAD_DATA"
              />
            }
            onClickActivity={() =>
              history.push(
                `/planificacion/${periodId}/${budgetInstanceDetail?.concept_name}/${dataPhase.key}/actividad/${budgetId}?type=planning&module=planningDetail`,
              )
            }
            onClickApply={() => setShowDrawerTools(true)}
            loadingDelete={isDeletingAll}
            visible={showDeleteAll}
            onCancelDeleteAll={() => setShowDeleteAll(false)}
            onConfirmDeleteAll={() =>
              handleDeleteAll({
                setIsLoading: setIsDeletingAll,
                deleteAll: () => deleteAllTransaction(budgetId, dataPhase.key),
                onRefetch: () =>
                  fetchDataNestedTableByPhase({
                    step: dataPhase.key,
                    period_id: periodId,
                    concept_id: budgetInstanceDetail?.concept_id,
                    planning_id: budgetId,
                    page: tablePagination.page,
                  }),
                onClose,
              })
            }
            onClickDeleteAll={() => setShowDeleteAll(true)}
            onClickCreate={() => setShowCreateModal(true)}
            hasCreate={canUserABM}
            extraButtons={{
              module_name: 'planning',
              concept: budgetInstanceDetail?.concept_id,
              submodule_name: budgetId,
            }}
            type="planning"
          />
        </Col>
        {enableKpis && (
          <Col span={24}>
            <PhaseKpis
              kpiLoading={isKpisLoading || isDisable}
              dataKpis={budgetKPIS}
              userDetail={loggedUser}
              setData={setData}
              comments={data}
              onRefetchComments={() => fetchCommentsList({ module: `${key}-${dataPhase.key}` })}
              loading={isLoading}
              phase={dataPhase.key}
            />
          </Col>
        )}
        <Col span={24}>
          <TabsTables
            tabKey={tabKey}
            setTabKey={setTabKey}
            canUserABM={canUserABM}
            isDisable={isDisable}
            budgetInstanceDetail={budgetInstanceDetail}
            onConfirmDeletSelection={() => handleDeleteSelection()}
            isDataLoading={isDataLoading}
            dataPhase={dataPhase}
            dataPeriod={dataPeriod}
            isTableNestedLoading={isTableNestedLoading || isDisable}
            reloadTableNested={reloadTableNested}
            isFinished={isFinished}
            businessRuleId={businessRuleId}
            setBusinessRuleId={setBusinessRuleId}
            reloadTablePercentage={reloadTablePercentage}
            setData={setData}
            comments={data}
            onRefetchComments={() => fetchCommentsList({ module: `${key}-${dataPhase.key}` })}
            loading={isLoading}
          />
        </Col>
      </Row>
      <CreateBudgetModal
        title={t('PLANNING_CREATE_NEW_BUDGET_BASE_MODAL_TITLE')}
        nameButton={t('ACTION_UPLOAD')}
        visible={showUploadModal}
        concept={{ name: budgetInstanceDetail?.concept_name, id: budgetInstanceDetail?.concept_id }}
        onCancel={() => setShowUploadModal(false)}
        actionProjection={updateBudgetInstance}
        actionSpreadsheets={uploadSpreadsheets}
        onRefetchProjections={reloadTableNested}
        dataPeriod={dataPeriod}
        fileName={'PLANNING_TEMPLATE_NAME'}
        projectionInstanceList={budgetInstanceList}
        extraProps={{
          step: budgetInstanceDetail?.step,
          planning_id: budgetId,
        }}
        enabledTypeOfLoad={true}
        setIsDisable={setIsDisable}
        dataProjection={{
          id: budgetId,
          module: 'planning',
        }}
      />
      <ToolsDrawer
        onRefetchDataTable={reloadTableNested}
        visible={showDrawerTools}
        onClose={() => handleClose()}
        globalProjectionId={budgetId}
        type="planning"
        onRefetchRules={onRefetch}
        applyRule={(ruleId) => handleApplyRule(ruleId)}
        unApplyRule={(ruleId) => handleUnApplyRule(ruleId)}
        disabledRule={isFinished || budgetInstanceDetail?.is_closing || !canUserABM}
        typeModule={GENERAL.MODULES_KEYS.MODULE_PLANNING}
        moduleId={budgetId}
        periodId={periodId}
        hasRule={true}
      />
      <CreateTransactionModal
        title={t('ACTION_CREATE_TRANSACTION')}
        dataPeriod={dataPeriod}
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        onRefetch={reloadTableNested}
        onCreateTransaction={(values) =>
          massiveCreatePlanningTransaction({
            ...values,
            period_id: periodId,
            concept_id: budgetInstanceDetail?.concept_id,
            type_sync_data: value,
            planning_id: budgetId,
          })
        }
        onChangeTypeOfLoad={onChangeTypeOfLoad}
        typeOfLoad={value}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  budgetKPIS: planning.selectors.getBudgetKpis(state),
  budgetInstanceList: planning.selectors.getBudgetInstanceList(state),
  periodList: planning.selectors.getPeriodList(state),
  currencyList: configuration.selectors.getCurrencyList(state),
  loggedUser: login.selectors.getWhoAmI(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  setNextPhase: planning.actions.setNextPhase,
  closingBudget: planning.actions.closingBudget,
  fetchBudgetKpis: planning.actions.fetchBudgetKpis,
  fetchBudgetInstanceList: planning.actions.fetchBudgetInstanceList,
  updateBudgetInstance: planning.actions.updateBudgetInstance,
  fetchDataNestedTableByPhase: planning.actions.fetchDataNestedTableByPhase,
  uploadSpreadsheets: home.actions.uploadSpreadsheets,
  applyBudgetRule: planning.actions.applyBudgetRule,
  unApplyBudgetRule: planning.actions.unApplyBudgetRule,
  massiveCreatePlanningTransaction: planning.actions.massiveCreatePlanningTransaction,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,

  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  fetchDataRdnPercentageByPlanning: planning.actions.fetchDataRdnPercentageByPlanning,
  fetchRulesList: configuration.actions.fetchRulesList,
  deleteAllTransaction: planning.actions.deleteTransactionFilterByPlanning,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseBudget)
