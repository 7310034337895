const control = {
  CONTOL_TEMPLATE_FILE_NAME: 'Plika Template - Actuals {{name}} - {{period}} ({{date}})',
  CONTROL_MENSUAL_EVOLUTION_FILE_NAME:
    'Plika - Actuals {{name}} - {{period}} ({{date}}) (Monthly evolution)',

  CONTROL_COMPARISON_FILE_NAME: 'Plika - Actuals {{name}} - {{period}} ({{date}}) (Comparison)',
  CONTROL_DEFAULT_FILE_NAME: 'Plika - Actuals ',

  CONTROL_ORIGINAL_OBS_FILED: 'Remarks',
  CONTROL_NO_OBSERVATIONS: 'No observations',

  CONTROL_EMPTY_SCREEN_DRAG_TITLE: 'Start loading your accounting data',
  CONTROL_CARD_EMPTY_STATE_DESCRIPTION: 'Log in and start uploading your executed {{conceptName}}.', //Here we will present a summary of the most important indicators of monitoring your budget.',
  CONTROL_EMPTY_ECONOMIC_CARD_TITLE: 'Control your expense and sales performance!',
  CONTROL_EMPTY_FINANCIAL_CARD_TITLE: 'Control your collections and payments!',
  CONTROL_EMPTY_FINANCIAL_CARD_DESCRIPTION:
    'Upload the real, compare the budget and analyze the data in order to make the best decisions',
  CONTROL_UPLOAD_MODAL_TITLE: 'Load accounting data',

  CONTROL_CONCEPT_PUBLISH_MODAL_TEXT_SALES_EXPENSES:
    'When you publish your actual data, you can view and use the various tools in Plika. You can still edit the loaded data. To continue, select the range of months you want to publish',
  CONTROL_CONCEPT_ACTION_SUCCESS_FEEDBACK_DESCRIPTION:
    'You just {{action}} the executed sales and expenses in the range {{rangeDate}} :)', //NOTE: COMENTADO HASTA QUE SE PUEDA NOTIFICAR. Do you want to share the news with everyone who participated in this planning?,
  ADJUSTMENT_DISTRIBUTION_MODAL_CANCEL_COMMENT_TEXT:
    'Enter a comment with the reason for the annulment',

  CONTROL_PUBLISHED_DATA_TOOLTIP_TITLE: 'Published data',
  CONTROL_UPLOADED_DATA_TOOLTIP_TITLE: 'Upload data',
  CONTROL_APPROVED_DATA_TOOLTIP_TITLE: 'Approved data',

  CONTROL_MODAL_ACTION_DATA_TITLE: '{{action}} real data',
  CONTROL_PUBLISH_MONTHS_FAIL_FEEDBACK_DESCRIPTION:
    'We could not make the action. Please try again',
  CONTROL_ALL_PUBLISH_DATES_TOOLTIP_TITLE: 'All dates are pubished.',
  CONTROL_LAST_DATE_STATUS_TOOLTIP_TITLE: 'Last month published {{date}}',

  CONTROL_EMPTY_DATA_CARDS_BUDGET_TYPE_BODY_TEXT:
    'Enter to start loading {{title}} and to see a summary of your data',
  CONTROL_PUBLISH_REAL_DATA_BUTTON_INFORMATIVE_TOOLTIP_TITLE:
    'When publishing the data, the status of the real information is modified, up to the month that is selected. This step enables taking the corresponding data to generate a Forecast. It can continue to be modified, but in this case, it must be republished.',
}

export default control
