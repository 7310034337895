import { PROJECTION, ROWS, DIMENSIONS, CONCEPTS, REPORT } from 'modules/core/constants'
import { saveAs } from 'file-saver'
import { message, notification, Tooltip } from 'antd'
import { arrayMove } from '@dnd-kit/sortable'
import i18n from 'i18next'

import moment from 'moment'
import _ from 'lodash'

export const isInElaboration = (status) => {
  return PROJECTION.STATUS.IN_ELABORATION === status
}

export const isPublished = (status) => {
  return PROJECTION.STATUS.PUBLISHED === status
}

export const isApproved = (status) => {
  return PROJECTION.STATUS.APPROVED === status
}

export const isInitiated = (status) => {
  return PROJECTION.STATUS.INITIATED === status
}

export const isApplied = (status) => {
  return PROJECTION.STATUS.APPLIED === status
}

export const isAmount = (key) => REPORT.REPORTS_KEYS.AMOUNT === key

export const calculateScrollX = (columns = [], initScrollX = 100) => {
  let scrollX = initScrollX
  columns.forEach((column) => {
    scrollX += column.width ? column.width : 0
  })
  return scrollX
}

export const orderBySelection = (ids, options) => {
  if (options) {
    const parsedIds = ids && ids.map((id) => parseInt(id))
    const restItems = ids ? options.filter((option) => !parsedIds.includes(option.id)) : options
    const itemsSelection = ids ? options.filter((option) => parsedIds.includes(option.id)) : []
    return [...itemsSelection, ...restItems]
  } else return []
}

export const columnPadding = (value, level, type) => {
  return (
    <span
      style={{
        paddingLeft: level && level * 7,
        ...(type === ROWS.RATIO && {
          fontWeight: 600,
        }),
      }}
    >
      {value}
    </span>
  )
}

export const updateCommentInReducer = ({ state, reducerKey, data }) => {
  const {
    rowId,
    columnId,
    newComment: { id, text, created_on, author } = {},
    commentId = null,
    type,
  } = data

  const updateCreatedComment = (row) => {
    if (row.id === rowId) {
      const updateCommentsThread = [
        ...row[columnId].comments_thread,
        { id, text, created_on, author },
      ]

      return {
        ...row,
        [columnId]: {
          ...row[columnId],
          comments_thread: updateCommentsThread,
        },
      }
    }
    return row
  }

  const updateDeletedComment = (row) => {
    if (row.id === rowId) {
      const updateCommentsThread = row[columnId].comments_thread.filter((el) => el.id !== commentId)

      return {
        ...row,
        [columnId]: {
          ...row[columnId],
          comments_thread: updateCommentsThread,
        },
      }
    }
    return row
  }

  const updateEditComment = (row) => {
    if (row.id === rowId) {
      return {
        ...row,
        [columnId]: {
          ...row[columnId],
          comments_thread: row[columnId].comments_thread.map((comment) => {
            if (comment.id === commentId) {
              return {
                ...comment,
                text,
                created_on,
                author,
              }
            }
            return comment
          }),
        },
      }
    }
    return row
  }

  const updateComment = (row) => {
    if (commentId && type === 'delete') {
      return updateDeletedComment(row)
    }
    if (commentId && type === 'edit') {
      return updateEditComment(row)
    }
    return updateCreatedComment(row)
  }

  return {
    [reducerKey]: {
      ...state[reducerKey],
      rows: state[reducerKey].rows.map((row) => updateComment(row)),
    },
  }
}

export const handleSelectAll = ({
  values = [],
  allValues = [],
  areAllSelected = false,
  onSetValues,
  onSetAreAllSelected = null,
}) => {
  //NOTE: Los true o fase agregados en la funcion onSetValues es por tema de roles. Hacen referencia al campo selected_all que le enviamos a back
  if (values.map((val) => parseInt(val)).includes(DIMENSIONS.ALL_FIELDS_ID)) {
    if (!areAllSelected) {
      onSetValues(allValues, true)
      onSetAreAllSelected && onSetAreAllSelected(true)
    } else {
      onSetValues([], false)
      onSetAreAllSelected && onSetAreAllSelected(false)
    }
  } else {
    onSetValues(values, false)
    onSetAreAllSelected && onSetAreAllSelected(false)
  }
}

export const generateSelectOptions = ({
  options = [],
  includeOptionAll = false,
  selection = null,
  dataTooltipLabel = null,
}) => {
  const auxOptions = _.compact([
    ...[
      includeOptionAll && {
        id: DIMENSIONS.ALL_FIELDS_ID,
        name: i18n.t('FIELD_ALL'),
      },
    ],
    ...options,
  ])
  return auxOptions.map((option) => {
    const label = option.code !== undefined ? `${option.code} - ${option.name}` : option.name
    return {
      label: (
        <Tooltip
          placement="left"
          title={dataTooltipLabel ? i18n.t(dataTooltipLabel[option.id]) : ''}
        >
          {label}
        </Tooltip>
      ),
      value: option.id,
      title: option.description ? option.description : label,
      ...(selection && { disabled: selection.includes(option.id.toString()) }),
    }
  })
}

export const renderTableCellLabel = ({ values = [], allValues = [], isDataJoin = false }) => {
  if (values.length === allValues.length) return i18n.t('FIELD_ALL')
  return isDataJoin ? values.join(', ') : values.length
}

export const executeExportAsImage = ({ chartRef, format, onExport, props, title = '' }) => {
  if (chartRef === null) {
    return
  }

  onExport(chartRef, { ...props, quality: 1 })
    .then((dataUrl) => {
      const fileName = i18n.t('CHART_EXPORT_AS_IMAGE_FILE_NAME', {
        chartTitle: title.toLowerCase(),
        format: format,
        date: moment().format('lll'),
      })
      saveAs(dataUrl, fileName)
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: generateMessageError(error, 'FEEDBACK_EXPORT_CHARTS_AS_IMAGE_ERROR'),
        duration: 0,
      })
    })
}

export const userCanAcces = ({ patchAcces, email }) => {
  if (!patchAcces.includes(email)) return null

  return true
}

export const renderPrefix = (functionalCurrencyData, defaultPrefix = '$') =>
  functionalCurrencyData ? functionalCurrencyData.symbol : defaultPrefix

export const generateSelectConceptOptions = ({ values }) => [
  {
    label: i18n.t('CONFIG_FIELD_ECONOMIC_CONCEPTS'),
    value: CONCEPTS.TYPE.ECONOMIC_KEY,
    children: values
      .filter((concept) => concept.type === CONCEPTS.TYPE.ECONOMIC_KEY)
      .map((concept) => {
        return { label: concept.name, value: concept.id }
      }),
  },
  {
    label: i18n.t('CONFIG_FIELD_FINANCIAL_CONCEPTS'),
    value: CONCEPTS.TYPE.FINANCIAL_KEY,
    children: values
      .filter((concept) => concept.type === CONCEPTS.TYPE.FINANCIAL_KEY)
      .map((concept) => {
        return { label: concept.name, value: concept.id }
      }),
  },
]

export const getStepsOfInstances = (detail, options) =>
  !_.isEmpty(detail) &&
  _.compact(
    detail?.budgets_ids.map((val) => {
      const conceptId = _.keys(val)
      const instanceId = _.values(val)

      return options
        .find((op) => {
          const auxConcept = op.concept_id ? op.concept_id : op.conceptId
          return auxConcept === parseInt(conceptId[0]) && op.id === instanceId[0][0]
        })
        ?.step.toLowerCase()
    }),
  )

export const setPhaseAvailable = (stepsInstance, dataPhaseBudget) => {
  const lastElement = stepsInstance[stepsInstance.length - 1]
  const indexPhase = dataPhaseBudget.findIndex((el) => el.key === lastElement)
  const data = dataPhaseBudget.slice(0, indexPhase + 1)
  return data
}

export const generateIndexPhase = (budgetInstanceDetail, filterDataPhaseBudgetByRole) => {
  if (!_.isEmpty(budgetInstanceDetail)) {
    const index = filterDataPhaseBudgetByRole.findIndex(
      (el) => el.key === budgetInstanceDetail?.step,
    )
    if (index === -1) {
      return filterDataPhaseBudgetByRole.length - 1
    } else {
      return index
    }
  } else {
    return 0
  }
}

export const getBudgetSelected = (budgetsIds, options) =>
  budgetsIds &&
  budgetsIds.map((ids) => {
    const auxKey = parseInt(_.keys(ids)[0])

    const findBudget = options.find((el) => el.conceptId && el.id === ids[auxKey])

    return findBudget
  })

export const handleDragStart = (event, setActiveId) => {
  setActiveId(event.active.id)
}

export const handleDragEnd = ({ event, values, order, setOrder, type }) => {
  const { active, over } = event

  if (active.id !== over?.id) {
    const oldIndex = values.findIndex((el) => el.id === active.id)
    const newIndex = values.findIndex((el) => el.id === over.id)
    const newOrderItems = arrayMove(values.asMutable(), oldIndex, newIndex)
    const ids = newOrderItems.map((el) => el.id)

    setOrder(newOrderItems)
    order({ [type]: ids }).catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
      setOrder(values)
    })
  }
}

export const sendUserDataToGTM = (user, path) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'userData',
    userId: user.pkid,
    userTenant: user.society,
    path,
  })
}

export const generateMessageError = (
  error,
  defaultMessage = 'FEEDBACK_GENERAL_DEFAULT',
  extraText = {},
) => {
  const response = error?.response
  if (response?.status >= 400 && response?.status < 500) {
    return response?.data.message || i18n.t(defaultMessage, extraText)
  } else if (response?.status >= 500) {
    return i18n.t('FEEDBACK_ERROR_500')
  }

  return i18n.t('FEEDBACK_GENERAL_DEFAULT', extraText)
}

let timeDefault = 3000
const limitTime = process.env.LIMIT_RATE || 30000

export const checkedUpload = ({ fetchProcessesList, setIsDisable, dataProjection, onRefetch }) => {
  return fetchProcessesList().then((response) => {
    const auxResponse = response.payload.data.results.filter(
      (el) => el.projection === parseInt(dataProjection.id) && el.module === dataProjection.module,
    )
    setIsDisable(true)
    if (auxResponse[0].status !== 'FINALIZADO' && auxResponse[0].status !== 'ERROR') {
      setTimeout(() => {
        checkedUpload({ fetchProcessesList, setIsDisable, dataProjection, onRefetch })
        if (timeDefault < limitTime) {
          timeDefault = timeDefault * 2
        }
      }, timeDefault)
    }
    if (auxResponse[0].status === 'ERROR') {
      notification.error({
        message: i18n.t('FEEDBACK_DEFAULT_ERROR'),
        description: i18n.t('FEEDBACK_LOAD_DATA_ERROR'),
        duration: 0,
      })
      setIsDisable(false)
    }
    if (auxResponse[0].status === 'FINALIZADO') {
      setIsDisable(false)
      onRefetch()
    }
  })
}
