import * as types from './constants'
import i18n from 'i18next'
import { saveAs } from 'file-saver'
import queryString from 'query-string'
// import { GA } from 'modules/core/utils';

export const fetchControlList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_CONTROL_LIST,
      payload: {
        request: {
          url: `/core/get_kpis_card/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataTable =
  (data = {}, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_TABLE,
      payload: {
        request: {
          url: `/dimensions/data_tables/get_table/`,
          method: 'POST',
          data,
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchOpenRow =
  (rowId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_OPEN_ROW,
      payload: {
        request: {
          url: `/dimensions/data_tables/get_table_detail/${rowId}/`,
          method: 'GET',
          params: { rowId, ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchRealColumns =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_REAL_COLUMNS,
      payload: {
        request: {
          url: `/planning/periods/columns/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchRealRows =
  (periodId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_REAL_ROWS,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/rows/`,
          method: 'GET',
          params: { page_size: 10, page: 1, ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchControlDetailKPIs =
  (periodId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_CONTROL_DETAIL_KPIS,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/kpis/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response.payload.data.kpis
  }

// ++XLS++

export const downloadControlTemplateXLS =
  (filename = i18n.t('CONTOL_TEMPLATE_FILE_NAME'), params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_CONTROL_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/core/export_excel/`,
          method: 'POST',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const uploadCotrolFileXLS =
  (data = {}, params = {}) =>
  async (dispatch) => {
    const formData = new FormData()
    data.forEach((file) => {
      formData.append('file', file)
    })
    Object.entries(params).forEach(function ([field_key, value]) {
      formData.append(field_key, value)
    })

    const response = await dispatch({
      type: types.UPLOAD_CONTROL_FILE_XLS,
      payload: {
        request: {
          url: `/core/upload_excel/`,
          method: 'POST',
          data: formData,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const downloadProjectionXLS =
  (filename = i18n.t('CONTROL_DEFAULT_FILE_NAME'), data = {}, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_PROJECTION_XLS,
      payload: {
        request: {
          url: `/dimensions/data_tables/download/`,
          method: 'POST',
          responseType: 'blob',
          data,
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.csv`)
    return Promise.resolve()
  }

// ++PUBLISH BUTTON ON PROJECTION DETAIL++
export const publishProjection = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.PUBLISH_PROJECTION,
    payload: {
      request: {
        url: `/planning/projections/${id}/publish/`,
        method: 'PATCH',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return Promise.resolve()
}

//Listado meses cargados para publicar
export const fetchMonthsToPublish = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_MONTHS_TO_PUBLISH,
    payload: {
      request: {
        url: `/core/get_to_be_aproved/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

//Listado meses publicados para aprobar
export const fetchMonthsToApprove =
  (periodId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_MONTHS_TO_APPROVE,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/published-months/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//Accion publicar meses
export const publishMonth =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.PUBLISH_MONTHS,
      payload: {
        request: {
          url: `/core/flow_data/public_transaction/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      // GA.event(GA.ACTIONS.PUBLISH_DATA_REAL_ERROR)
      return Promise.reject(response.error)
    }
    // GA.event(GA.ACTIONS.PUBLISH_DATA_REAL);
    return response
  }

export const approveMonth =
  (id, data = {}, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.APPROVE_MONTHS,
      payload: {
        request: {
          url: `/planning/periods/${id}/approve/`,
          method: 'PATCH',
          data,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadComparationXLS =
  (periodId, filename = i18n.t('CONTROL_DEFAULT_FILE_NAME'), params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_COMPARATION_XLS,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/download_projection/`,
          method: 'GET',
          responseType: 'blob',
          params: { ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

//listado meses aprobados
export const fetchApproveDates =
  (periodId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_APPROVE_DATES,
      payload: {
        request: {
          url: `/planning/periods/${periodId}/follow-months/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchLegalData =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_LEGAL_DATA,
      payload: {
        request: {
          url: '/core/transactions/get_legal_transactions/',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject()
    }

    return response
  }

export const deleteRow =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ROW,
      payload: {
        request: {
          url: `/core/transactions/delete_row_real/`,
          method: 'DELETE',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteAll =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ALL,
      payload: {
        request: {
          url: `/core/transactions/delete_all_by_concept/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteFilter =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_FILTER,
      payload: {
        request: {
          url: `/core/transactions/delete_row_by_filter/`,
          method: 'DELETE',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const massiveCreateTransaction =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.MASSIVE_CREATE_TRANSACTION,
      payload: {
        request: {
          url: `/core/transaction/create/massive/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataTableAntv =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_TABLE_ANTV,
      payload: {
        request: {
          url: `/demo/get_data_table/`,
          method: 'GET',
          params,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const createLayout =
  (module, key, layoutName, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_LAYOUT,
      payload: {
        request: {
          url: `/layout/table/${module}/${key}/${layoutName}/`,
          method: 'PUT',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchLayoutList =
  (module, key, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_LAYOUT_LIST,
      payload: {
        request: {
          url: `/layout/table/${module}/${key}/`,
          method: 'GET',
          params,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const deleteLayout = (module, key, layoutName) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_LAYOUT,
    payload: {
      request: {
        url: `/layout/table/${module}/${key}/${layoutName}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const deleteTransactionFilterByControl =
  (periodId, conceptId, filters = {}) =>
  async (dispatch) => {
    let dimension_filter = {}
    Object.entries(filters).forEach(function ([field_key, value]) {
      dimension_filter[field_key] = Object.entries(value).map(([field_key, _]) => field_key)
    })

    const response = await dispatch({
      type: types.DELETE_TRANSACTION_FILTER_BY_CONTROL,
      payload: {
        request: {
          url: `/core/transactions/delete_rows/`,
          method: 'POST',
          data: {
            period_id: periodId,
            concept_id: conceptId,
            dimension_filter: dimension_filter,
          },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }
