import React from 'react'
import { Tabs } from 'antd'
import { REPORT } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { Comparative } from './components'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Evolution } from './components/'
import planning from 'modules/planning'

const TabsReports = ({
  periodList,
  isEvolutionLoading,
  iscomparativeLoading,
  filters,
  selectedDates,
  groupProjection,
  periodTableSettings,
  setPeriodTableSettings,
  selectedProjection,
  setSelectedProjection,
  referenceBase,
  setReferenceBase,
  referenceComparison,
  setReferenceComparison,
  keyComparisonTable,
  setKeyComparisonTable,
  setTablePagination,
  tablePagination,
  onRefetch,
  tabKey,
  setTabKey,
  lineVertical,
  setLineVertical,
  onRefetchComparative,
  onRefetchEvolution,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  currencyData,
}) => {
  let { periodId } = useParams()
  const { t } = useTranslation()
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))

  return (
    <Tabs defaultActiveKey={tabKey} onChange={setTabKey} size="small">
      <Tabs.TabPane tab={t('FIELD_EVOLUTION')} key={REPORT.REPORTS_KEYS.EVOLUTION_KEY}>
        {tabKey === REPORT.REPORTS_KEYS.EVOLUTION_KEY && (
          <Evolution
            loading={isEvolutionLoading}
            selectedFilters={filters}
            selectedDates={selectedDates}
            selectedPeriod={dataPeriod}
            groupProjection={groupProjection}
            periodTableSettings={periodTableSettings}
            setPeriodTableSettings={setPeriodTableSettings}
            selectedProjection={selectedProjection}
            setSelectedProjection={setSelectedProjection}
            setTablePagination={setTablePagination}
            tablePagination={tablePagination}
            onRefetch={onRefetch}
            lineVertical={lineVertical}
            setLineVertical={setLineVertical}
            onRefetchEvolution={onRefetchEvolution}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            commentKey={commentKey}
            currencyData={currencyData}
          />
        )}
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('FIELD_COMPARISONS')} key={REPORT.REPORTS_KEYS.COMPARISON_KEY}>
        {tabKey === REPORT.REPORTS_KEYS.COMPARISON_KEY && (
          <Comparative
            loading={iscomparativeLoading}
            selectedFilters={filters}
            selectedDates={selectedDates}
            selectedPeriod={dataPeriod}
            groupProjection={groupProjection}
            periodTableSettings={periodTableSettings}
            setPeriodTableSettings={setPeriodTableSettings}
            referenceBase={referenceBase}
            setReferenceBase={setReferenceBase}
            referenceComparison={referenceComparison}
            setReferenceComparison={setReferenceComparison}
            keyComparisonTable={keyComparisonTable}
            setKeyComparisonTable={setKeyComparisonTable}
            onRefetchComparative={onRefetchComparative}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            commentKey={commentKey}
            currencyData={currencyData}
          />
        )}
      </Tabs.TabPane>
    </Tabs>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TabsReports)
