import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React from 'react'
import { Space } from 'antd'
import {
  ButtonActionBar,
  ButtonsSwitchDataType,
  Comments,
  DeleteSelectionButton,
  DropdownMenu,
  FavouriteIcon,
  FavouriteSelect,
  References,
  /*TableSettingsModal,*/
  Typography,
} from 'modules/core/components'
import { DownloadOutlined, CommentOutlined } from '@ant-design/icons'
// import { formItemsHelper } from "modules/core/utils";
import { useTranslation } from 'react-i18next'
import { TABLES } from 'modules/core/constants'
import _ from 'lodash'

const TopTablePhasesActions = ({
  // setTableSettings,
  // tableSettings,
  dataDelete,
  disabled,
  onConfirmDelete,
  isDeleting,
  loading,
  onClickDownload,
  onClickDownloadSpreadsheets,
  tableTypeKey = null,
  onChange,
  onClickSaveFav,
  visibleFav,
  onClickSave,
  onClickCancelSaveFav,
  setFavouriteName,
  favouriteName,
  isLoading,
  hasFavourite = false,
  options = [],
  canSave,
  viewSelect = undefined,
  setViewSelect,
  alreadyExist = false,
  layoutLoading,
  setPrimary,
  onClickDeleteFav,
  showComments,
  hasComment,
  setHasComment,
  setViewComment,
  userDetail,
  settings,
  setData,
  comments,
  onRefetchComments,
  loadingComments,
  onCommentCreate,
  removeNewComment,
  type = '',
}) => {
  const enabledDeleteTransaction = useFeatureIsOn('feature-delete-transaction')
  const { t } = useTranslation()

  const dowload = {
    spreadsheets: onClickDownloadSpreadsheets,
    file: onClickDownload,
  }

  return (
    <ButtonActionBar position="space-between">
      <Space direction="horizontal">
        {tableTypeKey && <ButtonsSwitchDataType tableTypeKey={tableTypeKey} onChange={onChange} />}
        <References
          referencesItems={[
            {
              color: '',
              type: '123',
              title: t('LABEL_TYPE_VALUES_REFERENCE', {
                type: settings?.aggregatorName?.toLowerCase() || t('FIELD_DECIMALS').toLowerCase(),
              }),
            },
          ]}
        />
        {enabledDeleteTransaction && !_.isEmpty(dataDelete) && (
          <DeleteSelectionButton
            onConfirm={onConfirmDelete}
            disabled={disabled}
            isDeletingAll={isDeleting}
            type={type}
          />
        )}
      </Space>
      <Space direction="horizontal">
        {canSave && (
          <FavouriteIcon
            visible={visibleFav}
            favouriteName={favouriteName}
            setFavouriteName={setFavouriteName}
            onClickCancelSaveFav={onClickCancelSaveFav}
            onClickSave={onClickSave}
            loading={isLoading}
            alreadyExist={alreadyExist}
            onClickSaveFav={onClickSaveFav}
          />
        )}
        {hasFavourite && (
          <FavouriteSelect
            viewSelect={viewSelect}
            loading={layoutLoading}
            options={options}
            setPrimary={setPrimary}
            setViewSelect={setViewSelect}
            onClickDeleteFav={onClickDeleteFav}
          />
        )}
        {showComments && (
          <Comments
            hasComment={hasComment.table}
            onClick={(visible) => {
              setHasComment({ type: 'table', data: visible })
              !visible && removeNewComment()
              !visible && setViewComment(visible)
            }}
            comments={comments}
            setData={setData}
            loading={loadingComments}
            onRefetchComments={onRefetchComments}
            onCommentCreate={onCommentCreate}
            onCleanHasComment={() => {
              setHasComment({ type: 'table', data: false })
              setViewComment(false)
            }}
          >
            <Typography.Icon
              style={{ cursor: 'pointer' }}
              title={t('LABEL_COMMENTS')}
              icon={CommentOutlined}
            />
          </Comments>
        )}
        <DropdownMenu
          title={t('ACTION_DOWNLOAD_TABLE')}
          placement="bottomRight"
          icon={<Typography.Icon icon={DownloadOutlined} />}
          menu={TABLES.OPTIONS_DOWNLOAD_CSV.map((formatType) => {
            return {
              title: formatType.name,
              key: formatType.id,
              disabled: loading,
              onClick: dowload[formatType.id],
            }
          })}
        />
        {/* <TableSettingsModal
                    onConfirm={setTableSettings}
                    settings={tableSettings}
                    disabled={loading}
                    formItems={formItemsHelper.itemsDataView(
                        tableSettings.negativeValuesType,
                        tableSettings.showNegativeInRed
                    )}
                /> */}
      </Space>
    </ButtonActionBar>
  )
}

export default TopTablePhasesActions
