import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Tabs, Tooltip } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import emptyStateImage from '../../../../../../../../assets/images/compositions/empty-big.svg'
import configuration from '../../../../../../../configuration'
import Button from '../../../../../../../core/components/Button/Button'
import EmptyScreen from '../../../../../../../core/components/EmptyScreen/EmptyScreen'
import PROJECTION from '../../../../../../../core/constants/projections'
import { Nested, Percentage } from '../index'

const TabsTables = (props) => {
  const {
    tabKey,
    setTabKey,
    canUserABM,
    isDisable,
    forecastInstanceDetail,
    onConfirmDeletSelection,
    isDataLoading,
    dataPhase,
    dataPeriod,
    isTableNestedLoading,
    reloadTableNested,
    isFinished,
    businessRuleId,
    setBusinessRuleId,
    ruleList,
    reloadTablePercentage,
    setData,
    comments,
    onRefetchComments,
    loading,
    visibleTotal = true,
  } = props
  const enabled = useFeatureIsOn('feature-rdn-percentage')
  const { t } = useTranslation()
  let history = useHistory()

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('CONFIG_FORMULAS_EMPTY_SCREEN_TITLE')}
      description={t('CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <Button.Primary
          onClick={() => history.push('/configuracion/herramientas/')}
          title="ACTION_GO_TO_CONFIGURE"
        />
      }
    />
  )

  return (
    <Tabs defaultActiveKey={tabKey} onChange={setTabKey} size="small">
      <Tabs.TabPane
        tab={
          <Tooltip title={t('PLANNING_TRANSACTIONAL_TABLE_INFORMATIVE_TOOLTIP_TITLE')}>
            {t('FIELD_TAB_TRANSACTIONAL')}
          </Tooltip>
        }
        key={PROJECTION.PLANNING_KEYS.TRANSACTIONAL_KEY}
      >
        {tabKey === PROJECTION.PLANNING_KEYS.TRANSACTIONAL_KEY && (
          <Nested
            disabledDeleteSelection={!canUserABM || forecastInstanceDetail?.is_closing || isDisable}
            onConfirmDeletSelection={onConfirmDeletSelection}
            loading={isDataLoading}
            dataPhase={dataPhase}
            forecastInstanceDetail={forecastInstanceDetail}
            dataPeriod={dataPeriod}
            tableLoading={isTableNestedLoading}
            reloadTableNested={reloadTableNested}
            canDelete={
              canUserABM && !isFinished && !forecastInstanceDetail?.is_closing && !isDisable
            }
            setData={setData}
            comments={comments}
            onRefetchComments={onRefetchComments}
            loadingComments={loading}
            visibleTotal={visibleTotal}
          />
        )}
      </Tabs.TabPane>
      {enabled && (
        <Tabs.TabPane
          tab={
            <Tooltip title={t('PLANNING_PERCENTAGE_TABLE_INFORMATIVE_TOOLTIP_TITLE')}>
              {t('FIELD_TAB_PERCENTAGE')}
            </Tooltip>
          }
          key={PROJECTION.PLANNING_KEYS.PERCENTAGE_KEY}
        >
          {tabKey === PROJECTION.PLANNING_KEYS.PERCENTAGE_KEY && businessRuleId !== undefined && (
            <Percentage
              loading={isTableNestedLoading}
              dataPhase={dataPhase}
              forecastInstance={forecastInstanceDetail}
              reloadTablePercentage={reloadTablePercentage}
              currentRdnSelect={businessRuleId}
              businessRulesOption={ruleList.values}
              handleRdnChange={setBusinessRuleId}
              setData={setData}
              comments={comments}
              onRefetchComments={onRefetchComments}
              loadingComments={loading}
              canUserABM={canUserABM}
            />
          )}
          {tabKey === PROJECTION.PLANNING_KEYS.PERCENTAGE_KEY &&
            businessRuleId === undefined &&
            renderNoDataScreen()}
        </Tabs.TabPane>
      )}
    </Tabs>
  )
}

const mapStateToProps = (state) => ({
  ruleList: configuration.selectors.getRulesList(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TabsTables)
