import _ from 'lodash'
import React from 'react'
import { CellStyle } from '../../../../modules/core/components'
import {
  flatKey,
  formatter,
  normalizeName,
  PivotData,
  redColorScaleGenerator,
  sortedColHeader,
  usFmt,
  usFmtPct,
} from '../../Utilities'
import TSVExportRenderer from './TSVExportRenderer'

function makeRenderer(opts = {}) {
  class TableRenderer extends React.PureComponent {
    constructor(props) {
      super(props)

      // We need state to record which entries are collapsed and which aren't.
      // This is an object with flat-keys indicating if the corresponding rows
      // should be collapsed.
      this.state = { collapsedRows: {}, collapsedCols: {} }
    }

    hasAccumulative = (arr1) => {
      let subArr1 = _.slice(arr1, 0, 1)
      let subArr2 = ['Proyecciones']
      return _.isEqual(subArr1, subArr2)
    }

    hasMonthly = (arr1) => {
      let subArr1 = _.slice(arr1, 0, 2)
      let subArr2 = ['Fecha', 'Proyecciones']
      return _.isEqual(subArr1, subArr2)
    }

    getBasePivotSettings() {
      const props = this.props
      const colAttrs = props.cols
      const rowAttrs = props.rows

      const tableOptions = Object.assign(
        {
          rowTotals: false,
          colTotals: true,
        },
        props.tableOptions,
      )

      const rowTotals = tableOptions.rowTotals || colAttrs.length === 0
      const colTotals = tableOptions.colTotals || rowAttrs.length === 0

      const subtotalOptions = Object.assign(
        {
          arrowCollapsed: '⨁',
          arrowExpanded: 'ⴱ',
        },
        props.subtotalOptions,
      )

      const colSubtotalDisplay = Object.assign(
        {
          displayOnTop: false,
          enabled: rowTotals,
          hideOnExpand: false,
        },
        subtotalOptions.colSubtotalDisplay,
      )

      const rowSubtotalDisplay = Object.assign(
        {
          displayOnTop: true,
          enabled: colTotals,
          hideOnExpand: false,
        },
        subtotalOptions.rowSubtotalDisplay,
      )

      const pivotData = new PivotData(
        props,
        !opts.subtotals
          ? {}
          : {
              rowEnabled: rowSubtotalDisplay.enabled,
              colEnabled: colSubtotalDisplay.enabled,
              rowPartialOnTop: rowSubtotalDisplay.displayOnTop,
              colPartialOnTop: colSubtotalDisplay.displayOnTop,
            },
      )

      const rowKeys = pivotData.getRowKeys()
      const colKeys = pivotData.getColKeys()
      // Also pre-calculate all the callbacks for cells, etc... This is nice to have to
      // avoid re-calculations of the call-backs on cell expansions, etc...
      const cellCallbacks = {}
      const rowTotalCallbacks = {}
      const colTotalCallbacks = {}
      let grandTotalCallback = null
      // Custom lines
      const linesOrder = !_.isEmpty(tableOptions?.linesOrder) ? tableOptions.linesOrder : []
      const linesTotal = !_.isEmpty(tableOptions?.linesTotal) ? tableOptions.linesTotal : []
      const linesRatio = !_.isEmpty(tableOptions?.linesRatio) ? tableOptions.linesRatio : []
      const linesRatioN = !_.isEmpty(tableOptions?.linesRatioN) ? tableOptions.linesRatioN : []
      const referenceBase = !_.isEmpty(tableOptions?.referenceBase)
        ? tableOptions.referenceBase
        : null
      const referenceBasePosition = this.hasAccumulative(colAttrs)
        ? 0
        : this.hasMonthly(colAttrs)
        ? 1
        : 0

      return Object.assign(
        {
          pivotData,
          colAttrs,
          rowAttrs,
          colKeys,
          rowKeys,
          rowTotals,
          colTotals,
          arrowCollapsed: subtotalOptions.arrowCollapsed,
          arrowExpanded: subtotalOptions.arrowExpanded,
          colSubtotalDisplay,
          rowSubtotalDisplay,
          cellCallbacks,
          rowTotalCallbacks,
          colTotalCallbacks,
          grandTotalCallback,
          linesOrder,
          linesTotal,
          linesRatio,
          linesRatioN,
          referenceBase,
          referenceBasePosition,
        },
        TableRenderer.heatmapMappers(
          pivotData,
          props.tableColorScaleGenerator,
          colTotals,
          rowTotals,
        ),
      )
    }

    clickHandler(pivotData, rowValues, colValues) {
      const colAttrs = this.props.cols
      const rowAttrs = this.props.rows
      const value = pivotData.getAggregator(rowValues, colValues).value()
      const filters = {}
      const colLimit = Math.min(colAttrs.length, colValues.length)
      for (let i = 0; i < colLimit; i++) {
        const attr = colAttrs[i]
        if (colValues[i] !== null) {
          filters[attr] = colValues[i]
        }
      }
      const rowLimit = Math.min(rowAttrs.length, rowValues.length)
      for (let i = 0; i < rowLimit; i++) {
        const attr = rowAttrs[i]
        if (rowValues[i] !== null) {
          filters[attr] = rowValues[i]
        }
      }
      return (e) => this.props.tableOptions.clickCallback(e, value, filters, pivotData)
    }

    collapseAttr(rowOrCol, attrIdx, allKeys) {
      return () => {
        // Collapse an entire attribute.

        const keyLen = attrIdx + 1
        const collapsed = allKeys.filter((k) => k.length === keyLen).map(flatKey)

        const updates = {}
        collapsed.forEach((k) => {
          updates[k] = true
        })

        if (rowOrCol) {
          this.setState((state) => ({
            collapsedRows: Object.assign({}, state.collapsedRows, updates),
          }))
        } else {
          this.setState((state) => ({
            collapsedCols: Object.assign({}, state.collapsedCols, updates),
          }))
        }
      }
    }

    expandAttr(rowOrCol, attrIdx, allKeys) {
      return () => {
        // Expand an entire attribute. This implicitly implies expanding all of the
        // parents as well. It's a bit inefficient but ah well...

        const updates = {}
        allKeys.forEach((k) => {
          for (let i = 0; i <= attrIdx; i++) {
            updates[flatKey(k.slice(0, i + 1))] = false
          }
        })

        if (rowOrCol) {
          this.setState((state) => ({
            collapsedRows: Object.assign({}, state.collapsedRows, updates),
          }))
        } else {
          this.setState((state) => ({
            collapsedCols: Object.assign({}, state.collapsedCols, updates),
          }))
        }
      }
    }

    toggleRowKey(flatRowKey) {
      return () => {
        this.setState((state) => ({
          collapsedRows: Object.assign({}, state.collapsedRows, {
            [flatRowKey]: !state.collapsedRows[flatRowKey],
          }),
        }))
      }
    }

    toggleColKey(flatColKey) {
      return () => {
        this.setState((state) => ({
          collapsedCols: Object.assign({}, state.collapsedCols, {
            [flatColKey]: !state.collapsedCols[flatColKey],
          }),
        }))
      }
    }

    calcAttrSpans(attrArr, numAttrs) {
      // Given an array of attribute values (i.e. each element is another array with
      // the value at every level), compute the spans for every attribute value at
      // every level. The return value is a nested array of the same shape. It has
      // -1's for repeated values and the span number otherwise.

      const spans = []
      // Index of the last new value
      const li = Array(numAttrs).map(() => 0)
      let lv = Array(numAttrs).map(() => null)
      for (let i = 0; i < attrArr.length; i++) {
        // Keep increasing span values as long as the last keys are the same. For
        // the rest, record spans of 1. Update the indices too.
        const cv = attrArr[i]
        const ent = []
        let depth = 0
        const limit = Math.min(lv.length, cv.length)
        while (depth < limit && lv[depth] === cv[depth]) {
          ent.push(-1)
          spans[li[depth]][depth]++
          depth++
        }
        while (depth < cv.length) {
          li[depth] = i
          ent.push(1)
          depth++
        }
        spans.push(ent)
        lv = cv
      }
      return spans
    }

    static heatmapMappers(pivotData, colorScaleGenerator, colTotals, rowTotals) {
      let valueCellColors = () => {}
      let rowTotalColors = () => {}
      let colTotalColors = () => {}
      if (opts.heatmapMode) {
        if (colTotals) {
          const colTotalValues = Object.values(pivotData.colTotals).map((a) => a.value())
          colTotalColors = colorScaleGenerator(colTotalValues)
        }
        if (rowTotals) {
          const rowTotalValues = Object.values(pivotData.rowTotals).map((a) => a.value())
          rowTotalColors = colorScaleGenerator(rowTotalValues)
        }
        if (opts.heatmapMode === 'full') {
          const allValues = []
          Object.values(pivotData.tree).map((cd) =>
            Object.values(cd).map((a) => allValues.push(a.value())),
          )
          const colorScale = colorScaleGenerator(allValues)
          valueCellColors = (r, c, v) => colorScale(v)
        } else if (opts.heatmapMode === 'row') {
          const rowColorScales = {}
          Object.entries(pivotData.tree).map(([rk, cd]) => {
            const rowValues = Object.values(cd).map((a) => a.value())
            rowColorScales[rk] = colorScaleGenerator(rowValues)
          })
          valueCellColors = (r, c, v) => rowColorScales[flatKey(r)](v)
        } else if (opts.heatmapMode === 'col') {
          const colColorScales = {}
          const colValues = {}
          Object.values(pivotData.tree).map((cd) =>
            Object.entries(cd).map(([ck, a]) => {
              if (!(ck in colValues)) {
                colValues[ck] = []
              }
              colValues[ck].push(a.value())
            }),
          )
          for (const k in colValues) {
            colColorScales[k] = colorScaleGenerator(colValues[k])
          }
          valueCellColors = (r, c, v) => colColorScales[flatKey(c)](v)
        }
      }
      return { valueCellColors, rowTotalColors, colTotalColors }
    }

    renderColHeaderRow(attrName, attrIdx, pivotSettings, referenceBase) {
      const {
        rowAttrs,
        colAttrs,
        colKeys,
        visibleColKeys,
        colAttrSpans,
        rowTotals,
        arrowExpanded,
        arrowCollapsed,
        colSubtotalDisplay,
        maxColVisible,
        referenceBasePosition,
      } = pivotSettings

      const needToggle =
        opts.subtotals && colSubtotalDisplay.enabled && attrIdx !== colAttrs.length - 1
      let clickHandle = null
      let subArrow = null
      if (needToggle) {
        clickHandle =
          attrIdx + 1 < maxColVisible
            ? this.collapseAttr(false, attrIdx, colKeys)
            : this.expandAttr(false, attrIdx, colKeys)
        subArrow = (attrIdx + 1 < maxColVisible ? arrowExpanded : arrowCollapsed) + ' '
      }
      const attrNameCell = (
        <th
          key="label"
          className="pvtAxisLabel width-min-250"
          onClick={clickHandle}
          style={{ textAlign: 'right' }}
        >
          {subArrow}
          {attrName}
        </th>
      )

      const attrValueCells = []
      const rowIncrSpan = rowAttrs.length !== 0 ? 1 : 0
      // Iterate through columns. Jump over duplicate values.
      let i = 0
      while (i < visibleColKeys.length) {
        const colKey = visibleColKeys[i]
        const colSpan = attrIdx < colKey.length ? colAttrSpans[i][attrIdx] : 1
        if (attrIdx < colKey.length) {
          const rowSpan = 1 + (attrIdx === colAttrs.length - 1 ? rowIncrSpan : 0)
          const flatColKey = flatKey(colKey.slice(0, attrIdx + 1))
          const onClick = needToggle ? this.toggleColKey(flatColKey) : null

          const hasVar = attrIdx + 1 === colKey.length
          const hasReferenceBase = colKey[referenceBasePosition] === referenceBase
          let currentColSpan = colSpan
          if (!hasReferenceBase && hasVar) {
            currentColSpan = 1
          } else if (hasReferenceBase && !hasVar) {
            currentColSpan = colSpan * 3 - 2
          } else {
            currentColSpan = colSpan
          }

          attrValueCells.push(
            <th
              className={`pvtColLabel ${hasVar && hasReferenceBase ? 'pvtReferenceBase' : ' '}`}
              key={'colKey-' + flatColKey}
              // colSpan={hasVar ? colSpan : !hasReferenceBase ? (colSpan + (2 * colSpan)) : colSpan}
              colSpan={currentColSpan}
              rowSpan={rowSpan}
              onClick={onClick}
            >
              {needToggle
                ? (this.state.collapsedCols[flatColKey] ? arrowCollapsed : arrowExpanded) + ' '
                : null}
              {colKey[attrIdx]}
            </th>,
          )

          if (!hasReferenceBase && hasVar) {
            attrValueCells.push(
              <th
                className="pvtColLabel"
                key={'colKey-' + flatColKey + '-var-$'}
                colSpan={colSpan}
                rowSpan={rowSpan}
              >
                VAR $
              </th>,
            )
            attrValueCells.push(
              <th
                className="pvtColLabel"
                key={'colKey-' + flatColKey + '-var-%'}
                colSpan={colSpan}
                rowSpan={rowSpan}
              >
                VAR %
              </th>,
            )
          }
        } else if (attrIdx === colKey.length) {
          const rowSpan = colAttrs.length - colKey.length + rowIncrSpan
          attrValueCells.push(
            <th
              className="pvtColLabel"
              key={'colKeyBuffer-' + flatKey(colKey)}
              colSpan={colSpan}
              rowSpan={rowSpan}
            >
              Subtotal
            </th>,
          )
        }
        // The next colSpan columns will have the same value anyway...
        i = i + colSpan
      }

      const totalCell =
        attrIdx === 0 && rowTotals ? (
          <th
            key="total"
            className="pvtTotalLabel"
            rowSpan={colAttrs.length + Math.min(rowAttrs.length, 1)}
          >
            Total
          </th>
        ) : null

      const cells = [attrNameCell, ...attrValueCells, totalCell]
      return <tr key={`colAttr-${attrIdx}`}>{cells}</tr>
    }

    renderRowHeaderRow(pivotSettings, referenceBase) {
      // Render just the attribute names of the rows (the actual attribute values
      // will show up in the individual rows).

      const {
        rowAttrs,
        colAttrs,
        rowKeys,
        arrowCollapsed,
        arrowExpanded,
        rowSubtotalDisplay,
        maxRowVisible,
      } = pivotSettings

      const rowLabel = rowAttrs.map((it) => it).join(' / ')
      const i = 0
      const needLabelToggle =
        opts.subtotals && rowSubtotalDisplay.enabled && i !== rowAttrs.length - 1
      let clickHandle = null
      let subArrow = null
      if (needLabelToggle) {
        clickHandle =
          i + 1 < maxRowVisible
            ? this.collapseAttr(true, i, rowKeys)
            : this.expandAttr(true, i, rowKeys)
        subArrow = (i + 1 < maxRowVisible ? arrowExpanded : arrowCollapsed) + ' '
      }

      return (
        <tr key="rowHdr">
          <th className="pvtAxisLabel width-min-250" key={`rowAttr-${i}`} onClick={clickHandle}>
            {subArrow} {rowLabel}
          </th>
        </tr>
      )
    }

    esInfinity(num) {
      return num === Infinity || num === -Infinity
    }

    calculateVariation(valueBase, valueReference, varitaion = 'positive') {
      let percentage_variation = 0
      let absolute_variation = this.normalizeNumber(valueBase, varitaion)

      if (valueReference !== 0.0) {
        absolute_variation =
          this.normalizeNumber(valueBase, varitaion) -
          this.normalizeNumber(valueReference, varitaion)
        if (isNaN(absolute_variation) || this.esInfinity(absolute_variation)) {
          absolute_variation = 0
        }

        percentage_variation =
          this.normalizeNumber(absolute_variation, varitaion) /
          this.normalizeNumber(valueReference, varitaion)
        if (isNaN(percentage_variation) || this.esInfinity(percentage_variation)) {
          percentage_variation = 0
        }
      }
      return [absolute_variation, percentage_variation]
    }

    renderTableRow(rowKey, rowIdx, pivotSettings, referenceBase, line) {
      // Render a single row in the pivot table.

      const {
        rowAttrs,
        rowAttrSpans,
        visibleColKeys,
        pivotData,
        rowTotals,
        valueCellColors,
        rowTotalColors,
        arrowExpanded,
        arrowCollapsed,
        rowTotalCallbacks,
        referenceBasePosition,
      } = pivotSettings

      const formatterFunction = this.getFormatter(pivotData)
      const variation = line?.calculation ?? 'positive'

      const flatRowKey = flatKey(rowKey)
      const attrValueCells = rowKey.map((r, i) => {
        const rowSpan = rowAttrSpans[rowIdx][i]
        if (rowSpan > 0) {
          const flatRowKey = flatKey(rowKey.slice(0, i + 1))
          const needRowToggle = opts.subtotals && i !== rowAttrs.length - 1
          const onClick = needRowToggle ? this.toggleRowKey(flatRowKey) : null

          return (
            <th
              key={`rowKeyLabel-${i}`}
              className={`pvtRowLabel pl-${i} width-min-250`}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
              rowSpan={1}
              onClick={onClick}
            >
              {needRowToggle
                ? (this.state.collapsedRows[flatRowKey] ? arrowCollapsed : arrowExpanded) + ' '
                : null}
              {r}
            </th>
          )
        }
        return null
      })

      let valueCells = []
      visibleColKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        const agg = pivotData.getAggregator(rowKey, colKey)
        const aggValue = agg.value()
        const style = valueCellColors(rowKey, colKey, aggValue)

        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

        valueCells.push(
          <td
            className={`pvtVal ${hasReferenceBase ? 'pvtReferenceBase' : ' '}`}
            key={'pvtVal-' + flatColKey}
            onClick={this.clickHandler(pivotData, rowKey, colKey)}
            style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {agg.format(aggValue)}
          </td>,
        )

        if (!hasReferenceBase) {
          const colKeyReferenceBase = _.cloneDeep(colKey)
          colKeyReferenceBase[referenceBasePosition] = referenceBase
          const aggBase = pivotData.getAggregator(rowKey, colKeyReferenceBase)
          const valueBase = aggBase.value()
          const [absolute_variation, percentage_variation] = this.calculateVariation(
            valueBase,
            aggValue,
            variation,
          )

          valueCells.push(
            <td
              className="pvtVal"
              key={'pvtVal-' + flatColKey + '-var$'}
              style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              {formatterFunction(absolute_variation)}
            </td>,
          )
          valueCells.push(
            <td
              className="pvtVal"
              key={'pvtVal-' + flatColKey + '-var%'}
              style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable
                value={percentage_variation}
                amount={percentage_variation}
              />
            </td>,
          )
        }
      })

      let totalCell = null
      if (rowTotals) {
        const agg = pivotData.getAggregator(rowKey, [])
        const aggValue = agg.value()
        const style = rowTotalColors(aggValue)
        totalCell = (
          <td
            key="total"
            className="pvtTotal"
            onClick={rowTotalCallbacks[flatRowKey]}
            style={{ ...(style ? style : line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {agg.format(aggValue)}
          </td>
        )
      }

      const rowCells = [...attrValueCells, ...valueCells, totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    getFormatter(pivotData) {
      let formatterFunction = usFmt
      if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
        formatterFunction = formatter[pivotData.props.aggregatorName]
      }
      return formatterFunction
    }

    normalizeNumber(value, variation = 'positive') {
      if (!value) {
        return 0
      }
      if (variation === 'negative') {
        value = Math.abs(value)
      }

      const normalizedValue = parseFloat(value).toFixed(6)
      return parseFloat(normalizedValue)
    }

    getAggregator(pivotData, rowKey, colKey) {
      try {
        const agg = pivotData.getAggregator(rowKey, colKey)
        return agg.value()
      } catch (e) {
        return 0
      }
    }

    renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line) {
      const { visibleColKeys, pivotData, rowTotals, referenceBase, referenceBasePosition } =
        pivotSettings
      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)
      const variation = line?.calculation ?? 'positive'

      const lines = lineTotal.lines.reduce((mergedLines, it) => {
        return mergedLines.concat(it.lines)
      }, [])

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            rowSpan={1}
            key={`rowKeyLabel-${i}`}
            className={`pvtRowLabel width-min-250 ${!line?.color ? 'pvtTotalizer' : ''} pl-${i}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })

      const valueCells = []
      visibleColKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        const accumulator = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

        valueCells.push(
          <td
            className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''} ${
              !line?.color && hasReferenceBase ? 'pvtReferenceBase' : ' '
            }`}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>,
        )

        if (!hasReferenceBase) {
          const colKeyReferenceBase = _.cloneDeep(colKey)
          colKeyReferenceBase[referenceBasePosition] = referenceBase
          const accumulatorBase = lines.reduce((acc, it) => {
            const amount = this.getAggregator(pivotData, [normalizeName(it)], colKeyReferenceBase)
            return acc + amount
          }, 0)

          const [absolute_variation, percentage_variation] = this.calculateVariation(
            accumulatorBase,
            accumulator,
            variation,
          )

          valueCells.push(
            <td
              className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
              key={'pvtVal-' + flatColKey + '-var$'}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              {formatterFunction(absolute_variation)}
            </td>,
          )

          valueCells.push(
            <td
              className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
              key={'pvtVal-' + flatColKey + '-var%'}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable
                value={percentage_variation}
                amount={percentage_variation}
              />
            </td>,
          )
        }
      })

      let totalCell = null
      if (rowTotals) {
        const accumulator = lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
          return acc + amount
        }, 0)

        totalCell = (
          <td
            key="total"
            className={`pvtVal ${!line?.color ? 'pvtTotalizer' : ''}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>
        )
      }

      const rowCells = [...attrValueCells, ...valueCells, totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line) {
      const { visibleColKeys, pivotData, rowTotals, referenceBase, referenceBasePosition } =
        pivotSettings
      const flatRowKey = flatKey(rowKey)
      const formatterFunction = this.getFormatter(pivotData)

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            rowSpan={1}
            key={`rowKeyLabel-${i}`}
            className={`pvtRowLabel width-min-250 pl-${i}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })
      const valueCells = []
      visibleColKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        const accumulator = 0

        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

        valueCells.push(
          <td
            className={`pvtVal ${!line?.color && hasReferenceBase ? 'pvtReferenceBase' : ' '}`}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>,
        )

        if (!hasReferenceBase) {
          valueCells.push(
            <td
              className={`pvtVal`}
              key={'pvtVal-' + flatColKey + 'var-$'}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              {formatterFunction(accumulator)}
            </td>,
          )
          valueCells.push(
            <td
              className={`pvtVal`}
              key={'pvtVal-' + flatColKey + 'var-%'}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable value={accumulator} amount={accumulator} />
            </td>,
          )
        }
      })

      let totalCell = null
      if (rowTotals) {
        const accumulator = 0

        totalCell = (
          <td
            key="total"
            className="pvtTotal"
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatterFunction(accumulator)}
          </td>
        )
      }

      const rowCells = [...attrValueCells, ...valueCells, totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, formatter = usFmt, line) {
      const { visibleColKeys, pivotData, rowTotals, referenceBase, referenceBasePosition } =
        pivotSettings
      const flatRowKey = flatKey(rowKey)
      let lines1 = []
      let lines2 = []
      if (!_.isEmpty(lineRatio.lines)) {
        lines1 = lineRatio.lines[0]
        lines2 = lineRatio.lines[1]
      }
      const variation = line?.calculation ?? 'positive'

      const attrValueCells = rowKey.map((r, i) => {
        return (
          <th
            rowSpan={1}
            key={`rowKeyLabel-${i}`}
            className={`pvtRowLabel width-min-250 ${!line?.color ? 'pvtRatio' : ''} pl-${i}`}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {r}
          </th>
        )
      })
      const valueCells = []
      visibleColKeys.map((colKey) => {
        const flatColKey = flatKey(colKey)
        let resultAccumulator = 0
        const accumulator1 = lines1.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)
        const accumulator2 = lines2.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
          return acc + amount
        }, 0)

        if (accumulator2 !== 0.0) {
          resultAccumulator = accumulator1 / accumulator2
        }
        const hasReferenceBase = colKey[referenceBasePosition] === referenceBase

        valueCells.push(
          <td
            className={`pvtVal ${!line?.color ? 'pvtRatio' : ''} ${
              !line.color && hasReferenceBase ? 'pvtReferenceBase' : ' '
            }`}
            key={'pvtVal-' + flatColKey}
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(resultAccumulator)}
          </td>,
        )

        if (!hasReferenceBase) {
          const colKeyReferenceBase = _.cloneDeep(colKey)
          colKeyReferenceBase[referenceBasePosition] = referenceBase

          let resultAccumulatorBase = 0
          const accumulator1Base = lines1.lines.reduce((acc, it) => {
            const amount = this.getAggregator(pivotData, [normalizeName(it)], colKeyReferenceBase)
            return acc + amount
          }, 0)
          const accumulator2Base = lines2.lines.reduce((acc, it) => {
            const amount = this.getAggregator(pivotData, [normalizeName(it)], colKeyReferenceBase)
            return acc + amount
          }, 0)

          if (accumulator2Base !== 0.0) {
            resultAccumulatorBase = accumulator1Base / accumulator2Base
          }

          const [absolute_variation, percentage_variation] = this.calculateVariation(
            resultAccumulatorBase,
            resultAccumulator,
            variation,
          )

          valueCells.push(
            <td
              className="pvtVal pvtRatio"
              key={'pvtVal-' + flatColKey + '-var$'}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              {formatter(absolute_variation)}
            </td>,
          )

          valueCells.push(
            <td
              className="pvtVal pvtRatio"
              key={'pvtVal-' + flatColKey + '-var%'}
              style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
            >
              <CellStyle.VariationTable
                value={percentage_variation}
                amount={percentage_variation}
              />
            </td>,
          )
        }
      })

      let totalCell = null
      if (rowTotals) {
        let result = 0
        const accumulator1 = lines1.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
          return acc + amount
        }, 0)

        const accumulator2 = lines2.lines.reduce((acc, it) => {
          const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
          return acc + amount
        }, 0)

        if (accumulator2 !== 0.0) {
          result = accumulator1 / accumulator2
        }

        totalCell = (
          <td
            key="total"
            className="pvtTotal pvtRatio"
            style={{ ...(line?.color ? { backgroundColor: line?.color } : {}) }}
          >
            {formatter(result)}
          </td>
        )
      }

      const rowCells = [...attrValueCells, ...valueCells, totalCell]

      return <tr key={'keyRow-' + flatRowKey}>{rowCells}</tr>
    }

    renderLinesRow(rowKey, rowIdx, pivotSettings, visibleRowKeys) {
      const { linesOrder, linesTotal, linesRatio, linesRatioN, referenceBase } = pivotSettings
      let isTree = false
      let flatRowKey = flatKey(rowKey)

      if (rowKey.length > 1) {
        flatRowKey = rowKey[0]
        isTree = true
      }

      const line = linesOrder?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineRatio = linesRatio?.find((it) => normalizeName(it.name) === flatRowKey)
      const lineRatioN = linesRatioN?.find((it) => normalizeName(it.name) === flatRowKey)
      const originalRowIdx = visibleRowKeys.findIndex((entry) => flatKey(entry) === flatKey(rowKey))

      if (lineTotal) {
        if (!isTree) {
          return this.renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line)
        }
      } else if (lineRatio) {
        if (!isTree) {
          return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, usFmtPct, line)
        }
      } else if (lineRatioN) {
        if (!isTree) {
          return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatioN, usFmt, line)
        }
      } else if (originalRowIdx < 0) {
        return this.renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line)
      } else {
        return this.renderTableRow(rowKey, originalRowIdx, pivotSettings, referenceBase, line)
      }
    }

    visibleKeys(keys, collapsed, numAttrs, subtotalDisplay) {
      return keys.filter(
        (key) =>
          // Is the key hidden by one of its parents?
          !key.some((k, j) => collapsed[flatKey(key.slice(0, j))]) &&
          // Leaf key.
          (key.length === numAttrs ||
            // Children hidden. Must show total.
            flatKey(key) in collapsed ||
            // Don't hide totals.
            !subtotalDisplay.hideOnExpand),
      )
    }

    render() {
      if (this.cachedProps !== this.props) {
        this.cachedProps = this.props
        this.cachedBasePivotSettings = this.getBasePivotSettings()
      }

      const {
        colAttrs,
        rowAttrs,
        rowKeys,
        colKeys,
        rowSubtotalDisplay,
        colSubtotalDisplay,
        linesOrder,
        referenceBase,
        referenceBasePosition,
      } = this.cachedBasePivotSettings

      // Need to account for exclusions to compute the effective row
      // and column keys.
      const visibleRowKeys = opts.subtotals
        ? this.visibleKeys(rowKeys, this.state.collapsedRows, rowAttrs.length, rowSubtotalDisplay)
        : rowKeys
      const visibleColKeys = opts.subtotals
        ? this.visibleKeys(colKeys, this.state.collapsedCols, colAttrs.length, colSubtotalDisplay)
        : colKeys

      const sortedVisibleColKeys = sortedColHeader(
        referenceBase,
        visibleColKeys,
        referenceBasePosition,
      )

      const pivotSettings = Object.assign(
        {
          visibleRowKeys,
          maxRowVisible: Math.max(...sortedVisibleColKeys.map((k) => k.length)),
          visibleColKeys: sortedVisibleColKeys,
          maxColVisible: Math.max(...sortedVisibleColKeys.map((k) => k.length)),
          rowAttrSpans: this.calcAttrSpans(visibleRowKeys, rowAttrs.length),
          colAttrSpans: this.calcAttrSpans(sortedVisibleColKeys, colAttrs.length),
        },
        this.cachedBasePivotSettings,
      )

      let customVisibleRowKeys = []
      linesOrder.forEach((it) => {
        if (it.type === 'grouper') {
          const listFilter = visibleRowKeys.filter((entry) => it.name === entry[0])
          if (listFilter.length > 0) {
            customVisibleRowKeys.push(...listFilter)
          } else {
            customVisibleRowKeys.push([it.name])
          }
        } else {
          customVisibleRowKeys.push([it.name])
        }
      })

      return (
        <table className="pvtTable">
          <thead>
            {colAttrs.map((it, index) =>
              this.renderColHeaderRow(it, index, pivotSettings, referenceBase),
            )}
            {rowAttrs.length !== 0 && this.renderRowHeaderRow(pivotSettings, referenceBase)}
          </thead>
          <tbody>
            {customVisibleRowKeys.map((r, i) =>
              this.renderLinesRow(r, i, pivotSettings, visibleRowKeys),
            )}
          </tbody>
        </table>
      )
    }
  }

  TableRenderer.defaultProps = PivotData.defaultProps
  TableRenderer.propTypes = PivotData.propTypes
  TableRenderer.defaultProps.tableColorScaleGenerator = redColorScaleGenerator
  TableRenderer.defaultProps.tableOptions = {}
  return TableRenderer
}

export default {
  'Table': makeRenderer({ subtotals: true }),
  'Table Heatmap': makeRenderer({ heatmapMode: 'full', subtotals: true }),
  'Table Col Heatmap': makeRenderer({ heatmapMode: 'col', subtotals: true }),
  'Table Row Heatmap': makeRenderer({ heatmapMode: 'row', subtotals: true }),
  'Exportable TSV': TSVExportRenderer,
}
