import React, { useState, useEffect } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout, Space, Dropdown, Modal, Col, Row, Card } from 'antd'
import { Typography, ButtonActionBar, Button } from 'modules/core/components'
import {
  isUserAllowed,
  getOperationsByKeys,
  getPeriodOperationsKeys,
  // GA,
} from 'modules/core/utils'
import {
  UserOutlined,
  CalendarOutlined,
  SettingOutlined,
  FileSyncOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import { UserAvatar } from 'modules/configuration/components'
import { ROLES } from 'modules/core/constants'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import moment from 'moment'
import logoBlack from 'assets/images/logo/logo-black.svg'
import logoCompleto from 'assets/images/logo/logo.svg'

import './AppLayout.scss'

const { Header } = Layout
const { SETTINGS, PLANNING, CONTROL, FORECAST, ANALYSIS } = ROLES

const AppLayout = ({
  children,
  history,
  onLogout,
  loggedUser,
  showIcons = true,
  notifications = [],
  fetchDimensionsList,
  dimensionsList,
  periodList,
  setShowAllComments,
  showAllComments,
}) => {
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    fetchDimensionsList()
  }, [fetchDimensionsList])

  const exit = () =>
    onLogout().then(() => {
      history.push('/login')
    })

  const pathLogoComplete = ['/']
  const pathname = window.location.pathname

  const headerMenus = !loggedUser.link_upload_file
    ? [
        {
          ...(dimensionsList.count > 0 && periodList.length > 0
            ? {
                title: t('LABEL_PERIODS'),
                url: '/periodos',
                exact: true,
                icon: <CalendarOutlined />,
                allowedFor: getPeriodOperationsKeys(),
              }
            : {}),
        },
        {
          title: t('LABEL_CONFIG'),
          url: '/configuracion',
          exact: false,
          icon: <SettingOutlined />,
          allowedFor: [SETTINGS],
        },
        {
          title: t('LABEL_COMMENTS'),
          url: '',
          icon: <CommentOutlined />,
          allowedFor: [SETTINGS, PLANNING, CONTROL, FORECAST, ANALYSIS],
        },
        {
          title: t('LABEL_PROCESS_UPLOAD'),
          url: '/procesosdecarga',
          exact: false,
          icon: <FileSyncOutlined />,
          allowedFor: [SETTINGS, PLANNING, CONTROL, FORECAST, ANALYSIS],
        },
      ]
    : []

  // useEffect(() => {
  //   GA.pageView();
  // });

  const renderLogoBlack = () => (
    <div className="app-brand-logo" onClick={() => history.push('/')}>
      <img alt="brand-logo" src={logoBlack} />
    </div>
  )

  const renderLogoComplete = () => (
    <div className="app-brand-logo-complete" onClick={() => history.push('/')}>
      <img alt="brand-logo" src={logoCompleto} />
    </div>
  )

  return (
    <Layout className="app-layout">
      <Header className="app-layout-header">
        {pathLogoComplete.includes(pathname)
          ? dimensionsList.count === 0
            ? renderLogoComplete()
            : renderLogoBlack()
          : renderLogoBlack()}
        {showIcons && (
          <Space className="app-layout-actions" align="center" size="small">
            <Dropdown
              trigger={['click']}
              overlay={
                <div style={{ padding: 0 }}>
                  <Card
                    style={{ width: 400 }}
                    bodyStyle={{ height: 80 }}
                    title={<Typography.Body level={2}>{loggedUser.email}</Typography.Body>}
                    headStyle={{ height: 56 }}
                    actions={[
                      <ButtonActionBar position="start">
                        <Button.Link onClick={() => setShowLogoutModal(true)} title="SIGN_OFF" />
                      </ButtonActionBar>,
                    ]}
                    // TODO: DESCOMENTAR CUANDO FUNICONE
                    extra={
                      <Button.Link
                        onClick={() =>
                          history.push(
                            `/configuracion/usuarios/${loggedUser.pkid}/perfil`, //TODO: Reemplazar por ruta de perfil cuando se desarrolle
                          )
                        }
                        title="PROFILE"
                      />
                    }
                  >
                    {/* TODO: Ajustar cuando esten las notificaciones */}
                    {notifications.length > 0 ? (
                      notifications.map((notification) => (
                        <Row gutter={24}>
                          <Col span={2}>
                            <UserAvatar avatarLetter={'PK'} />
                          </Col>
                          <Col span={22}>
                            <Row gutter={24}>
                              <Col span={24}>
                                <Typography.Body level={2}>{notification.message}</Typography.Body>
                              </Col>
                              <Col span={24}>
                                <Typography.Body level={2}>{`${moment(notification.datetime).format(
                                  ' ddd HH:mm A',
                                )}`}</Typography.Body>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))
                    ) : (
                      <Typography.Body level={2}>{t('NO_NOTIFICATIONS_MESSAGE')}</Typography.Body>
                    )}
                  </Card>
                </div>
              }
            >
              <Button.DefaultUser
                className="app-layout-user-section"
                title={loggedUser.firstName + ' ' + loggedUser.lastName} //title={t('SESSION')}
                icon={<UserOutlined />}
              />
            </Dropdown>
            {headerMenus
              .filter((navItem) => isUserAllowed(getOperationsByKeys(navItem.allowedFor)))
              .map((item, index) => {
                const { icon, url, title, exact } = item
                return url ? (
                  <NavLink
                    exact={exact}
                    to={url}
                    className="header-icon"
                    activeClassName="selected"
                    title={title}
                    key={index}
                  >
                    {icon}
                  </NavLink>
                ) : (
                  <Button.Icon
                    title="LABEL_COMMENTS"
                    icon={
                      <Typography.Icon
                        style={{ color: showAllComments && '#0047ba' }}
                        title={title}
                        level={2}
                        icon={CommentOutlined}
                      />
                    }
                    onClick={() => setShowAllComments(!showAllComments)}
                  />
                )
              })}
          </Space>
        )}
      </Header>
      <Layout className="app-layout-body">{children}</Layout>
      <Modal
        visible={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        onOk={exit}
        cancelText={t('ACTION_CANCEL')}
        okText={t('SIGN_OFF')}
        title={t('SIGN_OFF')}
      >
        {t('SIGN_OFF_CONFIRM_MESSAGE')}
      </Modal>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  periodList: planning.selectors.getPeriodList(state),
  showAllComments: configuration.selectors.getShowAllComments(state),
})

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  setShowAllComments: configuration.actions.setShowAllComments,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout))
