const control = {
  CONTOL_TEMPLATE_FILE_NAME: 'Plantilla Plika - Seguimiento {{name}} - {{period}} ({{date}})',
  CONTROL_MENSUAL_EVOLUTION_FILE_NAME:
    'Plika - Seguimiento {{name}} - {{period}} ({{date}}) (evolución mensual)',
  CONTROL_COMPARISON_FILE_NAME:
    'Plika - Seguimiento {{name}} - {{period}} ({{date}}) (Comparación)',
  CONTROL_DEFAULT_FILE_NAME: 'Plika - Seguimiento',

  CONTROL_ORIGINAL_OBS_FILED: 'Observaciones',
  CONTROL_NO_OBSERVATIONS: 'Sin Observaciones',

  CONTROL_EMPTY_SCREEN_DRAG_TITLE: 'Comenzá la carga de tus datos contables',
  CONTROL_CARD_EMPTY_STATE_DESCRIPTION: 'Ingresá y comenzá a cargar tus {{conceptName}}.', //Acá presentamos un resumen de los indicadores más importantes del seguimiento de tu presupuesto.',
  CONTROL_EMPTY_ECONOMIC_CARD_TITLE: '¡Controlá la ejecución de tus gastos y tus ventas!',
  CONTROL_EMPTY_FINANCIAL_CARD_TITLE: '¡Controlá tus cobros y tus pagos!',
  CONTROL_EMPTY_FINANCIAL_CARD_DESCRIPTION:
    'Cargá el real, compará el presupuesto y analizá los datos para poder tomar las mejores desiciones',
  CONTROL_UPLOAD_MODAL_TITLE: 'Cargar datos contables',
  CONTROL_ACCOUNTING_TYPE: 'contables',
  CONTROL_FINANCIAL_TYPE: 'financieros',

  CONTROL_CONCEPT_PUBLISH_MODAL_TEXT_SALES_EXPENSES:
    'Al publicar tus datos reales podras visualizar y utilizar las distintas herramientas de Plika. Aún podras editar los datos cargados. Para continuar, seleccioná el rango de meses que deseas publicar.',
  CONTROL_CONCEPT_ACTION_SUCCESS_FEEDBACK_DESCRIPTION:
    'Acabás de {{action}} las ventas y los gastos ejecutados en el rango {{rangeDate}} :)', //NOTE: COMENTADO HASTA QUE SE PUEDA NOTIFICAR.¿Querés darle la noticia a todos los que participaron de esta planificación?',

  ADJUSTMENT_DISTRIBUTION_MODAL_CANCEL_COMMENT_TEXT:
    'Ingresá un comentario con el motivo de la anulación',

  CONTROL_PUBLISHED_DATA_TOOLTIP_TITLE: 'Datos publicados',
  CONTROL_UPLOADED_DATA_TOOLTIP_TITLE: 'Datos cargados',
  CONTROL_APPROVED_DATA_TOOLTIP_TITLE: 'Datos aprobados',

  CONTROL_MODAL_ACTION_DATA_TITLE: '{{action}} datos reales',
  CONTROL_PUBLISH_MONTHS_FAIL_FEEDBACK_DESCRIPTION:
    'No pudimos realizar la acción. Por favor, volve a intentarlo.',
  CONTROL_ALL_PUBLISH_DATES_TOOLTIP_TITLE: 'Ya se encuentran todas las fechas publicadas',
  CONTROL_LAST_DATE_STATUS_TOOLTIP_TITLE: 'Último mes publicado {{date}}',

  CONTROL_EMPTY_DATA_CARDS_BUDGET_TYPE_BODY_TEXT:
    'Ingresá para comenzar la carga de tus {{title}} y poder ver un resumen de tus datos',
  CONTROL_PUBLISH_REAL_DATA_BUTTON_INFORMATIVE_TOOLTIP_TITLE:
    'Al publicar los datos, se modifica el status de la información real, hasta el mes que se seleccione. Este paso habilita a tomar la data correspondiente para generar un Forecast. La misma se podrá seguir modificando, pero en este caso, se deberá volver a publicar.',
}

export default control
