import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'
import { InputCell, SelectCell, DeleteAndUpdate } from 'modules/core/components'
import { isUserAllowed } from 'modules/core/utils'
import { PROJECTION_ELEMENTS, ROLES } from 'modules/core/constants'
import { STATUS_OPTIONS } from 'modules/core/constants/tables'
import { onDeleteRule, onEditRule, setNameVariableinFormula } from './utils'
import { EditDimensionModal, EditFormulaModal, TableActions } from './components'
import configuration from 'modules/configuration'
import _ from 'lodash'

import './RulesTable.scss'

const { SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM } = ROLES

const ORIGIN_KEY = 'origin_dimensions'
const TARGET_KEY = 'target_dimensions'

const RulesTable = ({
  dataSource,
  onChange,
  tablePagination,
  loading = false,
  count,
  deleteRule,
  onFetchRulesList,
  dataSelection,
  setDataSelection,
  canDelete,
  editRule,
  setDataFormula,
  variablesList,
}) => {
  const [deleteRuleId, setDeleteRuleId] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDimensionModal, setShowDimensionModal] = useState(false)
  const [dataDimensions, setDataDimensions] = useState({
    origin_dimensions: {},
    target_dimensions: {},
  })
  const [dataType, setDataType] = useState(ORIGIN_KEY)
  const { t } = useTranslation()

  const onCellEdit = (name, value) => {
    setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })
  }

  const onClose = () => setEditRow(null)

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsSaving(false)
    setDeleteRuleId(null)
  }

  const onEdit = (id) => {
    const dataBase = dataSource.find((row) => row.id === id)
    const data = {
      ...dataBase,
      ...dataCellEdit,
    }

    return editRule(id, data)
  }

  const columns = [
    {
      title: t('FIELD_NAME'),
      dataIndex: 'name',
      editable: true,
      render: (name, record) => {
        if (record.id === editRow) {
          return <InputCell initialValue={name} onCellUpdate={onCellEdit} name="name" />
        } else {
          return name
        }
      },
    },
    {
      title: t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN'),
      dataIndex: 'origin_dimensions',
      render: (origin, record) => {
        if (editRow && record.id === editRow) {
          return (
            <div
              className="cell-edit"
              role="button"
              onKeyDown={() => {}}
              onClick={() => {
                setShowDimensionModal(true)
                setDataType(ORIGIN_KEY)
                setDataDimensions({ ...dataDimensions, origin_dimensions: origin })
              }}
            >
              {_.keys(origin).length}
            </div>
          )
        } else {
          return _.keys(origin).length
        }
      },
    },
    {
      title: t('LABEL_FORMULA'),
      dataIndex: 'formula',
      render: (formula, record) => {
        const formulaWithoutAmount = formula.includes('amount') ? formula.slice(7) : formula
        const data = setNameVariableinFormula({
          formula: formulaWithoutAmount,
          variables: variablesList,
        })

        if (record.id === editRow) {
          return (
            <div
              className="cell-edit"
              role="button"
              onKeyDown={() => {}}
              onClick={() => {
                setShowEditModal(true)
                setDataFormula({ formula: data.auxFormula, variablesIds: data.varIds })
              }}
            >
              {dataCellEdit.formula ? dataCellEdit.formula : formulaWithoutAmount}
            </div>
          )
        } else {
          return formulaWithoutAmount
        }
      },
    },
    {
      title: t('FIELD_TYPE'),
      dataIndex: 'formula_type',
      render: (formula_type, record) => {
        if (record.id === editRow) {
          return (
            <SelectCell
              initialValue={formula_type}
              style={{ width: 150 }}
              onCellUpdate={onCellEdit}
              name="formula_type"
              options={PROJECTION_ELEMENTS.FORMULA_TYPES_OPTIONS.map((op) => {
                return { ...op, name: t(op.name) }
              })}
            />
          )
        } else {
          return formula_type
        }
      },
    },
    {
      title: t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_DESTINATION'),
      dataIndex: 'target_dimensions',
      render: (destination, record) => {
        if (editRow && record.id === editRow && _.keys(destination).length > 0) {
          return (
            <div
              className="cell-edit"
              role="button"
              onKeyDown={() => {}}
              onClick={() => {
                setShowDimensionModal(true)
                setDataType(TARGET_KEY)
                setDataDimensions({ ...dataDimensions, target_dimensions: destination })
              }}
            >
              {_.keys(destination).length}
            </div>
          )
        } else {
          return _.keys(destination).length
        }
      },
    },
    {
      title: t('FIELD_STATUS'),
      dataIndex: 'status',
      render: (status, record) => {
        if (record.id === editRow) {
          return (
            <SelectCell
              initialValue={status}
              style={{ width: 150 }}
              placeholder={t('ACTION_SELECT')}
              onCellUpdate={onCellEdit}
              options={STATUS_OPTIONS.map((op) => {
                return { ...op, name: t(op.name) }
              })}
              name="status"
            />
          )
        } else {
          return status === 'active' ? t('STATE_TAG_ACTIVE') : t('STATE_TAG_INACTIVE')
        }
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      width: 100,
      render: (id) => {
        return id !== editRow ? (
          <TableActions
            onConfirmPopconfirm={() =>
              onDeleteRule({
                setIsLoading: setIsSaving,
                deleteRule: () => deleteRule(id),
                onRefetch: onFetchRulesList,
                onClose: onConfirm,
              })
            }
            onCancelPopconfirm={() => setDeleteRuleId(null)}
            loading={isSaving}
            visiblePopconfirm={id === deleteRuleId}
            onClickEdit={() => setEditRow(id)}
            onClickDeleted={() => setDeleteRuleId(id)}
            disabled={!isUserAllowed(SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES__ABM)}
          />
        ) : (
          <DeleteAndUpdate
            onCancel={onClose}
            onConfirm={onConfirm}
            onSave={() =>
              onEditRule({
                dataCellEdit,
                setIsLoading: setIsSaving,
                editRule: () => onEdit(id),
                onRefetch: onFetchRulesList,
                onClose: onConfirm,
              })
            }
            dataCellEdit={dataCellEdit}
            loading={isSaving}
          />
        )
      },
    },
  ]

  const renderRowSelection = () => {
    return {
      selectedRowKeys: dataSelection,
      onChange: (value) => setDataSelection(value),
    }
  }

  return (
    <>
      <Table
        rowKey="id"
        size="small"
        bordered
        columns={columns}
        onChange={onChange}
        rowSelection={canDelete ? renderRowSelection() : undefined}
        loading={loading || isSaving}
        dataSource={dataSource}
        pagination={{
          pageSize: tablePagination.page_size,
          current: tablePagination.page,
          total: count,
          pageSizeOptions: ['10', '20', '30'],
          size: 'small',
          showSizeChanger: true,
        }}
      />
      <EditFormulaModal
        visible={showEditModal}
        onCancel={() => setShowEditModal(false)}
        onConfirm={(data) => onCellEdit('formula', data)}
      />
      <EditDimensionModal
        dataKey={dataType}
        visible={showDimensionModal}
        dataDimensions={dataDimensions[dataType]}
        onCancel={() => {
          setShowDimensionModal(false)
          setDataDimensions({ origin_dimensions: {}, target_dimensions: {} })
        }}
        onConfirm={(key, data) => {
          onCellEdit(key, data)
          setShowDimensionModal(false)
          setDataDimensions({ origin_dimensions: {}, target_dimensions: {} })
        }}
      />
    </>
  )
}

const mapDispatchToProps = {
  deleteRule: configuration.actions.deleteRule,
  editRule: configuration.actions.editRule,
  setDataFormula: configuration.actions.setDataFormula,
}

export default connect(null, mapDispatchToProps)(RulesTable)
