import React, { useEffect, useState } from 'react'
import { Tabs, Row, Col, message, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'modules/core/customHooks'
import { SiderLayout } from 'modules/core/layouts'
import { Navigator } from 'modules/core/components'
import { RolesList, UsersList } from './components'
import { getOperationsByKeys, isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import login from 'modules/login'

const { SETTINGS__SECURITY__USERS, SETTINGS__SECURITY__ROLES } = ROLES

const SecurityHome = ({ googleAccounts, loggedUser: { pkid }, whoAmI }) => {
  const [isLoggingOther, setIsLoggingOther] = useState(false)
  const { t } = useTranslation()
  let query = useQuery()
  let history = useHistory()

  var queryString = window.location.search
  var urlParams = new URLSearchParams(queryString)
  var code = urlParams.get('code')
  var error = urlParams.get('error')
  var state = urlParams.get('state')

  useEffect(() => {
    if (code || state || error) {
      const endpointData = {
        ...(code && state
          ? {
              code,
              state,
              error,
              type: 'google',
            }
          : {
              code,
              type: 'azure',
            }),
      }
      setIsLoggingOther(true)
      googleAccounts(endpointData).then(() =>
        whoAmI().then(() => {
          history.push(`/configuracion/usuarios/${pkid}/`)
          setIsLoggingOther(false)
          message.success(t('FEEDBACK_SETTING_SUCCESS'), 8)
        }),
      )
    }
    // eslint-disable-next-line
  }, [googleAccounts, code, error, state])

  const ROLES = 'roles'
  const USERS = 'users'

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        { name: t('LABEL_SECURITY') },
      ]}
    />
  )

  const renderSecurityTabs = () => (
    <Tabs size="small" defaultActiveKey={query.get('tab') !== null ? query.get('tab') : USERS}>
      {isUserAllowed(getOperationsByKeys([SETTINGS__SECURITY__USERS])) && (
        <Tabs.TabPane tab={t('LABEL_USERS')} key={USERS}>
          <UsersList loading={isLoggingOther} />
        </Tabs.TabPane>
      )}
      {isUserAllowed(getOperationsByKeys([SETTINGS__SECURITY__ROLES])) && (
        <Tabs.TabPane
          tab={
            <Tooltip title={t('CONFIG_ROLE_INFORMATIVE_TOOLTIP_TITLE')}>{t('LABEL_ROLES')}</Tooltip>
          }
          key={ROLES}
        >
          <RolesList />
        </Tabs.TabPane>
      )}
    </Tabs>
  )

  return (
    <SiderLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>{renderHeader()}</Col>
        <Col span={24}>{renderSecurityTabs()}</Col>
      </Row>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  googleAccounts: login.actions.googleAccounts,
  whoAmI: login.actions.whoAmI,
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityHome)
