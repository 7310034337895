import React from 'react'
import { Popconfirm } from 'antd'
import { DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { EditOutlined, DeleteOutlined, SolutionOutlined, CopyOutlined } from '@ant-design/icons'

const MoreActions = ({
  title,
  loading,
  onConfirmPopconfirm,
  onCancelPopconfirm,
  visible,
  onClickEdit,
  type,
  onClickDuplicate,
  onClickDelete,
  onOpenActivities,
}) => {
  const { t } = useTranslation()

  return (
    <Popconfirm
      placement="bottomRight"
      title={t('POPCONFIRM_DELETE_DEFAULT_TITLE', { name: title })}
      okText={t('ACTION_DELETE')}
      okButtonProps={{ loading }}
      onConfirm={onConfirmPopconfirm}
      cancelText={t('ACTION_CANCEL')}
      onCancel={onCancelPopconfirm}
      visible={visible}
    >
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_EDIT_NAME'),
            icon: <EditOutlined />,
            onClick: onClickEdit,
          },
          {
            title: t('ACTION_DUPLICATE'),
            icon: <CopyOutlined />,
            onClick: onClickDuplicate,
          },
          {
            title: t('ACTION_DELETE'),
            icon: <DeleteOutlined />,
            onClick: onClickDelete,
          },
          ...(onOpenActivities
            ? [
                {
                  title: t('ACTION_ACTIVITIES'),
                  icon: <SolutionOutlined />,
                  onClick: onOpenActivities,
                },
              ]
            : []),
        ]}
      />
    </Popconfirm>
  )
}

export default MoreActions
