import numeral from 'numeral'

const numberFormat = function (opts_in) {
  const defaults = {
    format: '0,0',
  }
  const opts = Object.assign({}, defaults, opts_in)
  return function (x) {
    if (isNaN(x) || !isFinite(x)) {
      return ''
    }
    return numeral(x).format(opts.format)
  }
}
// TODO: temporary fix for MMT format
const numberFormatMMT = function (opts_in) {
  return function (x) {
    if (isNaN(x) || !isFinite(x)) {
      return ''
    }

    if (opts_in.type === 'miles') return numeral(x / 1000).format('0,0.[0000000]')
    if (opts_in.type === 'millones') return numeral(x / 1000000).format('0,0.[0000000]')

    return x
  }
}

const usFmt = numberFormat({ format: '0,0.00' })
const usFmtInt = numberFormat({ format: '0,0' })
const usFmtMMT = numberFormat({ format: '0[.]0 a' })
const usFmtPct = numberFormat({ format: '0.00 %' })
const usFmtT = numberFormatMMT({ type: 'miles' })
const usFmtMM = numberFormatMMT({ type: 'millones' })

const aggregatorTemplates = {
  sum(formatter = usFmt) {
    return function ([attr]) {
      return function () {
        return {
          sum: 0,
          push(record) {
            if (!isNaN(parseFloat(record[attr]))) {
              this.sum += parseFloat(record[attr])
            }
          },
          value() {
            return this.sum
          },
          format: formatter,
          numInputs: typeof attr !== 'undefined' ? 0 : 1,
        }
      }
    }
  },
  runningStat(mode = 'mean', ddof = 1, formatter = usFmt) {
    return function ([attr]) {
      return function () {
        return {
          n: 0.0,
          m: 0.0,
          s: 0.0,
          push(record) {
            const x = parseFloat(record[attr])
            if (isNaN(x)) {
              return
            }
            this.n += 1.0
            if (this.n === 1.0) {
              this.m = x
            }
            const m_new = this.m + (x - this.m) / this.n
            this.s = this.s + (x - this.m) * (x - m_new)
            this.m = m_new
          },
          value() {
            if (mode === 'mean') {
              if (this.n === 0) {
                return 0 / 0
              }
              return this.m
            }
            if (this.n <= ddof) {
              return 0
            }
            switch (mode) {
              case 'var':
                return this.s / (this.n - ddof)
              case 'stdev':
                return Math.sqrt(this.s / (this.n - ddof))
              default:
                throw new Error('unknown mode for runningStat')
            }
          },
          format: formatter,
          numInputs: typeof attr !== 'undefined' ? 0 : 1,
        }
      }
    }
  },
  multiSum(formatter = usFmt) {
    return function (attrs) {
      return function () {
        let summedFacts = {}
        let i = 0
        let attr = attrs[i]
        while (i < attrs.length) {
          summedFacts[attrs[i]] = 0
          i++
        }
        return {
          push(record) {
            i = 0
            while (i < attrs.length) {
              if (!isNaN(parseFloat(record[attrs[i]]))) {
                summedFacts[attrs[i]] += parseFloat(record[attrs[i]])
              }
              i++
            }
            return summedFacts
          },
          value() {
            return parseFloat(summedFacts[attrs[0]])
          },
          multivalued() {
            return summedFacts
          },
          format: formatter,
          numInputs: attr != null ? 0 : 2,
        }
      }
    }
  },
}

aggregatorTemplates.average = (f) => aggregatorTemplates.runningStat('mean', 1, f)

const aggregatorsTableBase = ((tpl) => ({
  Decimales: tpl.multiSum(usFmt),
  Enteros: tpl.multiSum(usFmtInt),
  Miles: tpl.multiSum(usFmtT),
  Millones: tpl.multiSum(usFmtMM),
}))(aggregatorTemplates)

const aggregatorsTablePercentage = ((tpl) => ({
  Average: tpl.average(usFmt),
}))(aggregatorTemplates)

const aggregatorsTablePxQ = ((tpl) => ({
  Decimales: tpl.multiSum(usFmt),
  Enteros: tpl.multiSum(usFmtInt),
  Miles: tpl.multiSum(usFmtT),
  Millones: tpl.multiSum(usFmtMM),
}))(aggregatorTemplates)

export {
  usFmt,
  usFmtInt,
  usFmtMMT,
  usFmtPct,
  aggregatorTemplates,
  aggregatorsTableBase,
  aggregatorsTablePercentage,
  aggregatorsTablePxQ,
}
