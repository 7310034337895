import React from 'react'
import { Input, Space } from 'antd'
import { Button, ButtonActionBar, CustomBottomAction, DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined } from '@ant-design/icons'
// import { DeadlineModal } from "modules/planning/containers/PlanningList/components";

const { Search } = Input

const UserActions = ({
  onClick,
  disabled,
  tooltipTitle = '',
  periodId,
  concept,
  setSearchValue,
  module_name,
  onClickActivity,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Space size="middle">
        <Search placeholder={t('ACTION_SEARCH')} allowClear={true} onSearch={setSearchValue} />
        <Button.Primary
          onClick={onClick}
          disabled={disabled}
          title="ACTION_CREATE_NEW"
          tooltipTitle={tooltipTitle}
        />
        <CustomBottomAction extraButtons={{ module_name: module_name, concept: concept.id }} />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
          ]}
        />
        {/* <DeadlineModal
                    period={periodId}
                    concept={concept.id}
                /> */}
        {/* <Button
                type="primary"
                ghost
                // onClick={() => setShowCreateModal(true)}
                // disabled={!userCanCreate || areApprovedProjections}
                >
                Ver analiticas
                </Button> */}
      </Space>
    </ButtonActionBar>
  )
}

export default UserActions
