import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React from 'react'
import { Drawer, Tabs, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { ExchangeRateComponent, RuleComponent } from './components'

const EXCHANGE_RATE_KEY = 'exchangeRate'
const RULES_KEY = 'rules'

const ToolsDrawer = ({
  onRefetchDataTable = () => {},
  visible,
  onClose,
  periodId,
  hasRule = false,
  hasCurrency = false,
  globalProjectionId = null,
  type = '',
  onRefetchRules = () => {},
  applyRule = () => {},
  unApplyRule = () => {},
  disabledRule = false,
  typeModule = null,
  moduleId = null,
  isCurrencyLoading = false,
  currencyList = [],
  params = {},
  disabledDate = null,
}) => {
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const { t } = useTranslation()

  return (
    <Drawer visible={visible} title={t('LABEL_TOOLS')} onClose={onClose} size="large">
      <Tabs defaultActiveKey={hasRule ? RULES_KEY : EXCHANGE_RATE_KEY} size="small">
        {hasRule && (
          <Tabs.TabPane
            tab={
              <Tooltip title={t('TAB_RULES_BUTTON_TOOLS_INFORMATIVE_TOOLTIP_TITLE')}>
                {t('LABEL_BUSINESS_RULES')}
              </Tooltip>
            }
            key={RULES_KEY}
          >
            <RuleComponent
              visible={visible}
              globalProjectionId={globalProjectionId}
              type={type}
              onRefetch={onRefetchDataTable}
              applyRule={applyRule}
              unApplyRule={unApplyRule}
              disabled={disabledRule}
              typeModule={typeModule}
              moduleId={moduleId}
            />
          </Tabs.TabPane>
        )}
        {enabled && hasCurrency && (
          <Tabs.TabPane
            tab={
              <Tooltip title={t('TAB_CURRENCY_BUTTON_TOOLS_INFORMATIVE_TOOLTIP_TITLE')}>
                {t('LABEL_EXCHANGE_RATE')}
              </Tooltip>
            }
            key={EXCHANGE_RATE_KEY}
            style={{ width: '100%' }}
          >
            <ExchangeRateComponent
              visible={visible}
              periodId={periodId}
              isCurrencyLoading={isCurrencyLoading}
              currencyList={currencyList}
              onRefetchDataTable={onRefetchDataTable}
              type={type}
              params={params}
              disabledDate={disabledDate}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Drawer>
  )
}

export default ToolsDrawer
