import { Button, ButtonActionBar, DropdownMenu } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { SolutionOutlined } from '@ant-design/icons'

const UserActions = ({ tooltopTitle, onClick, loading, disabled, onClickActivity }) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar>
      <Button.Primary
        onClick={onClick}
        loading={loading}
        disabled={disabled}
        title="ACTION_CREATE_NEW"
        tooltipTitle={tooltopTitle}
      />
      <DropdownMenu
        title={t('ACTION_MORE')}
        menu={[
          {
            title: t('ACTION_ACTIVITIES'),
            icon: <SolutionOutlined />,
            onClick: onClickActivity,
          },
        ]}
      />
    </ButtonActionBar>
  )
}

export default UserActions
