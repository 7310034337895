import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Space, Spin, Tooltip } from 'antd'
import { SiderLayout } from 'modules/core/layouts'
import {
  ActionModal,
  Button,
  DateAndDimensionFilters,
  KPIsGlobalDetail,
  PeriodNavigator,
  ProjectionSelects,
  ScreenWellDone,
  ToolsDrawer,
  UserActionsGlobalDetail,
} from 'modules/core/components'
import { TabsReports } from './components'
import { useTranslation } from 'react-i18next'
import {
  getBudgetSelected,
  getOperationsByKeys,
  getStepsOfInstances,
  isApproved,
  isPublished,
  isUserAllowed,
} from 'modules/core/utils'
import { ROLES, PROJECTION, GENERAL } from 'modules/core/constants'
import { client } from 'httpclient'
import { onSetDefaultBudget } from './util'
import { useDefaultCurrency } from 'modules/core/customHooks'
import imageDefault from 'assets/images/compositions/feedback-image.svg'
import reports from 'modules/reports'
import planning from 'modules/planning'
import configuration from 'modules/configuration'

import './GlobalBudgetDetail.scss'

const { PLANNING__GLOBAL_BUDGET__ABM, SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES } = ROLES

const GlobalBudgetDetail = ({
  globalBudgetDetail,
  fetchBudgetTypeList,
  analysisList,
  fetchAnalysisList,
  approveGlobalBudget,
  setDefaultBudget,
  publishGlobalBudget,
  fetchGlobalBudgetDetail,
  periodList,
  changeBudget,
  fetchDataEvolutionByOverallBudget,
  fetchDataComparativeByOverallBudget,
  applyRule,
  unApplyRule,
  fetchAllDimensions,
  allDimensionsList,
  fetchCurrencyList,
  currencyList,
  fetchCommentsList,
  commentsList,
}) => {
  const enableKpis = useFeatureIsOn('feature-visible-kpi')
  const [isScreenLoading, setIsScreenLoading] = useState(false)
  const [isBudgetLoading, setIsBudgetLoading] = useState(false)
  const [tableId, setTableId] = useState(null)
  const [showActionModal, setShowActionModal] = useState(null)
  const [showScreenSuccess, setShowScreenSuccess] = useState(null)
  const [selectedDates, setSelectedDates] = useState({})
  const [filters, setFilters] = useState({})
  const [options, setOptions] = useState([])
  const [showDrawerRules, setShowDrawerRules] = useState(false)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [defaultPhase, setDefaultPhase] = useState(PROJECTION.BASE_KEY)
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false)
  const [currencyChange, setCurrencyChange] = useState(null)
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [applyRuleB, setApplyRuleB] = useState(false)

  const defaultCurrencyData = useDefaultCurrency()
  let { periodId, globalBudgetId } = useParams()
  let history = useHistory()
  const { t } = useTranslation()

  const auxCurrencyId = currencyChange ? currencyChange : defaultCurrencyData?.id
  const key = window.location.pathname.replaceAll('/', '-')

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: key }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, key])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsScreenLoading(true)
    fetchGlobalBudgetDetail(globalBudgetId).then(() => setIsScreenLoading(false))
    return () => setIsScreenLoading(false)
  }, [fetchGlobalBudgetDetail, globalBudgetId])

  useEffect(() => {
    fetchAnalysisList().then((data) => {
      data.payload.data.length > 0 && setTableId(data.payload.data[0].id)
    })
  }, [fetchAnalysisList])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  //todo: revisar
  useEffect(() => {
    fetchBudgetTypeList().then((response) => {
      response.payload.data.concepts.forEach((concept) => {
        setIsBudgetLoading(true)
        client.get(`/planning/get_all/${concept.id}/${periodId}/`).then((response) => {
          const aux = response.data.results.map((budget) => {
            return { ...budget, conceptId: concept.id }
          })
          setOptions((prevState) => [...prevState, ...aux])
          setIsBudgetLoading(false)
        })
      })
    })
    return () => {
      setIsBudgetLoading(false)
      setOptions([])
    }
  }, [fetchBudgetTypeList, periodId])

  useEffect(() => {
    setIsCurrencyLoading(true)
    fetchCurrencyList().then(() => {
      setIsCurrencyLoading(false)
    })
  }, [fetchCurrencyList])

  const isGlobalBudgetApproved = isApproved(globalBudgetDetail?.status)
  const isGlobalBudgetPublished = isPublished(globalBudgetDetail?.status)
  const isActionApprove = isApproved(showActionModal)
  const userCanAction = isUserAllowed(PLANNING__GLOBAL_BUDGET__ABM)
  const userCanViewAndABMRules = isUserAllowed(
    getOperationsByKeys([SETTINGS__PROJECTION_ELEMENTS__BUSINESS_RULES]),
  )
  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))
  const notBudgetClosed = getBudgetSelected(globalBudgetDetail?.budgets_ids, options)
    ? getBudgetSelected(globalBudgetDetail?.budgets_ids, options).some((el) => !el?.is_closing)
    : true

  const renderHeader = () => (
    <PeriodNavigator
      items={[
        {
          name: t('LABEL_PLANNING'),
          url: `/planificacion/${periodId}?tab=${PROJECTION.GLOBAL_BUDGET}`,
        },
        { name: globalBudgetDetail?.name },
      ]}
    />
  )

  const renderSelectors = () => (
    <ProjectionSelects
      loading={isBudgetLoading}
      idsBudgetsSelected={globalBudgetDetail?.budgets_ids}
      disabled={isGlobalBudgetApproved}
      options={options}
      globalProjectionId={globalBudgetId}
      changeProjection={changeBudget}
      onRefetchGobalBudgetDetail={() => fetchGlobalBudgetDetail(globalBudgetId)}
    />
  )

  const renderUserActions = () => (
    <UserActionsGlobalDetail
      onClickPublish={() => setShowActionModal(PROJECTION.STATUS.PUBLISHED)}
      disabledPublish={isGlobalBudgetPublished || isGlobalBudgetApproved || !userCanAction}
      buttonPublishName={isGlobalBudgetPublished ? t('STATE_TAG_PUBLISHED') : t('ACTION_PUBLISH')}
      tooltipApprove={
        !isGlobalBudgetPublished && !isGlobalBudgetApproved ? t('TOOLTIP_NOT_APPROVE_TITLE') : ''
      }
      onClickApprove={() => setShowActionModal(PROJECTION.STATUS.APPROVED)}
      disableApprove={
        !userCanAction || isGlobalBudgetApproved || !isGlobalBudgetPublished || notBudgetClosed
      }
      buttonApproveName={isGlobalBudgetApproved ? t('STATE_TAG_APPROVED') : t('ACTION_APPROVE')}
      onClickApply={() => setShowDrawerRules(true)}
      disabledApply={!userCanViewAndABMRules || !isGlobalBudgetPublished}
      extraButtons={{
        module_name: 'planning_global',
        submodule_name: globalBudgetId,
      }}
      currencyOptions={currencyList.data}
      onChangeCurrency={(val) => setCurrencyChange(val)}
      defaultCurrency={currencyChange ? currencyChange : defaultCurrencyData?.id}
      onClickActivity={() =>
        history.push(
          `/planificacion/${periodId}/presupuestoglobal/${globalBudgetId}/actividad?type=planning_overall_budget&module=globalBudgetDetail`,
        )
      }
    />
  )

  const renderReportsTables = () => (
    <TabsReports
      tableId={tableId}
      setTableId={setTableId}
      loading={isScreenLoading}
      stepsBudgetInstance={getStepsOfInstances(globalBudgetDetail, options)}
      dataPeriod={dataPeriod}
      globalBudgetName={globalBudgetDetail?.name}
      selectedDates={selectedDates}
      filters={filters}
      tableLoading={isTableLoading}
      allDimensionsList={allDimensionsList}
      defaultPhase={defaultPhase}
      setDefaultPhase={setDefaultPhase}
      auxCurrencyId={auxCurrencyId}
      setDataComments={setDataComments}
      dataComments={dataComments}
      onRefetchComments={() => fetchCommentsList({ module: key })}
      loadingComments={isLoadingComments}
      currencyData={
        currencyChange
          ? currencyList.data.find((el) => el.id === currencyChange)
          : defaultCurrencyData
      }
    />
  )

  const onRefetch = () => {
    setIsTableLoading(true)
    Promise.all([
      fetchDataEvolutionByOverallBudget(tableId, parseInt(globalBudgetId), defaultPhase, {
        ...(auxCurrencyId && { currency_id: auxCurrencyId }),
      }),
      fetchDataComparativeByOverallBudget(tableId, parseInt(globalBudgetId), {
        ...(auxCurrencyId && { currency_id: auxCurrencyId }),
      }),
    ]).then(() => setIsTableLoading(false))
  }

  const handleClose = () => {
    setShowDrawerRules(false)
    if (applyRuleB) {
      console.log('applyRule', applyRuleB)
      onRefetch()
    }
    setApplyRuleB(false)
  }

  const handleApplyRule = (ruleId) => {
    setApplyRuleB(true)

    return applyRule(globalBudgetId, ruleId, { period: periodId })
  }

  const handleUnApplyRule = (ruleId) => {
    setApplyRuleB(true)

    return unApplyRule(globalBudgetId, ruleId, { period: periodId })
  }

  return (
    <SiderLayout className="container-global-budget-detail">
      {renderHeader()}
      <Spin spinning={isScreenLoading} />
      {globalBudgetDetail && !isScreenLoading && (
        <>
          {!showScreenSuccess && (
            <>
              <Row gutter={[8, 24]}>
                <Col span={24}>{renderUserActions()}</Col>
                <Col span={24}>{renderSelectors()}</Col>
                <Col span={24}>
                  {enableKpis && (
                    <KPIsGlobalDetail
                      periodId={periodId}
                      globalBudgetId={globalBudgetId}
                      budget_type="planning"
                      setDataComments={setDataComments}
                      dataComments={dataComments}
                      onRefetchComments={() => fetchCommentsList({ module: key })}
                      loadingComments={isLoadingComments}
                      currencyData={
                        currencyChange
                          ? currencyList.data.find((el) => el.id === currencyChange)
                          : defaultCurrencyData
                      }
                    />
                  )}
                </Col>
                <Col span={24}>{analysisList.count > 0 && renderReportsTables()}</Col>
              </Row>
              <ActionModal
                globalBudgetId={globalBudgetId}
                isActionApprove={isActionApprove}
                visible={showActionModal}
                onAction={isActionApprove ? approveGlobalBudget : publishGlobalBudget}
                onFetchDetail={() => fetchGlobalBudgetDetail(globalBudgetId)}
                onConfirm={() => setShowScreenSuccess(showActionModal)}
                onClose={() => setShowActionModal(null)}
              />
              <ToolsDrawer
                onRefetchDataTable={onRefetch}
                periodId={periodId}
                visible={showDrawerRules}
                onClose={() => handleClose()}
                globalProjectionId={globalBudgetId}
                type="planning_overall_budget"
                applyRule={(ruleId) => handleApplyRule(ruleId)}
                unApplyRule={(ruleId) => handleUnApplyRule(ruleId)}
                typeModule={GENERAL.MODULES_KEYS.MODULE_PLANNING_OVERALL}
                moduleId={globalBudgetId}
                hasRule={true}
                hasCurrency={true}
                isCurrencyLoading={isCurrencyLoading}
                currencyList={currencyList}
                params={{
                  overall_budget_id: globalBudgetId,
                  module: 'OVERALL_PLANNING',
                }}
              />
            </>
          )}
          {showScreenSuccess && (
            <ScreenWellDone
              image={imageDefault}
              title={t('FEEDBACK_WELLDONE')}
              description={t('PLANNING_SCREEN_SUCCESS_FEEDBACK', {
                period: dataPeriod?.name,
                action: isActionApprove
                  ? t('ACTION_APPROVE').toLowerCase()
                  : t('ACTION_PUBLISH').toLowerCase(),
              })}
              footer={
                <Space>
                  <Button.Primary
                    onClick={() => setShowScreenSuccess(null)}
                    title="ACTION_FINISH"
                  />
                  {!isGlobalBudgetApproved && (
                    <Tooltip title={t('PLANNING_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE')}>
                      <Button.Default
                        onClick={() =>
                          onSetDefaultBudget({
                            setDefaultBudget: () => setDefaultBudget(globalBudgetId),
                            setShowScreenSuccess,
                          })
                        }
                        title="ACTION_FIX"
                      />
                    </Tooltip>
                  )}
                </Space>
              }
            />
          )}
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  globalBudgetDetail: planning.selectors.getGlobalBudgetDetail(state),
  analysisList: reports.selectors.getAnalysisList(state),
  periodList: planning.selectors.getPeriodList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  currencyList: configuration.selectors.getCurrencyList(state),
  commentsList: configuration.selectors.getCommentsList(state),
})

const mapDispatchToProps = {
  fetchGlobalBudgetDetail: planning.actions.fetchGlobalBudgetDetail,
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
  fetchAnalysisList: reports.actions.fetchAnalysisList,
  fetchGlobalBudgetDetailKPI: planning.actions.fetchGlobalBudgetDetailKPI,
  approveGlobalBudget: planning.actions.approveGlobalBudget,
  setDefaultBudget: planning.actions.setDefaultBudget,
  publishGlobalBudget: planning.actions.publishGlobalBudget,
  changeBudget: planning.actions.changeBudget,
  fetchGlobalBudgetEvolutionTable: planning.actions.fetchGlobalBudgetEvolutionTable,
  fetchGlobalBudgetComparisonTable: planning.actions.fetchGlobalBudgetComparisonTable,
  unApplyRule: planning.actions.unApplyRule,
  applyRule: planning.actions.applyRule,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  fetchDataEvolutionByOverallBudget: planning.actions.fetchDataEvolutionByOverallBudget,
  fetchDataComparativeByOverallBudget: planning.actions.fetchDataComparativeByOverallBudget,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalBudgetDetail)
