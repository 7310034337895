import { Button, Typography } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
// import { ExceptionButtons } from './components'

const PLIKA_URL = 'https://drive.google.com/drive/folders/1XfPXOzTSb9Lb1fsejaAXZ3kr_3cBfEfE'

const ContentHelp = ({ exception_path }) => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        width: 200,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ textAlign: 'center', marginBottom: 10 }}>
        <Typography.Body level={2}>{t('NEED_HELP')}</Typography.Body>
      </div>
      <Button.PrimaryBlock
        style={{ marginBottom: 10 }}
        title="LABEL_USER_MANUAL"
        onClick={() => window.open(PLIKA_URL, '_blank')}
      />
      {/* {exception_path && <ExceptionButtons exception_path={exception_path} />} */}
    </div>
  )
}

export default ContentHelp
