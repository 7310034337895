import * as types from './constants'
import queryString from 'query-string'
import { saveAs } from 'file-saver'

export const fetchForecasts =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FORECASTS,
      payload: {
        request: {
          url: '/planning/forecast/get_all/',
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchCutDates = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_CUT_DATES,
    payload: {
      request: {
        url: '/planning/get_court_date/',
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const setNextPhase =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SET_NEXT_PHASE,
      payload: {
        request: {
          url: `/planning/forecast/next_step/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchForecastInstanceList =
  (forecastId, conceptId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FORECAST_INSTANCE_LIST,
      payload: {
        request: {
          url: `/planning/forecast/get_kpis/${forecastId}/${conceptId}/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response.payload.data.results
  }

export const fetchForecastInstanceDetail = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_FORECAST_INSTANCE_DETAIL,
    payload: {
      request: {
        url: `/planning/forecast/get/${id}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const createForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_FORECAST,
      payload: {
        request: {
          url: '/planning/forecast/create/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createForecastInstance =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_FORECAST_INSTANCE,
      payload: {
        request: {
          url: `/planning/forecast/create_in/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteForecastInstance = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_FORECAST_INSTANCE,
    payload: {
      request: {
        url: `/planning/forecast/delete_kpis/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

export const editForecastInstance = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_FORECAST_INSTANCE,
    payload: {
      request: {
        url: `/planning/forecast/update_kpis/${id}/`,
        method: 'PUT',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

export const deleteForecast = (forecastId) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_FORECAST,
    payload: {
      request: {
        url: `/planning/forecast/delete/${forecastId}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchForecastProjections =
  (forecastId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FORECAST_PROJECTIONS,
      payload: {
        request: {
          url: `/planning/forecasts/${forecastId}/projections/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editForecastName = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_FORECAST_NAME,
    payload: {
      request: {
        url: `/planning/forecast/update/${id}/`,
        method: 'PUT',
        data: data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchForecastKpis =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FORECAST_KPIS,
      payload: {
        request: {
          url: `/planning/forecast/kpis_step/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataNestedTableForecastByPhase =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_NESTED_TABLE_FORECAST_BY_PHASE,
      payload: {
        request: {
          url: `/planning/forecast/get_data_step_table/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchExpandNestedTableRow =
  (rowId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_EXPAND_NESTED_TABLE_ROW,
      payload: {
        request: {
          url: `/planning/forecast/get_data_step_table_detail/${rowId}/`,
          method: 'GET',
          params: { rowId, ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadForecastTableNestedXLS =
  (filename, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_FORECAST_TABLE_NESTED_XLS,
      payload: {
        request: {
          url: `/planning/forecast/download_file/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.csv`)
    return Promise.resolve()
  }

export const fetchGlobalForecastPublishApprove = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_GLOBAL_FORECAST_PUBLISH_APPROVE,
    payload: {
      request: {
        url: `/planning/forecast/overall_budget/get_public_or_aproved/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchGlobalForecastFilterList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_FORECAST_FILTER_LIST,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/list/`,
          method: 'GET',
          params: params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createGlobalForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_GLOBAL_FORECAST,
      payload: {
        request: {
          url: '/planning/forecast/overall_budget/create/',
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editGlobalForecastName = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_GLOBAL_FORECAST_NAME,
    payload: {
      request: {
        url: `/planning/forecast/overall_budget/update/${id}/`,
        method: 'PUT',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const deleteGlobalForecast = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_GLOBAL_FORECAST,
    payload: {
      request: {
        url: `/planning/forecast/overall_budget/delete/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchGlobalForecastEvolutionTable =
  (reportId, budgetId, step, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_FORECAST_EVOLUTION_TABLE,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_evolutionary/${reportId}/${budgetId}/${step}/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchForecastEvolutionTableOpenRow =
  (reportId, globalBudgetId, step, rowId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FORECAST_EVOLUTION_TABLE_OPEN_ROW,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_evolutionary_detail/${reportId}/${globalBudgetId}/${step}/${rowId}/`,
          method: 'GET',
          params: { rowId, ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadGlobalForecastEvolutiontXLS =
  (filename, reportId, budgetId, step, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_GLOBAL_FORECAST_EVOLUTION_XLS,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_evolutionary_download/${reportId}/${budgetId}/${step}/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const fetchGlobalForecastComparisonTable =
  (reportId, globalForecastId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_FORECAST_COMPARISON_TABLE,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_comparative/${reportId}/${globalForecastId}/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchForecastComparisonTableOpenRow =
  (reportId, budgetId, rowId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FORECAST_COMPARISON_TABLE_OPEN_ROW,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_comparative_detail/${reportId}/${budgetId}/${rowId}/`,
          method: 'GET',
          params: { rowId, ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadGlobalForecastComparisontXLS =
  (filename, reportId, budgetId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_GLOBAL_FORECAST_COMPARISON_XLS,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_comparative_download/${reportId}/${budgetId}/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const fetchGlobalForecastList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_FORECAST_LIST,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_all/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadForecastTableByPhaseXLS =
  (filename, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DOWNLOAD_FORECAST_TABLE_BY_PHASE_XLS,
      payload: {
        request: {
          url: `/planning/forecast/download_file/`,
          method: 'GET',
          responseType: 'blob',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const closingForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CLOSING_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/closing/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const publishGlobalForecast = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.PUBLISH_GLOBAL_FORECAST,
    payload: {
      request: {
        url: `/planning/forecast/overall_budget/plublished/${id}/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const approveGlobalForecast = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.APPROVE_GLOBAL_FORECAST,
    payload: {
      request: {
        url: `/planning/forecast/overall_budget/approved/${id}/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    //GA.event(GA.ACTIONS.APPROVE_DATA_BUDGET_ERROR);
    return Promise.reject(response.error)
  }
  // GA.event({ ...GA.ACTIONS.APPROVE_DATA_BUDGET, label: id });
  return Promise.resolve()
}

export const setDefaultForecast = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.SET_DEFAULT_FORECAST,
    payload: {
      request: {
        url: `/planning/forecast/overall_budget/predetermined/${id}/`,
        method: 'POST',
        params: { id },
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const updateTransactionForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/data_step/row_edit/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateTransactionNestedForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_NESTED_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/update_data_step_table_cell/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchGlobalForecastChart =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_GLOBAL_FORECAST_CHART,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_data_graphic/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const syncForecastComment =
  (data = {}) =>
  (dispatch) => {
    const { type } = data
    const aux = {
      create: types.SYNC_CREATE_FORECAST_COMMENT,
      edit: types.SYNC_EDIT_FORECAST_COMMENT,
      delete: types.SYNC_DELETE_FORECAST_COMMENT,
    }

    // Reviso que venga la prop newComment, sino asumo que esta borrando comentario.
    return dispatch({
      type: aux[type],
      payload: { data },
    })
  }

export const createTransactionCommentForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_TRANSACTION_COMMENT_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/comment/create/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editTransactionCommentForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_TRANSACTION_COMMENT_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/comment/update/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const deleteTransactionCommentForecast = (commentId) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_TRANSACTION_COMMENT_FORECAST,
    payload: {
      request: {
        url: `/planning/forecast/comment/delete/${commentId}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }

  return response
}

export const fetchGlobalForecastDetail = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_GLOBAL_FORECAST_DETAIL,
    payload: {
      request: {
        url: `/planning/forecast/overall_budget/get/${id}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const updateForecastInstance =
  (data = {}) =>
  async (dispatch) => {
    const { file_upload, ...restProps } = data
    const formData = new FormData()
    if (file_upload) {
      file_upload.forEach((file) => {
        formData.append('file_upload', file)
      })
      formData.append('concept', restProps.concept)
      formData.append('type_budget', restProps.type_budget)
      formData.append('forecast_id', restProps.forecast_id)
      formData.append('forecast_kpis_id', restProps.forecast_kpis_id)
      formData.append('step', restProps.step)
    }
    const response = await dispatch({
      type: types.UPDATE_FORECAST_INSTANCE,
      payload: {
        request: {
          url: `/planning/forecast/add_data_step/`,
          method: 'POST',
          data: file_upload ? formData : { ...restProps },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const changeProjectionForecast =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CHANGE_PROJECTION_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/update_detail/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const unApplyRuleForecast =
  (budgetId, ruleId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UNAPPLY_RULE_FORECAST,
      payload: {
        request: {
          url: `/planning/business_rules/forecast_overall_budget/unapply/${ruleId}/${budgetId}/`,
          method: 'POST',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const applyRuleForecast =
  (budgetId, ruleId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.APPLY_RULE_FORECAST,
      payload: {
        request: {
          url: `/planning/business_rules/forecast_overall_budget/apply/${ruleId}/${budgetId}/`,
          method: 'POST',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const applyRuleinstanceForecast =
  (budgetId, ruleId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.APPLY_RULE_INSTANCE_FORECAST,
      payload: {
        request: {
          url: `/planning/business_rules/forecast/apply/${ruleId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const unApplyRuleInstanceForecast =
  (budgetId, ruleId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UNAPPLY_RULE_INSTANCE_FORECAST,
      payload: {
        request: {
          url: `/planning/business_rules/forecast/unapply/${ruleId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const deleteRow =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ROW,
      payload: {
        request: {
          url: `/core/transactions/forecast/delete_row_forecast/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteAll =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ALL,
      payload: {
        request: {
          url: `/core/transactions/forecast/delete_all_by_concept/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteFilter =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_FILTER,
      payload: {
        request: {
          url: `/core/transactions/forecast/delete_row_by_filter/`,
          method: 'DELETE',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const uploadFileByPhaseForecast =
  (fileList, data = {}) =>
  async (dispatch) => {
    const formData = new FormData()
    fileList.forEach((file) => {
      formData.append('file_upload', file)
    })

    Object.entries(data).forEach(function ([field_key, value]) {
      formData.append(field_key, value)
    })

    const response = await dispatch({
      type: types.UPLOAD_FILE_BY_PHASE_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/update_in/`,
          method: 'PUT',
          data: formData,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//++ DUPLICAR FORECAST ++
export const duplicateForecast = (forecastId) => async (dispatch) => {
  const response = await dispatch({
    type: types.DUPLICATE_FORECAST,
    payload: {
      request: {
        url: `/planning/duplicate_forecast/${forecastId}/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

export const orderForecastCard =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ORDER_FORECAST_CARD,
      payload: {
        request: {
          url: `/planning/order_forecasts/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const setForecastOrder = (data) => (dispatch) =>
  dispatch({
    type: types.SET_FORECAST_ORDER,
    payload: data,
  })

export const massiveCreateForecastTransaction =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.MASSIVE_CREATE_FORECAST_TRANSACTION,
      payload: {
        request: {
          url: `/planning/forecast/create/massive/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const fetchDateBlockingByForecast =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATE_BLOCKING_BY_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/get_dates_blocking/`,
          method: 'GET',
          params,
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const fetchDataEvolutionByOverallForecast =
  (reportId, budgetId, step, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_EVOLUTION_BY_OVERALL_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_evolutionary_all_table/${reportId}/${budgetId}/${step}/`,
          method: 'GET',
          params: { ...params },
          // paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataComparativeByOverallForecast =
  (reportId, budgetId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_COMPARATIVE_BY_OVERALL_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/get_comparative_all_table/${reportId}/${budgetId}/`,
          method: 'GET',
          params: { ...params },
          // paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDataRdnPercentageByForecast =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DATA_RDN_PERCENTAGE_BY_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/get_percentage_step_table/`,
          method: 'GET',
          params: { ...params },
          paramsSerializer: (params) => queryString.stringify(params),
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateTransactionPercentageByForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_TRANSACTION_PERCENTAGE_BY_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/update_data_step_table_cell_percentage/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const applyRdnPercentageByForecast =
  (businessRuleId, budgetId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.APPLY_RDN_PERCENTAGE_BY_FORECAST,
      payload: {
        request: {
          url: `/planning/business_rules/forecast/percentage/apply/${businessRuleId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const unApplyRdnPercentageByForecast =
  (businessRuleId, budgetId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UN_APPLY_RDN_PERCENTAGE_BY_FORECAST,
      payload: {
        request: {
          url: `/planning/business_rules/forecast/percentage/unapply/${businessRuleId}/${budgetId}/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteTransactionFilterByForecast =
  (forecastId, step, filters = {}) =>
  async (dispatch) => {
    let dimension_filter = {}
    Object.entries(filters).forEach(function ([field_key, value]) {
      dimension_filter[field_key] = Object.entries(value).map(([field_key, _]) => field_key)
    })

    const response = await dispatch({
      type: types.DELETE_TRANSACTION_FILTER_BY_FORECAST,
      payload: {
        request: {
          url: `/core/transactions/forecast/delete_rows/`,
          method: 'POST',
          data: {
            forecast_kpis_id: forecastId,
            step: step,
            dimension_filter: dimension_filter,
          },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const duplicateGlobalForecast =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DUPLICATE_GLOBAL_FORECAST,
      payload: {
        request: {
          url: `/planning/forecast/overall_budget/create_copy/`,
          method: 'POST',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }
