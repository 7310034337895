import React from 'react'
import numeral from 'numeral'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { CELLS, ROWS } from 'modules/core/constants'
import _ from 'lodash'

const AUX_TYPES = {
  [ROWS.RATIO]: CELLS.TYPES.FONT_WIDTH_RATIO,
  [ROWS.FINANCIAL]: CELLS.TYPES.FONT_WIDTH_FINANCIAL,
}

const Number = ({
  value,
  format = '0,0',
  showNegativeInRed = false,
  type = '',
  hasVarsOrRule = null,
}) => {
  const numberClassName = (value, showNegativeInRed, hasVarsOrRule) => {
    if (value < 0 && showNegativeInRed) return CELLS.TYPES.RED
    if (_.keys(AUX_TYPES).includes(type)) {
      return AUX_TYPES[type]
    }
    if (hasVarsOrRule && hasVarsOrRule.hasRule) return ROWS.TYPES.APPLIED_RULE_ROW
    if (hasVarsOrRule && hasVarsOrRule.hasVars) return ROWS.TYPES.APPLIED_VAR_ROW
    return ''
  }

  if (value && value !== null && value !== '-' && value !== undefined) {
    if (format === '%') {
      return (
        <span className={numberClassName(value, showNegativeInRed)}>
          {numeral(value).format('0.00%')}
        </span>
      )
    }
    return (
      <span className={numberClassName(value, showNegativeInRed, hasVarsOrRule)}>
        {numeral(value).format(format)}
      </span>
    )
  }
  if (value === undefined) return ''
  return value // note: revisar si esta bien para todas las tablas este cambio. antes tenia '-'
}

const VariationTable = ({ value, amount = null, format = '0,0' }) => {
  const variationClassName = (value, amount) => {
    if (value >= 0) return Math.sign(amount) === 1 ? CELLS.TYPES.GREEN : CELLS.TYPES.RED
    if (value < 0) return Math.sign(amount) === -1 ? CELLS.TYPES.RED : CELLS.TYPES.GREEN
    return ''
  }

  const renderVariationIcon = (value, amount) => {
    if (value > 0) return <CaretUpOutlined />
    if (value < 0) return <CaretDownOutlined />
    return ''
  }

  return (
    <div className={variationClassName(value, amount)}>
      <Number value={value} format="%" />
      {renderVariationIcon(value, amount)}
    </div>
  )
}

const VariationKPI = ({ value }) => {
  const variationClassNameKPI = (value) => {
    if (value <= 0) return CELLS.TYPES.RED
    if (value > 0 && value < 0.5) return CELLS.TYPES.YELLOW
    if (value >= 0.5) return CELLS.TYPES.GREEN_KPI
  }

  return (
    <div className={variationClassNameKPI(value)}>
      <Number value={value} format="%" />
    </div>
  )
}

const CellStyle = {
  Number,
  VariationTable,
  VariationKPI,
}

export default CellStyle
