import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Spin, Row, Col } from 'antd'
import { Button, EmptyScreen, ProjectionCardList, UserActions } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { handleDragEnd, handleDragStart, isInElaboration, isUserAllowed } from 'modules/core/utils'
import { ROLES, CONCEPTS, PROJECTION } from 'modules/core/constants'
import { CreateForecastInstanceModal } from './components'
import {
  DndContext,
  useSensor,
  useSensors,
  closestCenter,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import forecast from 'modules/forecast'
import planning from 'modules/planning'
import configuration from 'modules/configuration'

const {
  FORECAST__SALES__BASE__ABM,
  FORECAST__SALES__TOP_DOWN__ABM,
  FORECAST__SALES__BOTTOM_UP__ABM,
  FORECAST__SALES__CLOSING__ABM,
  FORECAST__SALES__BASE__VIEW,
  FORECAST__SALES__TOP_DOWN__VIEW,
  FORECAST__SALES__BOTTOM_UP__VIEW,
  FORECAST__SALES__CLOSING__VIEW,
  FORECAST__EXPENSES__BASE__ABM,
  FORECAST__EXPENSES__TOP_DOWN__ABM,
  FORECAST__EXPENSES__BOTTOM_UP__ABM,
  FORECAST__EXPENSES__CLOSING__ABM,
  FORECAST__EXPENSES__BASE__VIEW,
  FORECAST__EXPENSES__TOP_DOWN__VIEW,
  FORECAST__EXPENSES__BOTTOM_UP__VIEW,
  FORECAST__EXPENSES__CLOSING__VIEW,
  FORECAST__COSTS__BASE__ABM,
  FORECAST__COSTS__TOP_DOWN__ABM,
  FORECAST__COSTS__BOTTOM_UP__ABM,
  FORECAST__COSTS__CLOSING__ABM,
  FORECAST__COSTS__BASE__VIEW,
  FORECAST__COSTS__TOP_DOWN__VIEW,
  FORECAST__COSTS__BOTTOM_UP__VIEW,
  FORECAST__COSTS__CLOSING__VIEW,
  FORECAST__HUMAN_RESOURCES__BASE__ABM,
  FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
  FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
  FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
  FORECAST__HUMAN_RESOURCES__BASE__VIEW,
  FORECAST__HUMAN_RESOURCES__TOP_DOWN__VIEW,
  FORECAST__HUMAN_RESOURCES__BOTTOM_UP__VIEW,
  FORECAST__HUMAN_RESOURCES__CLOSING__VIEW,
  FORECAST__OTHER_5__BASE__ABM,
  FORECAST__OTHER_5__TOP_DOWN__ABM,
  FORECAST__OTHER_5__BOTTOM_UP__ABM,
  FORECAST__OTHER_5__CLOSING__ABM,
  FORECAST__OTHER_5__BASE__VIEW,
  FORECAST__OTHER_5__TOP_DOWN__VIEW,
  FORECAST__OTHER_5__BOTTOM_UP__VIEW,
  FORECAST__OTHER_5__CLOSING__VIEW,
  FORECAST__OTHER_6__BASE__ABM,
  FORECAST__OTHER_6__TOP_DOWN__ABM,
  FORECAST__OTHER_6__BOTTOM_UP__ABM,
  FORECAST__OTHER_6__CLOSING__ABM,
  FORECAST__OTHER_6__BASE__VIEW,
  FORECAST__OTHER_6__TOP_DOWN__VIEW,
  FORECAST__OTHER_6__BOTTOM_UP__VIEW,
  FORECAST__OTHER_6__CLOSING__VIEW,
} = ROLES

// NOTE: En los roles, los conceptos deberian ser resueltos mediante restricciones de dimensiones y no de operaciones
const FORECAST__CONCEPT = {
  [CONCEPTS.IDS.SALES_ID]: {
    VIEW: [
      FORECAST__SALES__BASE__VIEW,
      FORECAST__SALES__TOP_DOWN__VIEW,
      FORECAST__SALES__BOTTOM_UP__VIEW,
      FORECAST__SALES__CLOSING__VIEW,
    ],
    ABM: [
      FORECAST__SALES__BASE__ABM,
      FORECAST__SALES__TOP_DOWN__ABM,
      FORECAST__SALES__BOTTOM_UP__ABM,
      FORECAST__SALES__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.EXPENSES_ID]: {
    VIEW: [
      FORECAST__EXPENSES__BASE__VIEW,
      FORECAST__EXPENSES__TOP_DOWN__VIEW,
      FORECAST__EXPENSES__BOTTOM_UP__VIEW,
      FORECAST__EXPENSES__CLOSING__VIEW,
    ],
    ABM: [
      FORECAST__EXPENSES__BASE__ABM,
      FORECAST__EXPENSES__TOP_DOWN__ABM,
      FORECAST__EXPENSES__BOTTOM_UP__ABM,
      FORECAST__EXPENSES__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.COSTS_ID]: {
    VIEW: [
      FORECAST__COSTS__BASE__VIEW,
      FORECAST__COSTS__TOP_DOWN__VIEW,
      FORECAST__COSTS__BOTTOM_UP__VIEW,
      FORECAST__COSTS__CLOSING__VIEW,
    ],
    ABM: [
      FORECAST__COSTS__BASE__ABM,
      FORECAST__COSTS__TOP_DOWN__ABM,
      FORECAST__COSTS__BOTTOM_UP__ABM,
      FORECAST__COSTS__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.HUMAN_RESOURCES_ID]: {
    VIEW: [
      FORECAST__HUMAN_RESOURCES__BASE__VIEW,
      FORECAST__HUMAN_RESOURCES__TOP_DOWN__VIEW,
      FORECAST__HUMAN_RESOURCES__BOTTOM_UP__VIEW,
      FORECAST__HUMAN_RESOURCES__CLOSING__VIEW,
    ],
    ABM: [
      FORECAST__HUMAN_RESOURCES__BASE__ABM,
      FORECAST__HUMAN_RESOURCES__TOP_DOWN__ABM,
      FORECAST__HUMAN_RESOURCES__BOTTOM_UP__ABM,
      FORECAST__HUMAN_RESOURCES__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.OTHER_5_ID]: {
    VIEW: [
      FORECAST__OTHER_5__BASE__VIEW,
      FORECAST__OTHER_5__TOP_DOWN__VIEW,
      FORECAST__OTHER_5__BOTTOM_UP__VIEW,
      FORECAST__OTHER_5__CLOSING__VIEW,
    ],
    ABM: [
      FORECAST__OTHER_5__BASE__ABM,
      FORECAST__OTHER_5__TOP_DOWN__ABM,
      FORECAST__OTHER_5__BOTTOM_UP__ABM,
      FORECAST__OTHER_5__CLOSING__ABM,
    ],
  },
  [CONCEPTS.IDS.OTHER_6_ID]: {
    VIEW: [
      FORECAST__OTHER_6__BASE__VIEW,
      FORECAST__OTHER_6__TOP_DOWN__VIEW,
      FORECAST__OTHER_6__BOTTOM_UP__VIEW,
      FORECAST__OTHER_6__CLOSING__VIEW,
    ],
    ABM: [
      FORECAST__OTHER_6__BASE__ABM,
      FORECAST__OTHER_6__TOP_DOWN__ABM,
      FORECAST__OTHER_6__BOTTOM_UP__ABM,
      FORECAST__OTHER_6__CLOSING__ABM,
    ],
  },
}

const ForecastList = ({
  periodId,
  concept,
  fetchForecastInstanceList,
  forecastInstanceList,
  deleteForecastInstance,
  editForecastInstance,
  fetchGlobalBudgetList,
  globalBudgetList,
  fetchGlobalForecastPublishApprove,
  globalForecastPublishApprove,
  duplicateForecast,
  orderForecastCard,
  setForecastOrder,
  allDimensionsList,
  fetchAllDimensions,
  changeCardColor,
  fetchCommentsList,
  commentsList,
  periodList,
}) => {
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [dimensionId, setDimensionId] = useState(null)
  const [dataComments, setDataComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  const { t } = useTranslation()
  let { forecastId } = useParams()
  let history = useHistory()

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `${auxKey}?tab=${concept.name}` : auxKey

  useEffect(() => {
    setIsLoadingComments(true)
    fetchCommentsList({ module: key }).then(() => setIsLoadingComments(false))
  }, [fetchCommentsList, key, concept.name])

  useEffect(() => {
    setDataComments(commentsList)
  }, [commentsList])

  useEffect(() => {
    setIsLoading(true)
    fetchForecastInstanceList(forecastId, concept.id, {
      ...(dimensionId && { dimension_id: dimensionId }),
    }).then(() => {
      setIsScreenLoading(false)
      setIsLoading(false)
    })
  }, [fetchForecastInstanceList, forecastId, concept.id, dimensionId])

  useEffect(() => {
    Promise.all([
      fetchGlobalForecastPublishApprove(),
      fetchGlobalBudgetList({ period_id: periodId }),
    ])
  }, [fetchGlobalBudgetList, fetchGlobalForecastPublishApprove, periodId])

  useEffect(() => {
    fetchAllDimensions()
  }, [fetchAllDimensions])

  const verifyPermission = (concept) => {
    const permission = [
      PROJECTION.GLOBAL_FORECAST.toString(),
      CONCEPTS.IDS.SALES_ID.toString(),
      CONCEPTS.IDS.EXPENSES_ID.toString(),
      CONCEPTS.IDS.COSTS_ID.toString(),
      CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
      CONCEPTS.IDS.OTHER_5_ID.toString(),
      CONCEPTS.IDS.OTHER_6_ID.toString(),
    ]
    if (permission.includes(concept.id.toString())) {
      return isUserAllowed(FORECAST__CONCEPT[concept.id].ABM)
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const userCanCreate = verifyPermission(concept)
  const publishedApproveGlobalBudget = globalBudgetList?.values.filter(
    (global) => !isInElaboration(global.status),
  )
  const disabledButtonCreate =
    !userCanCreate ||
    [...globalForecastPublishApprove, ...publishedApproveGlobalBudget].length === 0

  const dataPeriod = periodList.find((el) => el.id === parseInt(periodId))

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('PLANNING_EMPTY_SCREEN_TITLE')}
      description={t('PLANNING_EMPTY_SCREEN_BODY')}
      footer={
        <Button.Primary
          onClick={() => setShowCreateModal(true)}
          disabled={disabledButtonCreate}
          title="PLANNING_BUDGET_CREATE_ACTION"
          tooltipTitle={
            disabledButtonCreate
              ? t(
                  'FORECAST_NEED_GLOBAL_BUDGET_FORECAST_PUBLISHED_APPROVED_INFORMATIVE_TOOLTIP_TITLE',
                )
              : ''
          }
        />
      }
    />
  )

  const renderTopActions = () => (
    <UserActions
      onClick={() => setShowCreateModal(true)}
      disabled={disabledButtonCreate}
      tooltipTitle={
        disabledButtonCreate
          ? t('FORECAST_NEED_GLOBAL_BUDGET_FORECAST_PUBLISHED_APPROVED_INFORMATIVE_TOOLTIP_TITLE')
          : ''
      }
      periodId={periodId}
      concept={concept}
      setSearchValue={setSearchValue}
      module_name="forecast"
      onClickActivity={() =>
        history.push(`/forecast/${periodId}/${forecastId}/actividad?type=forecast&module=forecast`)
      }
    />
  )

  const renderDataSource = () => {
    if (searchValue) {
      return forecastInstanceList.values
        .asMutable()
        .filter((el) => el.name.toLowerCase().includes(searchValue.toLowerCase()))
    } else {
      return forecastInstanceList.values.asMutable()
    }
  }

  const renderList = () => (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={(event) => handleDragStart(event, setActiveId)}
      onDragEnd={(event) =>
        handleDragEnd({
          event,
          values: forecastInstanceList.values,
          order: orderForecastCard,
          setOrder: setForecastOrder,
          type: 'forecast_ids',
        })
      }
    >
      <ProjectionCardList
        dataSource={renderDataSource()}
        type={concept.name}
        conceptId={concept.id}
        role={FORECAST__CONCEPT}
        onOpen={(projectionId) =>
          history.push(`/forecast/${periodId}/${forecastId}/${concept.name}/${projectionId}`)
        }
        onOpenActivities={(projectionId, step) =>
          history.push(
            `/forecast/${periodId}/${forecastId}/${concept.name}/${step}/actividad/${projectionId}?type=${PROJECTION.FORECAST}&module=forecastDetail`,
          )
        }
        reloadList={() =>
          fetchForecastInstanceList(forecastId, concept.id, {
            ...(dimensionId && { dimension_id: dimensionId }),
          })
        }
        deleteProjection={deleteForecastInstance}
        editProjectionName={editForecastInstance}
        duplicateCard={duplicateForecast}
        activeId={activeId}
        loading={isLoading}
        allDimensionsList={allDimensionsList}
        setDimensionId={setDimensionId}
        changeCardColor={changeCardColor}
        module="forecast"
        conceptName={concept.name}
        setDataComments={setDataComments}
        dataComments={dataComments}
        onRefetchComments={() => fetchCommentsList({ module: key })}
        loadingComments={isLoadingComments}
      />
    </DndContext>
  )

  return (
    <Spin spinning={isScreenLoading} size="large">
      <Row gutter={[24, 24]}>
        {!isScreenLoading && forecastInstanceList.count <= 0 && (
          <Col span={24}>{renderNoDataScreen()}</Col>
        )}
        {forecastInstanceList.count > 0 && (
          <>
            <Col span={24}>{renderTopActions()}</Col>
            <Col span={24}>{renderList()}</Col>
          </>
        )}
      </Row>
      <CreateForecastInstanceModal
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        optionsSelect={[...globalForecastPublishApprove, ...publishedApproveGlobalBudget]}
        onRefetch={() => fetchForecastInstanceList(forecastId, concept.id)}
        conceptId={concept.id}
        dataPeriod={dataPeriod}
      />
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  forecastInstanceList: forecast.selectors.getForecastInstanceList(state),
  forecasts: forecast.selectors.getForecasts(state),
  globalForecastPublishApprove: forecast.selectors.getGlobalForecastPublishApprove(state),
  globalBudgetList: planning.selectors.getGlobalBudgetList(state),
  allDimensionsList: configuration.selectors.getAllDimensionsList(state),
  commentsList: configuration.selectors.getCommentsList(state),
  periodList: planning.selectors.getPeriodList(state),
})

const mapDispatchToProps = {
  fetchForecastInstanceList: forecast.actions.fetchForecastInstanceList,
  deleteForecastInstance: forecast.actions.deleteForecastInstance,
  editForecastInstance: forecast.actions.editForecastInstance,
  fetchForecasts: forecast.actions.fetchForecasts,
  fetchGlobalForecastPublishApprove: forecast.actions.fetchGlobalForecastPublishApprove,
  fetchGlobalBudgetList: planning.actions.fetchGlobalBudgetList,
  duplicateForecast: forecast.actions.duplicateForecast,
  orderForecastCard: forecast.actions.orderForecastCard,
  setForecastOrder: forecast.actions.setForecastOrder,
  fetchAllDimensions: configuration.actions.fetchAllDimensions,
  changeCardColor: planning.actions.changeCardColor,
  fetchCommentsList: configuration.actions.fetchCommentsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForecastList)
