import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Select } from 'antd'
import { AddAndDeleteFormItem } from 'modules/core/components'
import { generateSelectOptions } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'

const FormList = ({
  form,
  isLoading,
  selectedBudgets,
  setSelectedBudgets,
  conceptId,
  setConceptId,
  fetchBudgetTypeList,
  budgetTypeList,
  fetchProjectionInstanceList,
  projectionInstanceList,
  type,
}) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    fetchBudgetTypeList()
  }, [fetchBudgetTypeList])

  useEffect(() => {
    if (conceptId) {
      setLoading(true)
      fetchProjectionInstanceList(conceptId).then(() => setLoading(false))
    }
    // eslint-disable-next-line
  }, [conceptId])

  return (
    <Form.List name="overall_budget">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => {
              return (
                <Row gutter={24} key={field.key} justify="end">
                  <Col span={12}>
                    <Form.Item
                      name={[field.name, 'concept_id']}
                      label={[field.label, t('LABEL_BUDGET_TYPE')]}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                    >
                      <Select
                        placeholder={t('ACTION_SELECT')}
                        onChange={(id) => {
                          setConceptId(id)
                          setSelectedBudgets([...selectedBudgets, id.toString()])
                          // Se limpia el campo de dimensionValue
                          const fields = form.getFieldsValue()
                          const { overall_budget } = fields
                          Object.assign(overall_budget[field.name], {
                            [`${type}_id`]: undefined,
                          })
                          form.setFieldsValue({ overall_budget })
                        }}
                        options={generateSelectOptions({
                          options: budgetTypeList?.values,
                          selection: selectedBudgets,
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[field.name, `${type}_id`]}
                      label={[field.label, t('LABEL_BUDGET')]}
                      rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                    >
                      <Select
                        placeholder={t('ACTION_SELECT')}
                        showArrow
                        allowClear
                        showSearch
                        loading={loading}
                        mode="multiple"
                        maxTagCount={1}
                        maxTagTextLength={8}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={
                          !conceptId ||
                          (form.getFieldValue('overall_budget')[field.name] &&
                            conceptId !==
                              form.getFieldValue('overall_budget')[field.name]['concept_id'])
                        }
                        options={generateSelectOptions({
                          options: projectionInstanceList?.values,
                          //.filter(budget=> budget.is_closing)
                        })}
                      />
                    </Form.Item>
                  </Col>
                  <AddAndDeleteFormItem
                    fieldsLength={fields.length}
                    index={index}
                    addData={{
                      disabled: isLoading,
                      onClick: () => {
                        add()
                        setConceptId(null)
                      },
                      buttonName: 'PLANNING_GLOBAL_BUDGET_ADD_NEW_COMBINATION_ACTION',
                    }}
                    deleteData={{
                      disabled: isLoading,
                      onClick: () => {
                        const selectedID =
                          form.getFieldValue('overall_budget')[field.name] &&
                          form.getFieldValue('overall_budget')[field.name]['concept_id']
                        if (selectedID)
                          setSelectedBudgets(
                            selectedBudgets.filter((id) => id !== selectedID.toString()),
                          )
                        remove(field.name)
                      },
                      buttonName: 'PLANNING_GLOBAL_BUDGET_DELETE_COMBINATION_ACTION',
                    }}
                  />
                </Row>
              )
            })}
          </div>
        )
      }}
    </Form.List>
  )
}

const mapStateToProps = (state) => ({
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
})

const mapDispatchToProps = {
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormList)
