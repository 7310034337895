import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Spin, List, Row, Col } from 'antd'
import { Typography } from 'modules/core/components'
import { isUserAllowed, getOperationsByKeys, sendUserDataToGTM } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import { SiderLayout } from 'modules/core/layouts'
import configuration from 'modules/configuration'
import login from 'modules/login'
import reports from 'modules/reports'

import './Configuration.scss'
import { ConfigurationCard } from './components'
import { listDataConfigModules } from './listDataConfigModules'

const { SETTINGS__BUDGET_TYPE, SETTINGS__DIMENSIONS, SETTINGS__REPORTS } = ROLES

const Configuration = ({
  dimensionsList,
  budgetTypeList,
  fetchDimensionsList,
  fetchBudgetTypeList,
  fetchReportList,
  reportList,
  modifiersList,
  loggedUser,
}) => {
  const enabled = useFeatureIsOn('feature-moneda-seguimiento')
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    setIsScreenLoading(true)
    const isFetcheable = (rolesArray) => isUserAllowed(getOperationsByKeys(rolesArray))

    Promise.all([
      isFetcheable([SETTINGS__BUDGET_TYPE]) && fetchBudgetTypeList(),
      isFetcheable([SETTINGS__REPORTS]) && fetchReportList(),
      isFetcheable([SETTINGS__DIMENSIONS]) && fetchDimensionsList(),
    ])
      .then(() => setIsScreenLoading(false))
      .catch(() => setIsScreenLoading(false))

    return () => setIsScreenLoading(false)
  }, [fetchDimensionsList, fetchBudgetTypeList, fetchReportList])

  useEffect(() => {
    sendUserDataToGTM(loggedUser, '/configuracion')
  }, [loggedUser])

  let modifiersListValue = modifiersList
  if (!enabled) {
    modifiersListValue = {
      ...modifiersList,
      values: [{ name: 'LABEL_BUSINESS_RULES', key: 'rules' }],
    }
  }

  const params = {
    dimensionsList,
    budgetTypeList,
    reportList,
    modifiersList: modifiersListValue,
    history,
  }

  const renderHeader = () => (
    <Typography.Headline level={5}>{t('LABEL_CONFIG')}</Typography.Headline>
  )

  const renderList = () => (
    <List
      grid={{ gutter: 24, column: 6 }}
      dataSource={listDataConfigModules(params).filter((el) =>
        isUserAllowed(getOperationsByKeys(el.allowedFor)),
      )}
      renderItem={(item) => (
        <List.Item>
          <ConfigurationCard
            title={item.title}
            onClick={item.url ? () => history.push(item.url) : null}
            total={item.count}
            renderListItem={item.renderListItem}
            disabled={item.disabled}
            itemKey={item.key}
            tooltipText={item.tooltipText}
          />
        </List.Item>
      )}
    />
  )

  return (
    <SiderLayout>
      <div className="configuration-container">
        <Row gutter={[24, 24]}>
          <Col span={24}>{renderHeader()}</Col>
          <Col span={24}>
            <Spin spinning={isScreenLoading} />
            {!isScreenLoading && renderList()}
          </Col>
        </Row>
      </div>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
  reportList: reports.selectors.getReportList(state),
  modifiersList: configuration.selectors.getModifiersList(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
  fetchReportList: reports.actions.fetchReportList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Configuration)
