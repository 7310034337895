const planning = {
  //Files names
  PLANNING_TEMPLATE_NAME_DEFAULT: 'Plantilla Plika - Planificación',
  PLANNING_TEMPLATE_NAME: 'Plantilla Plika - Planificación {{name}} - {{periodName}} ({{date}})',
  PLANNING_GLOBAL_BUDGET_EVOLUTION_TABLE_FILE_NAME: `Plika - Planificación {{name}}({{reportName}}) - {{periodName}} ({{date}}) (Presupuesto global - Evolución mensual)`,
  PLANNING_GLOBAL_BUDGET_COMPARISON_TABLE_FILE_NAME: `Plika - Planificación {{name}}({{reportName}}) - {{periodName}} ({{date}}) (Presupuesto global - Comparaciones)`,
  PLANNING_EVOLTION_FILE_NAME: 'Plika - Planificación (evolucion mensual)',
  PLANNING_TEMPLATE_UPLOAD_VARIABLES_VALUES:
    'Plantilla Plika - Carga de valores de variables {{periodName}} ({{date}})',
  PLANNING_COMPARISON_FILE_NAME: 'Plika - Planificación (Comparaciones)',
  PLANNING_PHASE_TABLE_FILE_NAME:
    'Plika - Planificacion {{name}} etapa {{phase}} ({{conceptName}}) - {{periodName}} ({{date}})',

  PLANNING_DISABLED_CREATION_TOOLTIP:
    'No podés crear nuevos presupuestos porque este período ya tiene presupuestos aprobados',

  //Text modal create budget
  PLANNING_CREATE_FORM_BUDGET_TITLE: 'Crear presupuesto nuevo',
  PLANNING_CREATE_BUDGET_SUCCESS_FEEDBACK: 'El presupuesto se creó correctamente.',
  PLANNING_CREATE_BUDGET_ERROR_FEEDBACK:
    'No pudimos crear el presupuesto. Por favor, volvé a intentarlo.',
  PLANNING_CREATE_FORM_BUDGET_PLACEHOLDER: 'Ej:{{name}} nuevo',

  PLANNING_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL: 'Base por la cual crear tu presupuesto',
  PLANNING_CREATE_NEW_BUDGET_BASE_DATES_FORM_LABEL: 'Rango de meses',
  PLANNING_CREATE_NEW_BUDGET_BASE_OPTION_FORM_SELECT: 'Base cero',
  PLANNING_CREATE_NEW_BUDGET_BASE_MODAL_TITLE: 'Cargar nuevo presupuesto base',
  PLANNING_CREATE_NEW_BUSGET_BASE_CHOOSE_TYPE_BASE_FORM_LABEL: 'Tipo de base',
  PLANNING_CREATE_NEW_BUSGET_BASE_DATA_FILTER_FORM_LABEL: 'Filtro de datos',
  PLANNING_CREATE_NEW_BUDGET_AVERAGE_OPTION_FORM_SELECT: 'Base histórica promedio',
  PLANNING_CREATE_NEW_BUDGET_PREVIUS_PERIOD_OPTION_FORM_SELECT: 'Base histórica',
  PLANNING_CREATE_NEW_BUDGET_UPLOAD_FILE_OPTION_FORM_SELECT: 'Base por carga de archivo',
  PLANNING_CREATE_NEW_BUDGET_ARIMA_ALGORITHM_OPTION_FORM_SELECT: 'Base por algoritmo Arima',
  PLANNING_CREATE_NEW_BUDGET_REGRESION_LINEAL_ALGORITHM_OPTION_FORM_SELECT:
    'Base por algoritmo Regresión lineal',
  PLANNING_CREATE_NEW_BUDGET_SARIMA_ALGORITHM_OPTION_FORM_SELECT: 'Base por algoritmo Sarima',
  PLANNING_CREATE_NEW_BUDGET_PROPHET_ALGORITHM_OPTION_FORM_SELECT: 'Base por algoritmo Prophet',

  //Texts modal deadline
  PLANNING_CREATE_FORM_DEADLINE_DATE_PLACEHOLDER: 'Seleccionar día',
  PLANNING_CREATE_FORM_DEADLINE_TIME_PLACEHOLDER: 'Seleccionar hora',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_LABEL: 'Crear recordatorio',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE: 'Ninguno',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_IN_DEADLINE: 'En fecha límite',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_ONE_DAY_BEFORE: '1 día antes',
  PLANNING_CREATE_FORM_DEADLINE_REMINDER_PLACEHOLDER_NONE_TWO_DAY_NEFORE: '2 días antes',
  PLANNING_CREATE_DEADLINE_SUCCESS_FEEDBACK: 'Se ha establecido la fecha limite de carga.',
  PLANNING_CREATE_DEADLINE_ERROR_FEEDBACK:
    'No pudimos establecer la fecha limite de carga. Por favor, volvé a intentarlo.',
  PLANNING_UPDATE_DEADLINE_SUCCESS_FEEDBACK:
    'Se ha actualizado la fecha límite de carga correctamente.',
  PLANNING_UPDATE_DEADLINE_ERROR_FEEDBACK:
    'No pudimos actualizar la fecha límite de carga. Por favor, volvé a intentarlo.',
  PLANNING_DEADLINE_MODAL_TITLE: '{{field}} fecha limite de carga',

  //Cards empty
  PLANNING_EMPTY_CARD_BODY: 'Ingresá y comenzá a presupuestar',

  //Notification budget
  PLANNING_COPY_BUDGET_ERROR_FEEDBACK:
    'No pudimos crear una copia del presupuesto. Por favor, volvé a intentarlo.',
  PLANNING_DELETE_BUDGET_SUCCESS_FEEDBACK: 'El presupuesto se eliminó correctamente.',
  PLANNING_DELETE_BUDGET_ERROR_FEEDBACK:
    'No pudimos eliminar el presupuesto. Por favor, volvé a intentarlo.',
  PLANNING_EDIT_NAME_BUDGET_SUCCESS_FEEDBACK: 'El nombre del presupuesto de editó correctamente.',
  PLANNING_EDIT_NAME_BUDGET_ERROR_FEEDBACK:
    'No pudimos editar el nombre del presupuesto. Por favor, volvé a intentarlo.',
  PLANNING_NEXT_STEP_ERROR_FEEDBACK: 'Error al pasar a la etapa siguiente',
  PLANNING_CLOSING_STEP_ERROR_FEEDBACK: 'Error al cerrar el presupuesto',

  //Modal global budget create
  PLANNING_GLOBAL_BUDGET_CREATE_FORM_TITLE: 'Crear presupuesto global',
  PLANNING_GLOBAL_BUDGET_CREATE_FORM_INPUT_PLACEHOLDER: 'Ej: presupuesto genera nuevo',

  //Drawer scenario
  PLANNING_VARS_EMPTY_SCREEN_UPLOAD_SUBTITLE: 'Comenzá la carga de tus variables',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES_VALUES: 'La variable aún no tiene valores asignados.',
  PLANNING_DRAWER_SCENARIO_NO_VARIABLES: 'Aún no existen variables',

  PLANNING_RULES_APPLY_SUCCESS_FEEDBACK: 'La regla se ha {{action}} correctamente.',
  RULES_APPLY_TEXT: 'Aplicado',
  RULES_UNAPPLY_TEXT: 'Desaplicado',
  PLANNING_RULES_APPLY_ERROR_FEEDBACK:
    'No pudimos {{action}} la regla. Por favor, volvé a intentarlo.',

  PLANNING_VARIABLE_EDIT_TOOLTIP: 'Click para editar variable',
  PLANNING_VARIABLE_CONFIG_TOOLTIP: 'Para editar este campo ir a configuración de variables',
  PLANNING_VARIABLE_CONFIG_LINK: 'Ir al listado de variables',
  PLANNING_VARIABLE_CONFIG_LINK_ACTION: 'Editar desde configuración',

  PLANNING_VARIABLES_RETURN_CONFIRM_TITLE: 'Volver al listado de variables',
  PLANNING_VARIABLES_RETURN_CONFIRM_TEXT:
    '¿Estás seguro que deseas volver al listado de variables?',
  PLANNING_VARIABLES_RETURN_CONFIRM_TOOLTIP:
    'Si volves sin guardar perderás los valores cargados hasta el momento.',

  PLANNING_ACTION_MODAL_TITLE: '{{action}} presupuesto',
  PLANNING_APPROVE_MODAL_TEXT:
    'Estás por finalizar con el proceso presupuestario de tu período.¿Estás seguro que deseas hacerlo? Recordá que una vez que apruebes el presupuesto ya no se podrá modificar ningún dato ni subir más archivos.',
  PLANNING_SCREEN_SUCCESS_FEEDBACK: ' Acabás de {{action}} el presupuesto del {{period}} :)', // NOTE: COMENTADO HASTA QUE SE PUEDA NOTIFICAR.¿Querés darle la noticia a todos los que participaron de esta planificación?',
  PLANNIG_PUBLISH_MODAL_TEXT:
    'Estás por publicar tu presupuesto. En esta instancia podrás editar,seguir cargando datos, comparar presupuestos entre sí y visualizar tus datos en análisis, seguimiento y forecast.',

  //Empty screen
  PLANNING_EMPTY_SCREEN_TITLE: '¡Aún no tenés ningún presupuesto creado!',
  PLANNING_EMPTY_SCREEN_BODY:
    'Creá el presupuesto, establecé prioridades y construí la cantidad de proyecciones así cómo diferentes escenarios tengas',
  PLANNING_BUDGET_CREATE_ACTION: 'Crear presupuesto',

  //Empty screen global budget
  PLANNING_GLOBAL_BUDGET_EMPTY_SCREEN_TITLE: '¡Aún no tenés ningun presupuesto global creado!',

  PLANNING_GLOBAL_BUDGET_FIXED_POPOVER_TEXT:
    'Seleccionaste este presupuesto como Predeterminado. Esto significa que visualizarás sus datos en toda la herramienta.',
  PLANNING_GLOBAL_BUDGET_NOT_DATA_BUDGET_TOOLTIP_TITLE:
    'Para realizar un análisis general deberás tener datos en al menos 2 presupuestos de distintos conceptos.',
  PLANNING_GLOBAL_BUDGET_MODIFY_BUDGET_POPCONFIRM_TITLE:
    'Si modifica el escenario del presupuesto global, se actualizarán todos sus datos. ¿Está seguro que desea continuar?',
  PLANNING_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE:
    'Haz click para establecer este presupuesto como predetermiando.',
  PLANNING_GLOBAL_BUDGET_ADD_NEW_COMBINATION_ACTION: 'Añadir nueva combinación',
  PLANNING_GLOBAL_BUDGET_DELETE_COMBINATION_ACTION: 'Eliminar combinación',
  PLANNING_GLOBAL_BUDGET_CREATE_NEW_CHOOSE_TYPE_BUDGET_FORM_SELECT_LABEL:
    'Tipos de presupuesto que conformarán tu {{projection}}',

  PLANNING_PHASES_INFORMATIVE_DISABLED_TOOLTIP_TITLE:
    'Una vez envies la información de la etapa anterior podrás visualizar esta etapa.',
  PLANNING_PHASES_ACTION_CONFIRM_POPCONFIRM_TITLE:
    '{{data}}. Una vez que lo hagas no podrás editar tus datos, solo podrás visualizarlos. Estás seguro que deseas {{action}} ahora?',
  PLANNING_PHASES_CLOSE_CONFIRM_POPCONFIRM_TITLE: 'Estás por cerrar este presupuesto',
  PLANNING_PHASES_SEND_CONFIRM_POPCONFIRM_TITLE:
    'Estás por enviar tu presupuesto a la siguiente etapa',
  PLANNING_PHASES_SEND_TOPDOWN_ACTION: 'Enviar a TopDown',
  PLANNING_PHASES_SEND_BOTTOMUP_ACTION: 'Enviar a BottomUp',
  PLANNING_PHASES_SEND_CLOSING_ACTION: 'Enviar a Cierre',

  //reglas de negocio
  PLANNING_FORMULAS_DESCRIPTION_NOTIFICATION_SUCCESS:
    'Para visualizarlos ingresa al detalle del factor.',
  PLANNING_FORMULA_SAVE_DATA_TOOLTIP_TITLE:
    'Debes guardar los valores ingresados para habilitar esta acción.',
  PLANNING_FACTOR_POPCONFIRM_SAVE_CHANGES:
    '¿Estás seguro que quieres guardar los cambios realizados? Ten en cuenta que esto impactará en el cálculo y sus resultados.',
  PLANNING_RULE_KEEP_DATA_INFORMATIVE_ALERT_DESCRIPTION:
    'Para mantener los valores que modifiques, debes aplicar la regla siempre después de guardarlos',

  PLANNING_DISPLAY_NEGATIVE_DATA_FORM_LABEL: 'Visualización valores negativos',

  //multimoneda
  PLANNING_CURRENCY_EMPTY_SCREEN_TITLE: '¡Aún no hay monedas de origen activadas para mostrar!',
  PLANNING_CURRENCY_CANCEL_POPCONFIRM_TITLE: '¿Estás seguro que deseas cancelar los cambios?',
  PLANNING_CURRENCY_LOAD_ALL_EXCHANGE_RATE_ALERT_DESCRIPTION:
    'Para guardar el tipo de cambio todos los meses deben tener un valor cargado.',
  PLANNING_CURRENCY_ALL_VALUES_TOOLTIP_TITLE: 'Debes completar con valores todos los meses',

  PLANNING_PERCENTAGE_EDITION_CELL_INFORMATIVE_ALERT_MESSAGE:
    'Al colocar % la edición se realizará en porcentaje',

  PLANNING_EMPTY_DATA_CARDS_BUDGET_TYPE_BODY_TEXT:
    'Ingresá para comenzar la carga de {{title}} y poder ver un resumen de tus datos',

  PLANNING_VALUES_FACTORS_APPLIED: 'Valores con reglas aplicadas',

  PLANNING_UPLOAD_VALUE_CERO_INFORMATIVE_TOOLTIP_TITLE:
    'Plika nos arrojará una tabla en cero, en forma de “lienzo”, para que podamos ir completando de forma manual o con alguna otra alternativa de generación del presupuesto.',
  PLANNING_UPLOAD_VALUE_AVERAGE_INFORMATIVE_TOOLTIP_TITLE:
    'Este tipo de carga permite contemplar la información histórica cargada en Plika calculando el promedio de estos datos para establecer nuestro Presupuesto Base.',
  PLANNING_UPLOAD_HISTORICAL_PREVIOUS_PERIOD_INFORMATIVE_TOOLTIP_TITLE:
    'Nos permite tomar de base información preexistente en la herramienta, seleccionando un rango de meses y el o los filtros de dimensiones a contemplar.',
  PLANNING_UPLOAD_VALUE_WITHOUT_BASE_INFORMATIVE_TOOLTIP_TITLE:
    'Es el tipo de carga más habitual. Se desarolla mediante una plantilla Excel o mediante una plantilla Spreadsheet.',
  PLANNING_PHASES_BASE_INFORMATIVE_TOOLTIPE_TITLE:
    'Marca la primera entrada o carga de datos en la tarjeta seleccionada.',
  PLANNING_PHASES_TOPDOWN_INFORMATIVE_TOOLTIPE_TITLE:
    'Esta fase se distingue por las modificaciones que el administrador del presupuesto puede realizar al proponer montos específicos para las partidas presupuestarias asignadas a los miembros de su equipo o a aquellos usuarios encargados de revisar la información más adelante.',
  PLANNING_TRANSACTIONAL_TABLE_INFORMATIVE_TOOLTIP_TITLE:
    'Genera una visual estilo pivotante y anidada, que permite crear un análisis dinámico entre las distintas dimensiones y valores Plika.',
  PLANNING_PERCENTAGE_TABLE_INFORMATIVE_TOOLTIP_TITLE:
    'Permite aplicar reglas de negocio utilizando un orden de jerarquía entre dimensiones, haciendo uso de la funcionalidad de cascadeo.',
  PLANNING_PHASES_BOTTOMUP_INFORMATIVE_TOOLTIP_TITLE:
    'Esta fase se distingue por las modificaciones que los involucrados de cada área o unidad de negocio proponen al presupuesto otorgado.',
  PLANNING_PHASES_CIERRE_INFORMATIVE_TOOLTIP_TITLE:
    'Es la etapa final del proceso, en donde el área encargada del presupuesto genera los últimos ajustes.',
}

export default planning
