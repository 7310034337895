import _ from 'lodash'
import React from 'react'
import { PivotData } from '../../Utilities'

class TSVExport extends React.PureComponent {
  render() {
    const pivotData = new PivotData(this.props)
    const rowKeys = pivotData.getRowKeys()
    const colKeys = pivotData.getColKeys()
    if (rowKeys.length === 0) {
      rowKeys.push([])
    }
    if (colKeys.length === 0) {
      colKeys.push([])
    }

    const baseRow = _.cloneDeep(pivotData.props.rows)
    const headerRow = baseRow.map((r) => r)
    if (colKeys.length === 1 && colKeys[0].length === 0) {
      headerRow.push(this.props.aggregatorName)
    } else {
      colKeys.map((c) => headerRow.push(c.join('-')))
    }

    const result = rowKeys.map((r) => {
      const row = r.map((x) => x)
      colKeys.map((c) => {
        const v = pivotData.getAggregator(r, c).value()
        row.push(v ? v : '')
      })
      return row
    })

    result.unshift(headerRow)

    return (
      <textarea
        value={result.map((r) => r.join('\t')).join('\n')}
        style={{ width: '100%', height: window.innerHeight / 2 }}
        readOnly={true}
      />
    )
  }
}

TSVExport.defaultProps = PivotData.defaultProps
TSVExport.propTypes = PivotData.propTypes

export default TSVExport
