import React, { useState, useEffect } from 'react'
import { Spin, Select, Typography } from 'antd'
import { client } from 'httpclient'
import { useTranslation } from 'react-i18next'
import { handleSelectAll, generateSelectOptions, isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'

const { Text } = Typography

const { SETTINGS__SECURITY__ROLES__ABM } = ROLES

const DimSelector = ({
  dimension: { id = null, url, label = '', value = [], all_selected = false },
  onChange,
  ...otherProps
}) => {
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [areAllSelected, setAreAllSelected] = useState(false)
  const [selectorOptions, setSelectorOptions] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      setIsLoadingOptions(true)
      client
        .get(url)
        .then((response) => {
          setSelectorOptions([
            ...selectorOptions,
            ...(response.data.results ? response.data.results : response.data),
          ])
          setIsLoadingOptions(false)
          if (all_selected) {
            const auxValues = response.data.results ? response.data.results : response.data
            onChange(
              auxValues.map((el) => el.id.toString()),
              true,
            )
            setAreAllSelected(true)
          }
        })
        .catch(() => console.log('Dimension error'))
    }
    // eslint-disable-next-line
  }, [url, id])

  const canUserEdit = isUserAllowed([SETTINGS__SECURITY__ROLES__ABM])

  const renderValue = () => {
    if (all_selected) {
      return selectorOptions.map((option) => option.id.toString())
    }
    return value.asMutable()
  }

  return (
    <>
      <Text>
        <Text type="danger">*</Text> {label}
      </Text>
      <Spin size="small" spinning={isLoadingOptions}>
        <Select
          showSearch
          showArrow
          allowClear
          disabled={!canUserEdit}
          style={{ width: '100%', marginBottom: 24 }}
          placeholder={t('VALUES_PLACEHOLDER')}
          onChange={(values) =>
            handleSelectAll({
              values,
              allValues: selectorOptions.map((option) => option.id),
              areAllSelected,
              onSetValues: (values, all_selected) => onChange(values, all_selected),
              onSetAreAllSelected: (state) => setAreAllSelected(state),
            })
          }
          value={renderValue()}
          loading={isLoadingOptions}
          notFoundContent={
            isLoadingOptions ? <Spin size="small" /> : t('FEEDBACK_DEFAULT_NOT_FOUND')
          }
          mode="multiple"
          maxTagCount={1}
          maxTagTextLength={12}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          {...otherProps}
          options={generateSelectOptions({
            options: selectorOptions.map((op) => {
              return { ...op, name: op.name_destination ? op.name_destination : op.name }
            }),
            includeOptionAll: true,
          })}
        />
      </Spin>
    </>
  )
}

export default DimSelector
