const forecast = {
  // Actions
  FORECAST_CREATE_ACTION: 'Create forecast',
  // Fields
  FORECAST_CUT_DATE_FIELD: 'Cut month:',

  // Messages
  FORECAST_NO_DATA_ALERT_TITLE: "We still don't have all the data we need to create your forecast",
  FORECAST_NO_DATA_ALERT_TEXT:
    'In order to create Forecast we must have at least one published global budget and one month with published real data. Once you do, you can create your forecast.',
  FORECAST_EMPTY_STATE_TEXT:
    'Identify what is not evolving as planned and re-define your goals. Modify the future in the short and medium term to reduce risks',

  FORECAST_DELETE_ERROR_FEEDBACK: 'The forecast could not be deleted',
  FORECAST_EDIT_NAME_SUCCESS_FEEDBACK: 'The forecast name was successfully edited.',
  FORECAST_EDIT_NAME_ERROR_FEEDBACK: "We couldn't edit the forecast name. Please try again.",
  FORECAST_DELETE_POPCONFIRM: 'Are you sure you want to delete this forecast?',

  // XLS names
  FORECAST_TEMPLATE_NAME: 'Plika Template Forecast {{name}} - {{periodName}} ({{date}})',
  FORECAST_GLOBAL_BUDGET_EVOLUTION_TABLE_FILE_NAME: `Plika - Forecast {{name}}({{reportName}}) - {{periodName}} (Global forecast)`,
  FORECAST_PHASE_TABLE_FILE_NAME:
    'Plika - Forecast {{name}} phase {{phase}} ({{conceptName}}) - {{periodName}} ({{date}})',

  FORECAST_CUT_DATE_INFORMATIVE_TEXT_MODAL:
    'Choose a name, a base, and select the cutoff month to create your forecast. This is the last month from which actual published data is taken.',
  FORECAST_CUT_DATE_PLACEHOLDER_DATEPICKER_MODAL: 'Select month',
  FORECAST_CREATE_GLOBAL_FORECAST_MODAL_TITLE: 'Create global forecast',
  FORECAST_CUTTING_MONTH_FORM_CREATE_MODAL_LABEL: 'Cutting month',
  FORECAST_NEED_NEW_PUBLISHED_DATE_INFORMATIVE_TOOLTIP_TITLE:
    'You need to publish a new date to be able to generate a Forecast with a new month of cut.',
  FORECAST_NEED_GLOBAL_BUDGET_FORECAST_PUBLISHED_APPROVED_INFORMATIVE_TOOLTIP_TITLE:
    'You must have at least a global budget or global forecast (from the previous month) published or approved to create a new forecast',
  FORECAST_NEED_LAST_CUT_DATE_WITH_GLOBAL_FORECAST_PUBLISHED_APPROVED_INFORMATIVE_TOOLTIP_TITLE:
    'You must publish or approve a global forecast (of the last month of cutting) to be able to create a new Forecast',
  FORECAST_NEED_PUBLISHED_DATE_GLOBAL_BUDGET_INFORMATIVE_TOOLTIP_TITLE:
    'You must publish a real date and publish a global budget to be able to create your first forecast',
  FORECAST_CREATE_NEW_BUDGET_BASE_TYPE_FORM_LABEL: 'Base for which to create your forecast',
  FORECAST_CREATE_MODAL_TITLE: 'Create New Base Forecast',
}

export default forecast
