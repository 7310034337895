import { useState } from 'react'
import { Avatar, Mentions, message, Popconfirm, Space, Typography as TypographyAntd } from 'antd'
import Button from 'modules/core/components/Button/Button'
import ButtonActionBar from 'modules/core/components/ButtonActionBar/ButtonActionBar'
import DropdownMenu from 'modules/core/components/DropdownMenu/DropdownMenu'
import Typography from 'modules/core/components/Typography/Typography'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  UpOutlined,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import configuration from 'modules/configuration'

import './CardData.scss'
import { generateMessageError } from 'modules/core/utils'

const { Paragraph } = TypographyAntd

const CardData = ({
  el,
  editId,
  onClickEdit,
  onClickReplies = () => {},
  isParent = false,
  usersList,
  onRefetchComments,
  onCleanHasComment,
  setElementRefId,
  deleteComment,
  editComment,
  resolveComment,
  setHasComment,
}) => {
  const [deleteId, setDeleteId] = useState(null)
  const [usersIds, setUsersIds] = useState([])
  const [textValue, setTextValue] = useState('')
  const { t } = useTranslation()

  const handleDelete = () => {
    deleteComment(deleteId)
      .then(() => {
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setDeleteId(null)
          onCleanHasComment()
        })
      })
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setDeleteId(null)
        onCleanHasComment()
      })
  }

  const handleEdit = () => {
    const { key, parent, module } = el
    editComment(editId, { key, parent, module, text: textValue, mentions: usersIds })
      .then(() => {
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          onClickEdit(null)
        })
      })
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        onClickEdit(null)
      })
  }

  const handleResolved = (id) => {
    resolveComment(id)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
      })
  }

  const renderText = () => {
    const aux = el.text.split(/@(\w+)/)
    return aux.map((part, index) =>
      index % 2 === 1 ? (
        <span style={{ color: '#0047ba' }} key={index}>
          @{part}
        </span>
      ) : (
        part
      ),
    )
  }

  const handleOnClick = (key, module) => {
    const identifyData = key.split(module)[1]
    const type = identifyData.split('-')[1]
    const id = identifyData.split('-')[2]
    const data = {
      type,
      data: true,
      ...(type !== 'table' && {
        key: module,
        id,
      }),
    }
    setHasComment(data)
  }

  return (
    <div id={el.key} key={el.id}>
      <Space
        onClick={() => handleOnClick(el.key, el.module)}
        style={{ width: '100%', cursor: 'pointer' }}
        direction="vertical"
      >
        <ButtonActionBar position="space-between">
          <Space direction="vertical">
            <Space direction="horizontal">
              <Avatar style={{ backgroundColor: '#0047ba' }} size="small">
                {el?.user?.first_name[0].toUpperCase()}
              </Avatar>
              <Typography.Body level={2}>{el?.user?.first_name}</Typography.Body>
              <Typography.Body level={3}>{moment(el.created_at).calendar()}</Typography.Body>
            </Space>
          </Space>
          <Space direction="horizontal">
            {isParent && !el.resolved && (
              <Button.Icon
                title={t('ACTION_SOLVE')}
                icon={<Typography.Icon level={2} icon={CheckCircleOutlined} />}
                onClick={() => handleResolved(el.id)}
              />
            )}
            <Popconfirm
              title={t('POPCONFIRM_REMOVE_COMMENT')}
              okText={t('ACTION_DELETE')}
              onConfirm={handleDelete}
              cancelText={t('ACTION_CANCEL')}
              onCancel={() => setDeleteId(null)}
              visible={el.id === deleteId}
            >
              <DropdownMenu
                title={t('ACTION_MORE')}
                menu={[
                  ...(!el.resolved
                    ? [
                        {
                          title: t('ACTION_EDIT'),
                          icon: <EditOutlined />,
                          onClick: () => onClickEdit(el.id),
                        },
                      ]
                    : []),
                  {
                    title: t('ACTION_DELETE'),
                    icon: <DeleteOutlined />,
                    onClick: () => setDeleteId(el.id),
                  },
                ]}
              />
            </Popconfirm>
          </Space>
        </ButtonActionBar>
        {editId === el.id ? (
          <Space direction="horizontal">
            <Mentions
              placeholder={t('WRITE_COMMENT_PLACEHOLDER')}
              rows={2}
              defaultValue={el.text}
              onSelect={(d) => setUsersIds((prevState) => [...prevState, parseInt(d.key)])}
              onChange={(value) => setTextValue(value)}
            >
              {usersList?.values.map((user) => (
                <Mentions.Option key={user.id} value={`${user.first_name}${user.last_name}`}>
                  {user.first_name}
                  {user.last_name}
                </Mentions.Option>
              ))}
            </Mentions>
            <Button.Icon
              icon={<Typography.Icon level={2} icon={CloseOutlined} />}
              title="ACTION_CANCEL"
              onClick={() => onClickEdit(null)}
            />
            {textValue && (
              <Button.Icon
                icon={<Typography.Icon level={2} icon={CheckOutlined} />}
                title="ACTION_SAVE"
                onClick={() => handleEdit()}
              />
            )}
          </Space>
        ) : (
          <Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: t('ACTION_READ_MORE'),
            }}
            key={el.key}
          >
            {renderText()}
          </Paragraph>
        )}
      </Space>
      {isParent && (
        <ButtonActionBar position={!el.resolved ? 'space-between' : 'end'}>
          {!el.resolved && <Button.Link title="ACTION_REPLY" onClick={onClickReplies} />}
          <Space style={{ cursor: 'pointer' }} onClick={onClickReplies} direction="horizontal">
            <span>{`#${el.replies.length}`}</span>
            {el.replies.length > 0 && (
              <Typography.Icon level={2} icon={el.viewReplies ? UpOutlined : DownOutlined} />
            )}
          </Space>
        </ButtonActionBar>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  setElementRefId: configuration.actions.setElementRefId,
  deleteComment: configuration.actions.deleteComment,
  editComment: configuration.actions.editComment,
  resolveComment: configuration.actions.resolveComment,
  setHasComment: configuration.actions.setHasComment,
}

export default connect(null, mapDispatchToProps)(CardData)
