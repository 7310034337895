import React, { useState } from 'react'
import { message, Popover, Space } from 'antd'
import { Button, ButtonActionBar, Comments, StatusTag, Typography } from 'modules/core/components'
import { MoreActions } from './components'
import { HolderOutlined, BgColorsOutlined, CommentOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ChromePicker } from 'react-color'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import _ from 'lodash'
import { generateMessageError } from 'modules/core/utils'

const CardExtra = ({
  status,
  title,
  loading,
  type, // Agregamos la prop 'type' para determinar el tipo de tarjeta
  onConfirmPopconfirm,
  onCancelPopconfirm,
  visible,
  onClickEdit,
  disabled,
  onClickDuplicate,
  onClickDelete,
  is_closing,
  onOpenActivities,
  attributes,
  listeners,
  tagStyle,
  className,
  setColor,
  color,
  onClickCancelSaveColor,
  onClickSaveColor,
  open,
  onClickIconColor,
  viewComment,
  hasComment,
  setHasComment,
  setViewComment,
  loggedUser,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  cardId,
  createComment,
  removeNewComment,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `${auxKey}?tab=${type}` : auxKey

  const handleCreate = ({ text, usersIds, idComment = null, setIsNewThread }) => {
    const dataa = {
      text,
      key: `${key}-card-${cardId}`,
      module: key,
      mentions: usersIds,
      ...(idComment && {
        parent: idComment,
      }),
    }
    setIsSaving(true)
    createComment(dataa)
      .then(() =>
        onRefetchComments().then(() => {
          message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
          setIsSaving(false)
          setIsNewThread(false)
        }),
      )
      .catch((error) => {
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8)
        setIsSaving(false)
        setIsNewThread(false)
      })
  }
  return (
    <Space size="small">
      <StatusTag is_closing={is_closing} status={status} />
      <Popover
        placement="bottom"
        trigger="click"
        visible={open}
        content={
          <Space direction="vertical">
            <ChromePicker
              disableAlpha
              color={color}
              onChangeComplete={(col) => setColor(col.hex)}
            />
            <ButtonActionBar position="space-between">
              <Button.Default title="ACTION_CANCEL" onClick={onClickCancelSaveColor} />
              <Button.Primary title="ACTION_SAVE" onClick={onClickSaveColor} />
            </ButtonActionBar>
          </Space>
        }
      >
        <Button.Icon
          title={t('ACTION_CHANGE_COLOR')}
          icon={<Typography.Icon icon={BgColorsOutlined} />}
          onClick={onClickIconColor}
        />
      </Popover>
      {(viewComment ||
        dataComments?.filter((el) => el?.key === `${key}-card-${cardId}` && !el?.resolved).length >
          0) && (
        <Comments
          hasComment={
            !_.isEmpty(hasComment?.card)
              ? !_.isEmpty(hasComment?.card[key]) && hasComment?.card[key][cardId]
              : false
          }
          onClick={(visible) => {
            setHasComment({ type: 'card', data: visible, key, id: cardId })
            !visible && removeNewComment()
            !visible && setViewComment(visible)
          }}
          comments={dataComments?.filter(
            (el) => (el?.key === `${key}-card-${cardId}` && !el?.resolved) || !el?.text,
          )}
          setData={setDataComments}
          loading={loadingComments || isSaving}
          onRefetchComments={onRefetchComments}
          onCommentCreate={(text, usersIds, idComment, setIsNewThread) =>
            handleCreate({ text, usersIds, idComment, setIsNewThread })
          }
          onCleanHasComment={() => {
            setHasComment({ type: 'card', data: false, key, id: cardId })
            setViewComment(false)
          }}
        >
          <Typography.Icon
            style={{ cursor: 'pointer' }}
            title={t('LABEL_COMMENTS')}
            icon={CommentOutlined}
          />
        </Comments>
      )}
      <MoreActions
        title={title}
        loading={loading}
        onConfirmPopconfirm={onConfirmPopconfirm}
        onCancelPopconfirm={onCancelPopconfirm}
        visible={visible}
        onClickEdit={onClickEdit}
        disabled={disabled}
        onClickDuplicate={onClickDuplicate}
        onClickDelete={onClickDelete}
        onOpenActivities={onOpenActivities}
        type={type}
        color={color}
      />
      <div className={className} {...attributes} {...listeners} style={tagStyle}>
        <Typography.Icon title={t('ACTION_SORT')} icon={HolderOutlined} />
      </div>
    </Space>
  )
}

const mapDispatchToProps = {
  createComment: configuration.actions.createComment,
  removeNewComment: configuration.actions.removeNewComment,
}

export default connect(null, mapDispatchToProps)(CardExtra)
